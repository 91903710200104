import React from "react";
import UpperSection from "../Shared/UpperSection";
import * as styles from "./Integrations.module.scss";
import {
  IconIntegrations,
  iconTekmetric,
  imgShopware,
  iconMitchell1,
  iconRowriter,
  imgMamsoftware,
  imgProtractor,
  imgNapatracks,
  iconMeineke,
  imgLankar,
  imgShopbosspro,
  imgAutofluent,
  imgAndreoli,
  imgOmnique,
  imgAutos2000,
  imgFasttrak,
  imgGemcar,
  imgMaxxtraxxce,
  imgPrecisiontune,
  imgStocktrac,
  imgShopcontroller,
  iconShopkey,
  imgTiremaster,
  imgTireshop,
  imgWinworks,
  imgMojio,
  imgGeotab,
} from "../../assets/icons/Integrations";

const INTEGRATIONS = [
  {
    name: "Tekmetric",
    type: "Shop Management System",
    logo: iconTekmetric,
    color: "#084E7C",
    className: "tekmetric",
  },
  {
    name: "Shop-Ware",
    type: "Shop Management System",
    logo: imgShopware,
    color: "#F15921",
    className: "shopware",
  },
  {
    name: "Mitchell1",
    type: "Shop Management System",
    logo: iconMitchell1,
    color: "#000000",
    className: "mitchell",
  },
  {
    name: "ROWriter",
    type: "Shop Management System",
    logo: iconRowriter,
    color: "#0076A9",
    className: "rowriter",
  },
  {
    name: "VastSQL",
    type: "Shop Management System",
    logo: imgMamsoftware,
    color: "#E40074",
    className: "vastsql",
  },
  {
    name: "Protractor",
    type: "Shop Management System",
    logo: imgProtractor,
    color: "#5A69A5",
    className: "protractor",
  },
  {
    name: "NAPA TRACS",
    type: "Shop Management System",
    logo: imgNapatracks,
    color: "#0D2A8A",
    className: "napatracs",
  },
  {
    name: "Meineke",
    type: "Shop Management System",
    logo: iconMeineke,
    color: "#FEDE11",
    className: "meineke",
  },
  {
    name: "Lankar",
    type: "Shop Management System",
    logo: imgLankar,
    color: "#141414",
    className: "lankar",
  },
  {
    name: "ShopBoss",
    type: "Shop Management System",
    logo: imgShopbosspro,
    color: "#DD2527",
    className: "shopboss",
  },
  {
    name: "AutoFluent",
    type: "Shop Management System",
    logo: imgAutofluent,
    color: "#043B90",
    className: "autofluent",
  },
  {
    name: "Andreoli",
    type: "Shop Management System",
    logo: imgAndreoli,
    color: "#6FBD43",
    className: "andreoli",
  },
  {
    name: "Omnique",
    type: "Shop Management System",
    logo: imgOmnique,
    color: "#0B4591",
    className: "omnique",
  },
  {
    name: "Autos2000",
    type: "Shop Management System",
    logo: imgAutos2000,
    color: "#2A2A2A",
    className: "autos2000",
  },
  {
    name: "FastTrak",
    type: "Shop Management System",
    logo: imgFasttrak,
    color: "#2D2B6D",
    className: "fasttrak",
  },
  {
    name: "Gem-Car",
    type: "Shop Management System",
    logo: imgGemcar,
    color: "#3251A2",
    className: "gemcar",
  },
  {
    name: "MaxxTraxxCE",
    type: "Shop Management System",
    logo: imgMaxxtraxxce,
    color: "#074A81",
    className: "maxxtraxx",
  },
  {
    name: "PTAC",
    type: "Shop Management System",
    logo: imgPrecisiontune,
    color: "#FFF200",
    className: "precisiontune",
  },
  {
    name: "Stocktrac",
    type: "Shop Management System",
    logo: imgStocktrac,
    color: "#B64539",
    className: "stocktrac",
  },
  {
    name: "ShopController",
    type: "Shop Management System",
    logo: imgShopcontroller,
    color: "#2C355A",
    className: "shopcontroller",
  },
  {
    name: "ShopKey",
    type: "Shop Management System",
    logo: iconShopkey,
    color: "#ED1F29",
    className: "shopkey",
  },
  {
    name: "TireMaster",
    type: "Shop Management System",
    logo: imgTiremaster,
    color: "#EC202A",
    className: "tiremaster",
  },
  {
    name: "TireShop",
    type: "Shop Management System",
    logo: imgTireshop,
    color: "#4EA1D9",
    className: "tireshop",
  },
  {
    name: "Winworks",
    type: "Shop Management System",
    logo: imgWinworks,
    color: "#1D815C",
    className: "winworks",
  },
  {
    name: "Mojio",
    type: "Telematics",
    logo: imgMojio,
    color: "#232323",
    className: "mojio",
  },
  {
    name: "Geotab",
    type: "Telematics",
    logo: imgGeotab,
    color: "#21325E",
    className: "geotab",
  },
];

const Integrations = ({}) => {
  return (
    <>
      <UpperSection
        sectionName="Shop Management Integrations"
        textTitle="Integrate with industry-leading solutions"
        description={`Steer integrates with many Automotive Shop Management Software Systems.
        If you use one not seen below, please contact us to discuss.`}
        clsLeft="IntegrationHero"
        clsRight="IntegrationHeroImg"
        formClass="Hidden"
        className={styles.heroWrapper}
      >
        <div className={styles.hero}>
          <IconIntegrations className={styles.icon} />
        </div>
      </UpperSection>
      <div className={styles.wrapper}>
        <div className={styles.sectionWrapper}>
          <div className={styles.sectionTitle}>Our integrations</div>
          <div className={styles.sectionDescription}>
            Steer integrates with the leading auto repair shop management
            software providers, giving your business an integrated solution that
            performs tasks across platforms and provides a connected experience
            that brings multiple platforms together. Making your business more
            efficient and more accurate with critical data.
          </div>
          <div className={styles.cardsContainer}>
            {INTEGRATIONS.map(({ name, type, color, logo, className }, i) => (
              <div
                key={i}
                className={styles.card}
                style={{
                  backgroundColor: color,
                }}
              >
                <div className={styles.logoContainer}>
                  <img
                    className={`${styles.logo} ${styles[className]}`}
                    src={logo}
                    alt="logo"
                  />
                </div>
                <div className={styles.info}>
                  <div className={styles.type}>{type}</div>
                  <div className={styles.name}>{name}</div>
                </div>
              </div>
            ))}
            <div className={styles.poligon1}></div>
            <div className={styles.poligon2}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Integrations;
