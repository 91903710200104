import React from "react";
import MiddleSection from "../Shared/MiddleSection";
import UpperSection from "../Shared/UpperSection";
import * as styles from "./EmailMarketing.module.scss";
import iconEmail from "../../assets/icons/EmailMarketing/i-email-marketing-email.svg";
import iconEmailCampaigns from "../../assets/icons/EmailMarketing/i-email-marketing-campaigns.svg";
import iconEmailReminders from "../../assets/icons/EmailMarketing/i-email-marketing-reminders.svg";
import { StaticImage } from "gatsby-plugin-image";
import { useScreenSize } from "../../hooks";

const EMAIL_MARKETING_TOOLS = [
  {
    toolTitle: "Professional email template designs",
    toolDescription:
      "Choose from dozen of customizable email templates to fit the needs of your auto repair shop, or design your own with our email template design tool.",
    icon: iconEmail,
  },
  {
    toolTitle: "Send important notifications & service reminders",
    toolDescription:
      "Sync your existing customers through our Shop Management System integrations to easily send customized emails based on service history, vehicle model and more.",
    icon: iconEmailReminders,
  },
  {
    toolTitle: "Automated auto repair email marketing campaigns",
    toolDescription:
      "With automated emails you can send special offers, prompt reviews and provide important vehicle service reminders to your customers.",
    icon: iconEmailCampaigns,
  },
];

const EmailMarketingProduct = ({}) => {
  const { isMobile, isTablet } = useScreenSize();

  return (
    <>
      <UpperSection
        sectionName="Email Marketing"
        textTitle="Automated email marketing"
        description="Send your customers important information and reminders with automated and targeted Auto Repair Shop Emails."
        clsLeft="emailMarketingDistance"
        clsRight="imagesDistance"
        formClass="EmailMarketingForm"
      >
        {isMobile ? (
          <StaticImage
            src="../../assets/images/EmailMarketing/hero-mobile.png"
            alt="welcome message"
            className={styles.welcomeMessage}
            loading="eager"
            quality={100}
            objectFit="contain"
          />
        ) : (
          <div className={styles.heroImages}>
            <StaticImage
              src="../../assets/images/EmailMarketing/welcome-message.png"
              alt="welcome message"
              className={styles.welcomeMessage}
              loading="eager"
              quality={100}
              style={{
                position: "absolute",
              }}
            />
            <StaticImage
              src="../../assets/images/EmailMarketing/image1.png"
              alt="shop"
              className={styles.image1}
              loading="eager"
              quality={100}
              style={{
                position: "absolute",
              }}
            />
            {isTablet ? (
              <StaticImage
                src="../../assets/images/EmailMarketing/image2_mob_tablet.png"
                alt="customer"
                className={styles.image2}
                loading="eager"
                quality={100}
                style={{
                  position: "absolute",
                }}
              />
            ) : (
              <StaticImage
                src="../../assets/images/EmailMarketing/image2.png"
                alt="customer"
                className={styles.image2}
                loading="eager"
                quality={100}
                style={{
                  position: "absolute",
                }}
              />
            )}
            <StaticImage
              src="../../assets/images/EmailMarketing/follow-ups-card.png"
              alt="follow ups card"
              className={styles.followUpsImg}
              loading="eager"
              quality={100}
              style={{
                position: "absolute",
              }}
            />
            <div className={styles.gradientBox}></div>
            <div className={styles.hexagon}></div>
            <div className={styles.circle}></div>
            <div className={styles.triangle}></div>
          </div>
        )}
      </UpperSection>
      <MiddleSection
        textTitle="Auto repair email marketing made simple"
        description="Create an email campaign for your automotive repair shop in minutes."
        tools={EMAIL_MARKETING_TOOLS}
      >
        <div className={styles.featuresImage}></div>
      </MiddleSection>
    </>
  );
};

export default EmailMarketingProduct;
