import React, { useEffect, useState } from "react";
import { delay } from "../../../utils/delay";
import * as styles from "./Tooltip.module.scss";

type TooltipProps = {
  tooltip: string;
  className?: string;
  clsTooltip?: string;
  icon: React.ReactNode;
  hoverIcon?: React.ReactNode;
  isPreHidden?: boolean;
};

const Tooltip: React.FC<TooltipProps> = ({
  tooltip,
  className = "",
  clsTooltip = "",
  icon,
  hoverIcon,
  isPreHidden,
}) => {
  const [show, setShow] = useState(false);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (!isPreHidden) {
      (async () => {
        setShow(true);
        await delay(5000);
        setShow(false);
      })();
    }
  }, []);
  return (
    <div
      className={`${styles.tooltipWrap} ${className}`}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      {hover ? hoverIcon : icon}
      <span
        className={`${styles.tooltip} ${
          show ? styles.tooltipShow : ""
        } ${clsTooltip}`}
      >
        {tooltip}
      </span>
    </div>
  );
};

export default Tooltip;
