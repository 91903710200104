import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import SupportRequestPage from "../components/SupportRequest";

const SupportRequest: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return <SupportRequestPage />;
};

export default SupportRequest;
