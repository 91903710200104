// extracted by mini-css-extract-plugin
export var board = "JobBoard-module--board--3PfRl";
export var filters = "JobBoard-module--filters--2F0YK";
export var typeFilter = "JobBoard-module--typeFilter--1V4vz";
export var button = "JobBoard-module--button--1Dyoq";
export var active = "JobBoard-module--active--2B-t2";
export var line = "JobBoard-module--line--3F6kl";
export var cards = "JobBoard-module--cards--2sVwa";
export var card = "JobBoard-module--card--3E7i4";
export var departments = "JobBoard-module--departments--whvr0";
export var label = "JobBoard-module--label--373pr";
export var title = "JobBoard-module--title--BwRny";
export var location = "JobBoard-module--location--2W2Nq";
export var description = "JobBoard-module--description--2KYRH";
export var details = "JobBoard-module--details--C49uC";
export var tag = "JobBoard-module--tag--2CpvV";
export var icon = "JobBoard-module--icon--2NyLV";
export var emptySearch = "JobBoard-module--emptySearch--2u-x2";
export var text = "JobBoard-module--text--puFx5";
export var heading = "JobBoard-module--heading--1cbaW";