const ANALYTICS_EVENTS = {
  getEarlyAccessClick: "get_early_access_click",
  getEarlyAccessPopupOpened: "get_early_access_popup_opened",
  getEarlyAccessFormShown: "get_early_access_form_shown",
  getEarlyAccessFormSubmitAttempt: "get_early_access_form_submit_attempt",
  getEarlyAccessFormSubmitted: "get_early_access_form_submitted",
  supportRequestFormSubmitAttempt: "support_request_submit_attempt",
  supportRequestFormSubmitted: "support_request_submitted",
};

let addAnalyticsEvent = (eventName: String) => {};

if (typeof window !== `undefined`) {
  window.dataLayer = window.dataLayer || [];
  addAnalyticsEvent = (eventName: String) => {
    window.dataLayer.push({ event: eventName });
  };
}

export { ANALYTICS_EVENTS, addAnalyticsEvent };
