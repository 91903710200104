// extracted by mini-css-extract-plugin
export var container = "_404-module--container--3xVjT";
export var image = "_404-module--image--3z0hV";
export var content = "_404-module--content--1KAgA";
export var title = "_404-module--title--2rxi6";
export var text = "_404-module--text--1AdaY";
export var buttonsContainer = "_404-module--buttonsContainer--mmwbV";
export var button = "_404-module--button--3kiI4";
export var icon = "_404-module--icon--1dw6u";
export var textDesktop = "_404-module--textDesktop--1Bv26";
export var textMobile = "_404-module--textMobile--1svV6";