import React from "react";
import * as styles from "./PrivacyPolicy.module.scss";
import TextLink from "../Shared/TextLink";
import { PATHS } from "../../constants";

const PrivacyPolicyPage = ({}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.section}>
        <div className={styles.sectionText}>
          <div className={styles.textWraper}>
            <div className={styles.sectionName}>Privacy Policy</div>
            <h1 className={styles.textTitle}>Privacy Policy</h1>
            <p className={styles.description}>Last updated on May 28th, 2024</p>
          </div>
        </div>
      </div>
      <div className={styles.policyWrapper}>
        <div className={styles.policy}>
          <div className={styles.policyTitle}>Our Policy:</div>
          <p className={styles.policyDescription}>
            Welcome to the web site (the <b>“Site“</b>) of Steercrm.com (
            <b>“Steer“</b>, “we“, “us“ and/or “our“). This Site is operated by
            Steer by Mechanic Advisor, and has been created to as a software as
            a service (the <b>“Steer Services“</b>) to our Site visitors (“you“,
            “your“). This Privacy Policy sets forth Steer’s policy with respect
            to information including personally identifiable data (
            <b>“Personal Data“</b>) and other information that is collected from
            Site visitors.
          </p>
        </div>

        <div className={styles.policy}>
          <div className={styles.policyTitle}>Information we collect:</div>
          <p className={styles.policyDescription}>
            When you interact with us through the Site, we may collect Personal
            Data and other information from you, as further described below:
          </p>
          <p className={styles.policyDescription}>
            <b>Personal Data That You Provide Through the Site:</b> We collect
            Personal Data from you when you voluntarily provide such
            information, such as when you contact us with inquiries, respond to
            one of our surveys, register for access to the Steer Services or use
            certain Steer Services. Wherever Steer collects Personal Data we
            make an effort to provide a link to this Privacy Policy.
          </p>
          <p className={styles.policyDescription}>
            <b>
              By voluntarily providing us with Personal Data, you are consenting
              to our use of it in accordance with this Privacy Policy. If you
              provide Personal Data to this Site, you acknowledge and agree that
              such Personal Data may be transferred from your current location
              to the offices and servers of Steer and the authorized third
              parties referred to herein located in the United States.
            </b>
          </p>
        </div>
        <div className={styles.policy}>
          <div className={styles.policyTitle}>Other information:</div>
          <p className={styles.policyDescription}>
            <b>Non-Identifiable Data:</b> When you interact with Steer through
            the Site, we receive and store certain personally non-identifiable
            information. Such information, which is collected passively using
            various technologies, cannot presently be used to specifically
            identify you. Steer may store such information itself or such
            information may be included in databases owned and maintained by
            Steer affiliates, agents or service providers. This Site may use
            such information and pool it with other information to track, for
            example, the total number of visitors to our Site, the number of
            visitors to each page of our Site, and the domain names of our
            visitors’ Internet service providers. It is important to note that
            no Personal Data is available or used in this process.
          </p>
          <p className={styles.policyDescription}>
            In operating this Site, we may use a technology called “cookies“. A
            cookie is a piece of information that the computer that hosts our
            Site gives to your browser when you access the Site. Our cookies
            help provide additional functionality to the Site and help us
            analyze Site usage more accurately. For instance, our Site may set a
            cookie on your browser that allows you to access the Site without
            needing to remember and then enter a password more than once during
            a visit to the Site. In all cases in which we use cookies, we will
            not collect Personal Data except with your permission. On most web
            browsers, you will find a “help” section on the toolbar. Please
            refer to this section for information on how to receive notification
            when you are receiving a new cookie and how to turn cookies off. We
            recommend that you leave cookies turned on because they allow you to
            take advantage of some of the Site’s features.
          </p>
          <p className={styles.policyDescription}>
            <b>Aggregated Personal Data:</b> In an ongoing effort to better
            understand and serve the users of the Steer Services, Steer often
            conducts research on its customer demographics, interests and
            behavior based on the Personal Data and other information provided
            to us. This research may be compiled and analyzed on an aggregate
            basis, and Steer may share this aggregate data with its affiliates,
            agents and business partners. This aggregate information does not
            identify you personally. Steer may also disclose aggregated user
            statistics in order to describe our services to current and
            prospective business partners, and to other third parties for other
            lawful purposes.{" "}
          </p>
        </div>
        <div className={styles.policy}>
          <div className={styles.policyTitle}>
            Our use of your personal data and other information:
          </div>
          <p className={styles.policyDescription}>
            Steer uses the Personal Data you provide in a manner that is
            consistent with this Privacy Policy. If you provide Personal Data
            for a certain reason, we may use the Personal Data in connection
            with the reason for which it was provided. For instance, if you
            contact us by e-mail, we will use the Personal Data you provide to
            answer your question or resolve your problem. Also, if you provide
            Personal Data in order to obtain access to the Steer Services, we
            will use your Personal Data to provide you with access to such
            services and to monitor your use of such services. Steer and its
            subsidiaries and affiliates (the “Steer Related Companies”) may also
            use your Personal Data and other personally non-identifiable
            information collected through the Site to help us improve the
            content and functionality of the Site, to better understand our
            users and to improve the Steer Services. Steer and its affiliates
            may use this information to contact you in the future to tell you
            about services we believe will be of interest to you. If we do so,
            each communication we send you will contain instructions permitting
            you to «opt-out» of receiving future communications. In addition, if
            at any time you wish not to receive any future communications or you
            wish to have your name deleted from our mailing lists, please
            contact us as indicated below.
          </p>
          <p className={styles.policyDescription}>
            If Steer intends on using any Personal Data in any manner that is
            not consistent with this Privacy Policy, you will be informed of
            such anticipated use prior to or at the time at which the Personal
            Data is collected.
          </p>
        </div>
        <div className={styles.policy}>
          <div className={styles.policyTitle}>
            Our disclosure of your personal data and other information:
          </div>
          <p className={styles.policyDescription}>
            Steer is not in the business of selling your information. We
            consider this information to be a vital part of our relationship
            with you. There are, however, certain circumstances in which we may
            share your Personal Data with certain third parties without further
            notice to you, as set forth below:
          </p>
          <p className={styles.policyDescription}>
            <b>Business Transfers:</b> As we develop our business, we might sell
            or buy businesses or assets. In the event of a corporate sale,
            merger, reorganization, dissolution or similar event, Personal Data
            may be part of the transferred assets.
          </p>
          <p className={styles.policyDescription}>
            <b>Related Companies:</b> We may also share your Personal Data with
            our Related Companies for purposes consistent with this Privacy
            Policy.
          </p>
          <p className={styles.policyDescription}>
            <b>Agents, Consultants and Related Third Parties:</b> Steer by
            Mechanic Advisor, like many businesses, sometimes hires other
            companies to perform certain business-related functions. Examples of
            such functions include mailing information, maintaining databases
            and processing payments. When we employ another company to perform a
            function of this nature, we only provide them with the information
            that they need to perform their specific function.
          </p>
          <p className={styles.policyDescription}>
            <b>Legal Requirements:</b> Steer may disclose your Personal Data if
            required to do so by law or in the good faith belief that such
            action is necessary to (i) comply with a legal obligation, (ii)
            protect and defend the rights or property of Steer by Mechanic
            Advisor, (iii) act in urgent circumstances to protect the personal
            safety of users of the Site or the public, or (iv) protect against
            legal liability.
          </p>
          <p className={styles.policyDescription}>
            <b>Text Message Opt-in:</b> Text messaging originator opt-in data
            and consent will not be shared with any third parties, excluding
            aggregators and providers of the Text Message services.
          </p>
        </div>

        <div className={styles.policy}>
          <div className={styles.policyTitle}>Your choices:</div>
          <p className={styles.policyDescription}>
            You can use the Site without providing any Personal Data. If you
            choose not to provide any Personal Data, you may not be able to use
            certain Steer Services.
          </p>
        </div>
        <div className={styles.policy}>
          <div className={styles.policyTitle}>Children:</div>
          <p className={styles.policyDescription}>
            Steer does not knowingly collect Personal Data from children under
            the age of 13. If you are under the age of 13, please do not submit
            any Personal Data through the Site. We encourage parents and legal
            guardians to monitor their children’s Internet usage and to help
            enforce our Privacy Policy by instructing their children never to
            provide Personal Data on this Site without their permission. If you
            have reason to believe that a child under the age of 13 has provided
            Personal Data to Steer through this Site, please contact us, and we
            will endeavor to delete that information from our databases.
          </p>
        </div>
        <div className={styles.policy}>
          <div className={styles.policyTitle}>Links to other web sites:</div>
          <p className={styles.policyDescription}>
            This Privacy Policy applies only to the Site. This Site may contain
            links to other web sites not operated or controlled by Steer (the
            “Third Party Sites”). The policies and procedures we described here
            do not apply to the Third Party Sites. The links from this Site do
            not imply that Steer endorses or has reviewed the Third Party Sites.
            We suggest contacting those sites directly for information on their
            privacy policies.
          </p>
        </div>
        <div className={styles.policy}>
          <div className={styles.policyTitle}>Security:</div>
          <p className={styles.policyDescription}>
            Steer takes reasonable steps to protect the Personal Data provided
            via the Site from loss, misuse, and unauthorized access, disclosure,
            alteration, or destruction. However, no Internet or e-mail
            transmission is ever fully secure or error free. In particular,
            e-mail sent to or from this Site may not be secure. Therefore, you
            should take special care in deciding what information you send to us
            via e-mail. Please keep this in mind when disclosing any Personal
            Data to Steer via the Internet.
          </p>
        </div>
        <div className={styles.policy}>
          <div className={styles.policyTitle}>Other Terms and Conditions:</div>
          <p className={styles.policyDescription}>
            Your access to and use of this Site is subject to the{" "}
            <TextLink
              text="Terms of Use."
              to={PATHS.TERMS_AND_CONDITIONS}
              className={styles.termsOfUse}
            />
          </p>
        </div>
        <div className={styles.policy}>
          <div className={styles.policyTitle}>
            Changes to Steer by Mechanic Advisor’s Privacy Policy:
          </div>
          <p className={styles.policyDescription}>
            The Site and our business may change from time to time. As a result,
            at times it may be necessary for Steer to make changes to this
            Privacy Policy. Steer reserves the right to update or modify this
            Privacy Policy at any time and from time to time without prior
            notice. Please review this policy periodically, and especially
            before you provide any Personal Data. This Privacy Policy was last
            updated on the date indicated above. Your continued use of the Site
            after any changes or revisions to this Privacy Policy shall indicate
            your agreement with the terms of such revised Privacy Policy.
          </p>
        </div>
        <div className={styles.policy}>
          <div className={styles.policyTitle}>
            Access to information; Contacting Steer by Mechanic Advisor:
          </div>
          <p className={styles.policyDescription}>
            To keep your Personal Data accurate, current, and complete, please
            contact us as specified below. We will take easonable steps to
            update or correct Personal Data in our possession that you have
            previously submitted via this Site.
          </p>
          <p className={styles.policyDescription}>
            Please also feel free to contact us if you have any questions about
            Steer by Mechanic Advisor’s Privacy Policy or the information
            practices of this Site.
          </p>
          <p className={styles.policyDescription}>
            You may contact us as follows: 11 Elkins Street, Suite # 420,
            Boston, MA 02127
          </p>
        </div>
        <div className={styles.policy}>
          <div className={styles.policyTitle}>
            Your California Privacy Rights:
          </div>
          <p className={styles.policyDescription}>
            Applicable California privacy legislation permits visitors who are
            California residents to request certain information regarding our
            disclosure of personal information to third parties for their direct
            marketing purposes. To make such a request to access such
            information, please contact us as follows: 11 Elkins Street, Suite #
            420, Boston, MA 02127. Within thirty (30) days of receiving such a
            request, we will provide a list of the categories of personal
            information disclosed to third parties for third-party direct
            marketing purposes during the immediately preceding calendar year,
            along with the names and addresses of these third parties. This
            request may be made no more than once per calendar year. We reserve
            our right not to respond to requests submitted other than to the
            address specified in this paragraph.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
