import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import PartnersPage from "../components/Partners";

const Partners: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return <PartnersPage />;
};

export default Partners;
