import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import TermsAndConditionsPage from "../components/TermsAndConditions";
import { Helmet } from "react-helmet";

const TermsAndConditions: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return (
    <>
      <Helmet>
        <title>Terms and Conditions | Steer</title>
        <meta name="description" content="Steer CRM Terms and Conditions." />
      </Helmet>
      <TermsAndConditionsPage />
    </>
  );
};

export default TermsAndConditions;
