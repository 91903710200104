import React from "react";
import * as styles from "./EnterpriseSolutions.module.scss";
import { useScreenSize } from "../../hooks";
import { StaticImage } from "gatsby-plugin-image";
import UpperSection from "../Shared/UpperSection";
import Solution from "./Solution";
import Card from "../Shared/Card/Card";
import Advantage from "./Advantage";
import { Link } from "@reach/router";
import { PATHS } from "../../constants";
import icon from "../../assets/icons/i-arrow-up-active.svg";
import iconDark from "../../assets/icons/i-arrow-up-dark-blue.svg";
import starsIcon from "../../assets/icons/i-stars-green.svg";
import smileIcon from "../../assets/icons/i-smile-green.svg";
import {
  iconFeature1,
  iconFeature2,
  iconFeature3,
  iconFeature4,
  iconFeature5,
  iconFeature6,
  iconFeature7,
  iconAdvantage1,
  iconAdvantage2,
  iconAdvantage3,
  iconAdvantage6,
  iconAdvantage7,
  iconAdvantage8,
  iconAdvantage9,
} from "../../assets/icons/EnterpriseSolutions";

const ENTERPRISE_FEATURES = [
  {
    title: "Multi-location dashboard",
    description:
      "Monitor operations across all of your locations in real-time with an intuitive, centralized dashboard.",
    icon: iconFeature1,
  },
  {
    title: "Custom reporting",
    description:
      "Get insights tailored to your needs, including custom reports like email collection for performance tracking.",
    icon: iconFeature2,
  },
  {
    title: "Data cleanup",
    description:
      "After you sign up with Steer, we’ll clean up your data to ensure you always see accurate, up-to-date insights.",
    icon: iconFeature3,
  },
  {
    title: "Integrated data",
    description:
      "Combine data from all locations and set custom permission levels.",
    icon: iconFeature4,
  },
  {
    title: "Priority support",
    description:
      "Get instant access to our dedicated support team, prioritizing your queries and ensuring rapid resolutions.",
    icon: iconFeature5,
  },
  {
    title: "Influence our future",
    description:
      "Be a part of beta testing with a say on our product roadmap and custom development opportunities.",
    icon: iconFeature6,
  },
  {
    title: "User permissions",
    description:
      "Customize access levels to fit each team member’s role, maintaining integrity and control of your system.",
    icon: iconFeature7,
  },
];

const ADVANTAGES_CARDS = {
  SHOPS_SUPPORT: [
    {
      title: "Campaign reporting",
      description:
        "Understand the impact of your campaigns with detailed analytics and reports.",
      icon: iconAdvantage1,
    },
    {
      title: "Declined services",
      description:
        "Track when customers decline services and send them custom reminders to schedule an appointment.",
      icon: iconAdvantage2,
    },
    {
      title: "Multi-touch marketing",
      description:
        "Connect with your customers how they prefer — whether that's via text, email, direct mail, or all of the above.",
      icon: iconAdvantage3,
    },
    {
      title: "Appointment management",
      description:
        "Connect with your customers how they prefer — whether that's via text, email, direct mail, or all of the above.",
    },
    {
      title: "Track productivity",
      description:
        "Monitor and assess how your team and service bays are being utilized.",
      icon: iconFeature2,
    },
    {
      title: "Automate processes",
      description:
        "Steer helps you automate marketing communications to your customers with just a few clicks.",
      icon: iconAdvantage6,
    },
  ],
  SECURITY: [
    {
      title: "Encrypted transmission",
      description:
        "All traffic, including user data, is transported securely and encrypted via SSL.",
      icon: iconAdvantage7,
    },
    {
      title: "Data center security",
      description:
        "Our data center provider maintains industry-recognized certifications, including FedRAMP, ISO, SOC 2, and more.",
      icon: iconAdvantage8,
    },
    {
      title: "SSO",
      description: "Authenticate access to your account with Single Sign On.",
      icon: iconFeature7,
    },
    {
      title: "Compliance",
      description:
        "All payments are processed by Stripe, who comply with PCI Level 1 Data Security Standards.",
      icon: iconAdvantage9,
    },
    {
      title: "Seamless data integration",
      description:
        "Incorporate existing data effortlessly, maintaining integrity and accuracy.",
      icon: iconFeature4,
    },
  ],
};

const HIGHLIGHTS = [
  {
    value: "19%",
    description:
      "Lift in overall saves vs. the previous year where they didn't have our software",
  },
  {
    value: "30M+",
    description: "New revenue generated from our software",
  },
  {
    value: "15K+",
    description: "Online business reviews received",
  },
];

const EnterpriseSolutions = ({}) => {
  const { isMobile } = useScreenSize();

  return (
    <>
      <UpperSection
        sectionName="Enterprise Solutions"
        textTitle="Automotive CRM software for enterprise businesses"
        description="Manage customer experiences across shops, standardize your data, and effortlessly sync with your trusted tools, all from a single dashboard."
        clsLeft="enterpriseSolutionDistance"
        clsRight="enterpriseSolutionImgDistance"
        formClass="Hidden"
        className={styles.hero}
        isDemoBtnShown
        demoBtnTitle="Book a Demo"
      >
        {isMobile ? (
          <StaticImage
            src="../../assets/images/EnterpriseSolutions/laptop_mobile.png"
            alt="laptop"
            className={styles.laptop}
            loading="eager"
            quality={100}
            style={{
              position: "absolute",
            }}
          />
        ) : (
          <>
            <StaticImage
              src="../../assets/images/EnterpriseSolutions/laptop.png"
              alt="laptop"
              className={styles.laptop}
              loading="eager"
              quality={100}
              style={{
                position: "absolute",
              }}
            />
            <StaticImage
              src="../../assets/images/EnterpriseSolutions/laptop-info.png"
              alt="dashboard"
              className={styles.laptopInfo}
              loading="eager"
              quality={100}
              style={{
                position: "absolute",
              }}
            />
          </>
        )}
      </UpperSection>

      <div className={styles.wrapper}>
        <div className={styles.solutions}>
          <Solution
            title="Explore enterprise features"
            description="Dive into our robust suite of features tailored specifically for enterprise-level operations. 
            From custom onboarding to unified dashboards, explore how Steer Enterprise sets you apart."
            className="upperSolution"
          >
            <div className={styles.cardsContainer}>
              {ENTERPRISE_FEATURES.map(({ title, description, icon }, i) => (
                <Card
                  title={title}
                  description={description}
                  icon={icon}
                  key={i}
                  variant="Secondary"
                />
              ))}
            </div>
          </Solution>
          <div className={styles.banner}>
            <div className={styles.card}>
              <img
                className={styles.bannerStars}
                src={starsIcon}
                alt="rating icon"
              />
              <div className={styles.bannerDivider}></div>
              <img
                className={styles.bannerSmile}
                src={smileIcon}
                alt="smile icon"
              />
              <div className={styles.bannerText}>
                We like the online appointments, we also love the texting. Steer
                by Mechanic Advisor has definitely been awesome and it’s working
                very well for us.
              </div>
              <div className={styles.bannerAuthor}>
                Enzo, Owner at Mike More Miles – Lockport
              </div>
              <div className={styles.quotesIcon}></div>
            </div>
          </div>
          <Solution
            title="Why Steer?"
            description="In the auto repair industry, it's crucial to balance efficient operations with excellent customer service. Steer CRM makes this easy for all auto shops, whether you have one location or many."
            className="middleSolution"
          >
            <div className={styles.poligon1}></div>
            <div className={styles.dashboard}></div>
          </Solution>
          <Solution
            title="Learn how Steer supports shops of all sizes"
            description="Leveraging our platform not only streamlines your operations but has been shown to boost repeat sales revenue by up to 30%."
            className="afterDashboardSolution"
          >
            <div className={styles.poligon2}></div>
            <Advantage cards={ADVANTAGES_CARDS.SHOPS_SUPPORT}>
              <div className={styles.personImg1}></div>
            </Advantage>
          </Solution>
          <Solution
            title="Data & security"
            description="We prioritize the protection of your shop’s information and customer data, ensuring a safe and trustworthy experience."
          >
            <Advantage cards={ADVANTAGES_CARDS.SECURITY} variant="Reverse">
              <div className={styles.personImg2}></div>
            </Advantage>
          </Solution>
          <div className={styles.partnerships}>
            <div className={styles.textTitle}>
              <div className={styles.title}>Successful partnerships</div>
              <div className={styles.description}>
                Through one of our strategic partnerships, we onboarded and
                tracked <b>134 stores</b> over a <b>12-month</b> period. Here
                are the highlights.
              </div>
            </div>
            <div className={styles.highlights}>
              {HIGHLIGHTS.map(({ value, description }, i) => (
                <div className={styles.highlight} key={i}>
                  <div className={styles.highlightNumber}>{value}</div>
                  <div className={styles.highlightDescription}>
                    {description}
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.partnershipsIcon}></div>
          </div>
        </div>
        <div className={styles.integrations}>
          <div className={styles.imgOne} />
          <div className={styles.content}>
            <div className={styles.title}>Popular integrations</div>
            <div className={styles.description}>
              Our platform offers extensive compatibility, integrating with 20+
              Shop Management Systems.
            </div>
            <Link to={PATHS.INTEGRATIONS} className={styles.link}>
              <span>See All Integrations</span>
              <img className={styles.linkIcon} src={icon}></img>
              <img className={styles.linkIconDark} src={iconDark}></img>
            </Link>
          </div>
          <div className={styles.imgTwo} />
        </div>
      </div>
    </>
  );
};

export default EnterpriseSolutions;
