import React from "react";
import * as styles from "./TextLink.module.scss";
import { Link } from "gatsby";
import icon from "../../../assets/icons/i-arrow-up-active.svg";
import iconDark from "../../../assets/icons/i-arrow-up-dark-blue.svg";

type TextLinkProps = {
  text: string;
  to: string;
  className?: string;
  onClick?: () => void;
  isArrowed?: boolean;
};

const TextLink: React.FC<TextLinkProps> = ({
  text,
  to,
  className = "",
  onClick,
  isArrowed,
}) => {
  return (
    <Link
      className={`${styles.textLink} ${className}`}
      to={to}
      onClick={onClick}
    >
      {text}
      {isArrowed && (
        <>
          <img className={styles.linkIcon} src={icon}></img>
          <img className={styles.linkIconDark} src={iconDark}></img>
        </>
      )}
    </Link>
  );
};

export default TextLink;
