import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import ProtractorPage from "../components/Protractor";

const Protractor: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return <ProtractorPage />;
};

export default Protractor;
