import React from "react";
import * as styles from "./Footer.module.scss";
import { PATHS } from "../../constants";

export const BannerPartners: React.FC = () => {
  return (
    <>
      <div className={`${styles.banner} ${styles.bannerPartners}`}>
        <p className={`${styles.title} ${styles.titlePartners}`}>
          Learn what Steer CRM can do for your shop
        </p>
        <a href={PATHS.SUPPORT_REQUEST} style={{ color: "inherit" }}>
          <div className={`${styles.button} ${styles.buttonPartners}`}>
            Contact Us
            <span className={styles.arrow}>&#8594;</span>
          </div>
        </a>
        <div className={`${styles.icon} ${styles.iconPartners}`}></div>
      </div>
      <div className={styles.bannerPlaceholder}></div>
    </>
  );
};
