import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import ServiceRemindersProduct from "../components/ServiceReminders";

const ServiceReminders: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return <ServiceRemindersProduct />;
};

export default ServiceReminders;
