import React from "react";
import * as styles from "./CustomerTestimonials.module.scss";
import starsIcon from "../../assets/icons/i-stars-green.svg";

type TestimonialProps = {
  text: string;
  author: string;
  className?: string;
};

export const Testimonial: React.FC<TestimonialProps> = ({
  text,
  author,
  className = "",
}) => {
  return (
    <div className={`${styles.testimonial} ${className}`}>
      <img className={styles.stars} src={starsIcon} alt="rating icon" />
      <div className={styles.text}>{text}</div>
      <div className={styles.author}>{author}</div>
    </div>
  );
};
