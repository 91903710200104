import React, { useEffect, useState } from "react";
import * as styles from "./SubscribePopup.module.scss";
import authorIcon1 from "../../assets/images/Home/author1.png";
import authorIcon2 from "../../assets/images/Home/author2.png";
import authorIcon3 from "../../assets/images/Home/author3.png";

export function getNextActive(curr: number) {
  return curr < 2 ? curr + 1 : 0;
}
export function getPrevActive(curr: number) {
  return curr > 0 ? curr - 1 : 2;
}

const text1 =
  "This is the absolute best CRM company we have worked with! I’m so happy with them, their system creates such an easy way for us to communicate with our customers and has helped us get so many more reviews! Great software that has boosted our communication with customers immensely.";
const text2 =
  "Huge shot out to the entire team at Mechanic Advisor. Always a quick and knowledgeable response to whatever needs we have. They are always available to help us to move our shop to the next level. I truly recommend any shop that is looking for CRM, SMS and/or scheduling to check out this program. You will not be disappointed!";
const text3 =
  "We have this product for our auto repair business. It has made our lives soooo much easier. The clients love the techs love it the admin loves it. If you're contemplating, wait no more. Go for it. It pays for itself. The staff is helpful. It makes running your business so easy.";

type SlideProps = {
  text: string;
  author: string;
  active?: boolean;
  slideRight: boolean;
};

const Slide: React.FC<SlideProps> = ({ text, author, active, slideRight }) => {
  return (
    <div
      className={`${styles.slide} ${active ? styles.slideActive : ""} ${
        slideRight ? styles.slideFromRight : styles.slideFromLeft
      }`}
    >
      <p className={styles.text}>{text}</p>
      <p className={styles.author}>{author}</p>
    </div>
  );
};

type CarouselProps = {};

const Carousel: React.FC<CarouselProps> = ({}) => {
  const [slideFromRight, setSlideFromRight] = useState(true);
  const [auto, setAuto] = useState(true);
  const [active, setActive] = useState(0);

  const handlePrev = () => {
    setAuto(false);
    setActive(getPrevActive(active));
    setSlideFromRight(false);
  };
  const handleNext = () => {
    setAuto(false);
    setActive(getNextActive(active));
    setSlideFromRight(true);
  };
  const handleChange = (newActive: number) => {
    if (active === newActive) return;
    setSlideFromRight(newActive > active ? true : false);
    setAuto(false);
    setActive(newActive);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (auto) {
        setActive(getNextActive(active));
      }
    }, 10000);
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [active]);
  return (
    <div className={styles.carousel}>
      <div className={styles.slidesWrap}>
        <div className={styles.btnPrevWrap}>
          <button className={styles.btnPrev} onClick={handlePrev}></button>
        </div>
        <div className={styles.btnNextWrap}>
          <button className={styles.btnNext} onClick={handleNext}></button>
        </div>
        <div className={styles.icons}>
          <img
            src={authorIcon1}
            alt="author"
            className={`${styles.icon} ${
              active === 0
                ? `${styles.iconActive} ${styles.order1}`
                : active === 1
                ? styles.order0
                : styles.order2
            }
            `}
          />
          <img
            src={authorIcon2}
            alt="author"
            className={`${styles.icon} ${
              active === 1
                ? `${styles.iconActive} ${styles.order1}`
                : active === 0
                ? styles.order2
                : styles.order0
            }`}
          />
          <img
            src={authorIcon3}
            alt="author"
            className={`${styles.icon} ${
              active === 2
                ? `${styles.iconActive} ${styles.order1}`
                : active === 1
                ? styles.order2
                : styles.order0
            }`}
          />
        </div>

        <div className={styles.slides}>
          <Slide
            text={text1}
            author="Rachel Kosack"
            active={active === 0}
            slideRight={slideFromRight}
          />
          <Slide
            text={text2}
            author="Jessica Pangelinan"
            active={active === 1}
            slideRight={slideFromRight}
          />
          <Slide
            text={text3}
            author="Lanell Jackson"
            active={active === 2}
            slideRight={slideFromRight}
          />
        </div>
      </div>
      <div className={styles.indicators}>
        <div
          className={`${styles.indicator} ${
            active === 0 ? styles.indicatorActive : ""
          }`}
          onClick={() => handleChange(0)}
        ></div>
        <div
          className={`${styles.indicator} ${
            active === 1 ? styles.indicatorActive : ""
          }`}
          onClick={() => handleChange(1)}
        ></div>
        <div
          className={`${styles.indicator} ${
            active === 2 ? styles.indicatorActive : ""
          }`}
          onClick={() => handleChange(2)}
        ></div>
      </div>
    </div>
  );
};

export default Carousel;
