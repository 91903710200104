import React from "react";
import * as styles from "./divider.module.scss";

type DividerProps = {
  className?: string;
};

export const HSplit: React.FC<DividerProps> = ({ className = "" }) => {
  return <div className={`${styles.hSplit} ${className}`}></div>;
};

export const VSplit: React.FC<DividerProps> = ({ className = "" }) => {
  return <div className={`${styles.vSplit} ${className}`}></div>;
};
