import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { Helmet } from "react-helmet";
import { FeaturesSlider } from "../components/Shared/FeaturesSlider";
import OnlineSchedulingProduct from "../components/OnlineScheduling";

const OnlineScheduling: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return (
    <>
      <Helmet>
        <title>Auto Repair Shop Scheduling Software | Steer</title>
      </Helmet>
      <OnlineSchedulingProduct />
      <FeaturesSlider excludedSlideIdx={5} />
    </>
  );
};

export default OnlineScheduling;
