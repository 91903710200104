import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { Helmet } from "react-helmet";
import { FeaturesSlider } from "../components/Shared/FeaturesSlider";
import TelematicsProduct from "../components/Telematics";

const Telematics: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return (
    <>
      <Helmet>
        <title>Vehicle Telematics System | Steer</title>
        <meta
          name="description"
          content="Get your customers to return for necessary repairs and services with our vehicle telematics system. Steer CRM — an automated marketing and communication platform for automotive shops."
        />
      </Helmet>
      <TelematicsProduct />
      <FeaturesSlider excludedSlideIdx={7} />
    </>
  );
};

export default Telematics;
