import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import ShopWarePage from "../components/ShopWare";

const ShopWare: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return <ShopWarePage />;
};

export default ShopWare;
