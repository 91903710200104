import React from "react";
import * as styles from "./SubscribePopup.module.scss";
import iconError from "../../assets/icons/i-warning.svg";

export const Error: React.FC<{
  onTryAgain: () => void;
}> = ({ onTryAgain }) => {
  return (
    <div className={styles.meetingsFormWrapper}>
      <div className={styles.meetingsFormContainer}>
        <div className={styles.error}>
          <img className={styles.iconError} src={iconError} alt="warning" />
          <h1 className={styles.title}>Well, This Is Unexpected</h1>
          <p className={styles.body}>
            {`Sorry, sometimes things don't go according to plan.\nPlease try booking your meeting again.`}
          </p>
          <button className={styles.btnError} onClick={onTryAgain}>
            Try Booking Again
          </button>
          <p className={styles.body}>
            Or email our Steer Sales team directly at
            <br />
            <a href="mailto:sales@steercrm.com">sales@steercrm.com</a>
          </p>
        </div>
      </div>
    </div>
  );
};
