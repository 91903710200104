import React, { useState, useEffect } from "react";
import * as styles from "./SupportRequest.module.scss";

const HubspotContactForm = ({
  onSubmit,
  onSuccess,
}: {
  onSubmit: () => void;
  onSuccess: () => void;
}) => {
  const [hubspotLoadingTries, setHubspotLoadingTries] = useState(1);
  const [isFormLoading, setIsFormLoading] = useState(true);

  useEffect(() => {
    if (typeof hbspt !== "undefined") {
      hbspt.forms.create({
        region: "na1",
        portalId: "6191634",
        formId: "c97cff4d-c1fb-41ec-96d9-dc850e30cd69",
        target: "#hubspotForm",
        onFormReady: setIsFormLoading(false),
        onFormSubmit: onSubmit,
        onFormSubmitted: onSuccess,
      });
    } else if (hubspotLoadingTries < 10) {
      // wait for hubspot more if total tries is less that 10
      setTimeout(
        () => setHubspotLoadingTries((x) => x + 1),
        hubspotLoadingTries * 500
      );
    }
  }, [hubspotLoadingTries]);

  return (
    <div
      className={`${styles.formWrapper} animated`}
      style={isFormLoading ? { display: "none" } : {}}
    >
      <h1 className={`${styles.formTitle} animated`}>Drop Us a Line</h1>
      <div id="hubspotForm"></div>
    </div>
  );
};

export default HubspotContactForm;
