import React, { useRef } from "react";
import * as styles from "./Layout.module.scss";
import { useOnScreen } from "../../hooks";
import Footer from "../Footer";
import Header from "../Header";
import GoTopButton from "../GoTopButton";

const Layout: React.FC = ({ children }) => {
  const elementRef = useRef(null);
  const isFooterOnScreen = useOnScreen(elementRef);

  return (
    <div className={styles.layout}>
      <Header />
      <div className={`${styles.pageContainer}`}>{children}</div>
      <GoTopButton isFooterShown={isFooterOnScreen} />
      <div ref={elementRef}>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
