import React, { useEffect, useRef } from "react";
import * as styles from "./VideoTestimonials.module.scss";
import { Link } from "@reach/router";
import icon from "../../../assets/icons/i-arrow-up-active.svg";
import iconDark from "../../../assets/icons/i-arrow-up-dark-blue.svg";
import { animateOnScroll } from "../../../services/animationService";
import { VideoCarousel } from "./VideoCarousel";

type TestimonialProps = {
  link?: string;
};

export const VideoTestimonials: React.FC<TestimonialProps> = ({ link }) => {
  const refTitle = useRef(null);
  const refDescription = useRef(null);
  const refLink = useRef(null);
  const refs = [refTitle, refDescription];
  if (link) {
    refs.push(refLink);
  }

  useEffect(() => animateOnScroll(refs.map((ref) => ref.current)), []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.testimonialsHeader}>
        <div className={styles.title} ref={refTitle}>
          See why thousands of auto repair shops trust Steer
        </div>
        <div className={styles.description} ref={refDescription}>
          Hear from real shop owners who made the switch to the leading auto
          repair CRM solution.
        </div>
        {link && (
          <Link to={link} className={styles.link} ref={refLink}>
            <span>View More Customer Testimonials</span>
            <img className={styles.linkIcon} src={icon}></img>
            <img className={styles.linkIconDark} src={iconDark}></img>
          </Link>
        )}
      </div>

      <VideoCarousel />
    </div>
  );
};
