import React, { useEffect, useRef } from "react";
import steerVideo from "../../../assets/video/steerVideo.mp4";
import * as styles from "./Promotions.module.scss";
import { animateVideoOnScroll } from "../../../services/animationService";

export default function Video() {
  const videoRef = useRef(null);

  useEffect(() => animateVideoOnScroll(videoRef.current), []);

  return (
    <div className={styles.videoWrapper}>
      <video className={styles.video} muted playsInline ref={videoRef}>
        <source src={steerVideo} type="video/mp4" />
      </video>
    </div>
  );
}
