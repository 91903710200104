import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import CustomerTestimonialsPage from "../components/CustomerTestimonials";

const CustomerTestimonials: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return <CustomerTestimonialsPage />;
};

export default CustomerTestimonials;
