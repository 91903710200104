import React from "react";
import * as styles from "./Footer.module.scss";
import { PATHS } from "../../constants";
import cn from "classnames";

export const BannerEnterprise: React.FC = () => {
  return (
    <>
      <div className={cn(styles.banner, styles.bannerEnterprise)}>
        <p className={cn(styles.title, styles.titleEnterprise)}>
          Let’s drive forward, together
        </p>
        <p className={cn(styles.subtitle, styles.subtitleEnterprise)}>
          Interested in exploring an enterprise partnership with Steer?
        </p>

        <a href={PATHS.SUPPORT_REQUEST} style={{ color: "inherit" }}>
          <div className={cn(styles.button, styles.buttonEnterprise)}>
            Contact Us
            <span className={styles.arrow}>&#8594;</span>
          </div>
        </a>
        <div className={cn(styles.icon, styles.iconEnterprise)}></div>
      </div>
      <div className={styles.bannerPlaceholder}></div>
    </>
  );
};
