import React, { useState, useRef, useEffect } from "react";
import cn from "classnames";
import * as styles from "./Home.module.scss";
import { EarlyAccessForm } from "../Shared/EarlyAccessForm";
import { animateOnScroll } from "../../services/animationService";
import { StaticImage } from "gatsby-plugin-image";
import ReactPlayer from "react-player";
import icon from "../../assets/icons/i-arrow-up-white.svg";
import { useScreenSize } from "../../hooks";

export const Hero = () => {
  const refHero = useRef(null);
  const refHeroTitle = useRef(null);
  const refHeroBody = useRef(null);
  const refHeroImg = useRef(null);
  const refHeroPolygonUp = useRef(null);
  const refHeroPolygonDown = useRef(null);
  const refHeroAudiImg = useRef(null);
  const refHeroFordImg = useRef(null);
  const refHeroDeclinedServicesImg = useRef(null);
  const refheroGirlImg = useRef(null);
  const refs = [
    refHero,
    refHeroTitle,
    refHeroBody,
    refHeroImg,
    refHeroPolygonUp,
    refHeroPolygonDown,
    refHeroAudiImg,
    refHeroFordImg,
    refHeroDeclinedServicesImg,
    refheroGirlImg,
  ];

  const refReactPlayer = useRef(null);

  const [isVideoShown, setIsVideoShown] = useState(false);
  const [isVideoFormShown, setIsVideoFormShown] = useState(false);
  const videoUrl = "https://vimeo.com/795613605";
  const { isMobile, isTablet } = useScreenSize();

  useEffect(() => animateOnScroll(refs.map((ref) => ref.current)), []);

  useEffect(() => {
    if (document !== undefined) {
      if (isVideoShown || isVideoFormShown) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "overlay";
      }
    }
  }, [isVideoShown, isVideoFormShown]);

  return (
    <div className={styles.heroWrap}>
      <div className={styles.hero} ref={refHero}>
        <div className={styles.heroText}>
          <div className={styles.heroTextWrap}>
            <h1 className={styles.heroTitle} ref={refHeroTitle}>
              Grow revenue & customer loyalty with Steer
            </h1>
            <p className={styles.heroBody} ref={refHeroBody}>
              Steer is changing the way auto shops communicate with their
              customers through our automated retention marketing and customer
              communication platform.
            </p>
            <EarlyAccessForm animated className="HeroSectionForm" />
          </div>
        </div>
        <div className={styles.heroImgWrap}>
          <div className={styles.heroImg} ref={refHeroImg}>
            <div className={styles.heroAudiImg}>
              <div
                data-animateparent
                className={styles.animationTrigger}
                ref={refHeroAudiImg}
              ></div>
            </div>
            <div className={styles.heroFordImg}>
              <div
                data-animateparent
                className={styles.animationTrigger}
                ref={refHeroFordImg}
              ></div>
            </div>
            <div className={styles.heroGirlImgWrapper}>
              <div
                data-animateparent
                className={styles.animationTrigger}
                ref={refheroGirlImg}
              ></div>

              {isMobile ? (
                <StaticImage
                  src="../../assets/images/Hero/hero-girl-mobile.png"
                  alt="girl"
                  className={styles.heroGirlImg}
                  loading="eager"
                  quality={100}
                  style={{
                    position: "absolute",
                  }}
                />
              ) : isTablet ? (
                <StaticImage
                  src="../../assets/images/Hero/hero-girl-tablet.png"
                  alt="girl"
                  className={styles.heroGirlImg}
                  loading="eager"
                  quality={100}
                  style={{
                    position: "absolute",
                  }}
                />
              ) : (
                <StaticImage
                  src="../../assets/images/Hero/hero_girl.png"
                  alt="girl"
                  className={styles.heroGirlImg}
                  loading="eager"
                  quality={100}
                  style={{
                    position: "absolute",
                  }}
                />
              )}
              <div
                className={styles.heroButton}
                onClick={() => {
                  setIsVideoShown(true);
                }}
              >
                <div className={styles.buttonCircle}>
                  <div className={styles.buttonTriangle}></div>
                </div>
              </div>
            </div>
            <div className={styles.heroDeclinedServicesImg}>
              <div
                data-animateparent
                className={styles.animationTrigger}
                ref={refHeroDeclinedServicesImg}
              ></div>
            </div>
          </div>
        </div>
        <div className={styles.poligonUp} ref={refHeroPolygonUp}></div>
        <div className={styles.poligonDown} ref={refHeroPolygonDown}></div>
      </div>
      {isVideoShown && (
        <div className={cn(styles.modal)}>
          <div
            className={styles.buttonWrapper}
            onClick={() => {
              setIsVideoShown(false);
            }}
          >
            <div className={styles.closeIcon}></div>
          </div>
          <ReactPlayer
            ref={refReactPlayer}
            url={videoUrl}
            config={{
              vimeo: {
                playerOptions: {
                  controls: true,
                  transparent: true,
                  pip: false,
                  keyboard: true,
                  playsinline: true,
                  title: false,
                },
              },
            }}
            className={styles.videoPlayer}
            onEnded={() => {
              setIsVideoFormShown(true);
              setIsVideoShown(false);
            }}
            playing={isVideoShown}
          ></ReactPlayer>
        </div>
      )}
      {isVideoFormShown && (
        <div className={cn(styles.modal, styles.form)}>
          <div
            className={styles.buttonWrapper}
            onClick={() => setIsVideoFormShown(false)}
          >
            <div className={styles.closeIcon}></div>
          </div>
          <VideoForm onClick={() => setIsVideoFormShown(false)} />
        </div>
      )}
    </div>
  );
};

type VideoFormProps = {
  onClick(): void;
};

function VideoForm({ onClick }: VideoFormProps) {
  return (
    <div className={styles.videoForm}>
      <div className={styles.titleVideoForm}>
        Enter your email address to request a demo
      </div>
      <EarlyAccessForm className="VideoForm" onCloseClick={onClick} />
      <div className={styles.skipBtn} onClick={onClick}>
        <span>Skip</span>
        <img className={styles.skipIcon} src={icon}></img>
      </div>
    </div>
  );
}
