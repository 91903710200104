export const PATHS = {
  HOME: "/",
  TEXT_MESSAGING: "/products/text-messaging",
  EMAIL_MARKETING: "/products/email-marketing",
  DIRECT_MAIL: "/products/direct-mail-postcards",
  SERVICE_REMINDERS: "/products/service-reminders",
  REPUTATION_MANAGEMENT: "/products/reputation-management",
  ONLINE_SCHEDULING: "/products/online-scheduling",
  WEBSITE_CHAT: "/products/website-chat",
  TELEMATICS: "/products/telematics",
  ADVANCED_REPORTING: "/",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  STEERMATICS_TERMS_AND_CONDITIONS: "/steermatics-terms-and-conditions",
  SUPPORT_REQUEST: "/support-request",
  ENTERPRISE_SOLUTIONS: "/enterprise-solutions",
  ABOUT_US: "/about-us",
  CUSTOMER_TESTIMONIALS: "/customer-testimonials",
  INTEGRATIONS: "/shop-management-integrations",
  PARTNERS: "/our-partners",
  PRICING: "/pricing-plans",
  ADVANCE_PROFESSIONAL: "/advance-professional",
  PROTRACTOR: "/protractor",
  SHOP_WARE: "/shop-ware",
  TEKMETRIC: "/tekmetric",
  VAST_SQL: "/vast-SQL",
  RESERVE_WITH_GOOGLE: "/reserve-with-google",
  CAREERS_AT_STEER: "/careers",
};

export const SOCIAL_MEDIA = {
  FACEBOOK: "https://www.facebook.com/steercrm",
  LINKEDIN: "https://www.linkedin.com/company/steercrm",
  TWITTER: "https://x.com/steercrm",
  INSTAGRAM: "https://www.instagram.com/steercrm",
  YOUTUBE: "https://www.youtube.com/@steercrm",
};

export const MECHANIC_ADVISOR_LINK = "https://mechanicadvisor.com";

export const SCREEN_MOBILE = 768;
export const SCREEN_TABLET = 1024;
export const SCREEN_LARGE = 1200;
export const SCREEN_EXTRA_LARGE = 1600;
export const SCREEN_VIEW_SIZES = {
  MOBILE: "mobile",
  TABLET: "tablet",
  MIDDLE_PC: "middlePC",
  PERSONAL_COMPUTER: "personalComputer",
  LARGE_PERSONAL_COMPUTER: "largePersonalComputer",
};
