import React from "react";
import PartnerHero from "../Shared/PartnerHero";
import * as styles from "./Tekmetric.module.scss";
import { imgTekmetric } from "../../assets/images/Tekmetric";
import {
  iconStar,
  iconCloudSync,
  iconCalendar,
} from "../../assets/icons/SinglePartner";
import Card from "../Shared/Card/Card";
import icon from "../../assets/icons/i-arrow-up-active.svg";
import iconDark from "../../assets/icons/i-arrow-up-dark-blue.svg";
import { useHabspotLinkLoad } from "../../hooks";

const FEATURES = [
  {
    title: "Real-time data sync",
    description:
      "Steer and Tekmetric sync in real-time allowing you to keep your shop running smoothly. View customer service records, future and past appointments, and accurate contact information on both platforms.",
    icon: iconCloudSync,
  },
  {
    title: "Two-way calendar integration",
    description:
      "View your service schedule, appointment requests, and customer service history, with total accuracy on both platforms.",
    icon: iconCalendar,
  },
  {
    title: "Increase customer retention",
    description:
      "Tekmetric allows you to view deferred services and customer history, while Steer enables you to maintain contact with your customer. Help customers get the repairs they need while maintaining shop efficiency.",
    icon: iconStar,
  },
];

const Tekmetric = ({}) => {
  const isHabspotLoaded = useHabspotLinkLoad(
    "cdfeaa57-074b-4f2c-888a-7376a66791d6"
  );

  return (
    <>
      <PartnerHero
        textTitle="Tekmetric"
        description="Tekmetric is a leading Shop Management System that has partnered with Steer CRM to provide direct-sync-capabilities, so you can manage your shop and marketing more efficiently than ever. Tekmetric is a part of the Aftermarket Technology Connection along with Advance Auto parts."
        partnerLogo={imgTekmetric}
        className={styles.textTitle}
        cnLogoPartner={styles.tekmetric}
        linkTitle="Interested in Tekmetric Shop Management System?"
        demoLinkTitle="Already a Tekmetric user?"
        hasSectionTitle
      >
        {!isHabspotLoaded && (
          <span
            className="hs-cta-wrapper"
            id="hs-cta-wrapper-cdfeaa57-074b-4f2c-888a-7376a66791d6"
          >
            <span
              className="hs-cta-node hs-cta-cdfeaa57-074b-4f2c-888a-7376a66791d6"
              id="hs-cta-cdfeaa57-074b-4f2c-888a-7376a66791d6"
            >
              <div id="hs-cta-ie-element"></div>
              <a
                href="https://cta-redirect.hubspot.com/cta/redirect/6191634/cdfeaa57-074b-4f2c-888a-7376a66791d6"
                target="_blank"
                rel="noopener"
                className={styles.link}
              >
                <span>Visit Website</span>
                <img className={styles.linkIcon} src={icon}></img>
                <img className={styles.linkIconDark} src={iconDark}></img>
              </a>
            </span>
          </span>
        )}
        {isHabspotLoaded && (
          <a className={styles.link}>
            <span>Visit Website</span>
            <img className={styles.linkIcon} src={icon}></img>
            <img className={styles.linkIconDark} src={iconDark}></img>
          </a>
        )}
      </PartnerHero>
      <div className={styles.wrapper}>
        <div className={styles.innerWrapper}>
          <div className={styles.title}>Tekmetric features</div>
          <div className={styles.features}>
            {FEATURES.map(({ title, description, icon }, i) => (
              <Card
                title={title}
                description={description}
                icon={icon}
                key={i}
                variant="Partner"
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Tekmetric;
