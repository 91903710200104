import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import CareersAtSteerPage from "../components/Careers";

const CareersAtSteer: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return <CareersAtSteerPage />;
};

export default CareersAtSteer;
