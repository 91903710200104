// extracted by mini-css-extract-plugin
export var wrapper = "PrivacyPolicy-module--wrapper--3i7kH";
export var section = "PrivacyPolicy-module--section--2x50d";
export var sectionText = "PrivacyPolicy-module--sectionText--2IJHK";
export var textWraper = "PrivacyPolicy-module--textWraper--1w1Vv";
export var sectionName = "PrivacyPolicy-module--sectionName--a2s3Q";
export var textTitle = "PrivacyPolicy-module--textTitle--3Fgej";
export var description = "PrivacyPolicy-module--description--3sW5m";
export var policyWrapper = "PrivacyPolicy-module--policyWrapper--3bHIx";
export var policy = "PrivacyPolicy-module--policy--r-Gwl";
export var policyTitle = "PrivacyPolicy-module--policyTitle--2l0fg";
export var policyDescription = "PrivacyPolicy-module--policyDescription--1aRTF";
export var termsOfUse = "PrivacyPolicy-module--termsOfUse--2f9oE";