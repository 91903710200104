import React from "react";
import * as styles from "./MiddleSection.module.scss";

type MiddleSectionProps = {
  textTitle: string;
  description?: string;
  children?: any;
  tools?: Array<{ toolTitle: string; toolDescription: string; icon?: any }>;
  icon?: any;
  cls?: string;
  clsImg?: string;
  clsDescription?: string;
  isReversed?: boolean;
};

const MiddleSection: React.FC<MiddleSectionProps> = ({
  textTitle,
  description,
  tools,
  children,
  icon,
  cls,
  clsImg,
  clsDescription,
  isReversed,
}) => {
  return (
    <div className={`${styles.wrapper} ${cls && styles[cls]}`}>
      <div className={`${styles.section} ${isReversed && styles.reversed}`}>
        <div className={styles.sectionText}>
          <div className={styles.textWraper}>
            <h1 className={styles.textTitle}>{textTitle}</h1>
            <p
              className={`${styles.description} ${
                !description && styles.noDescription
              } ${clsDescription && styles[clsDescription]}`}
            >
              {description}
            </p>
            {tools?.map((tool, i) => (
              <FeatureTool
                toolTitle={tool.toolTitle}
                toolDescription={tool.toolDescription}
                toolIcon={tool.icon || icon}
                toolTitleCls={tool.className}
                key={i}
              />
            ))}
          </div>
        </div>
        <div className={`${styles.imagesWrapper} ${clsImg && styles[clsImg]}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

type SectionTool = {
  toolTitle: string;
  toolTitleCls?: any;
  toolDescription: string;
  toolIcon: any;
};

const FeatureTool: React.FC<SectionTool> = ({
  toolTitle,
  toolDescription,
  toolIcon,
  toolTitleCls,
}) => {
  return (
    <div className={styles.toolWrapper}>
      <img className={styles.toolIcon} src={toolIcon} alt="icon" />
      <div>
        <div className={`${styles.toolTitle} ${styles[toolTitleCls]}`}>
          {toolTitle}
        </div>
        <div className={styles.toolDescription}>{toolDescription}</div>
      </div>
    </div>
  );
};

export default MiddleSection;
