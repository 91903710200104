import React from "react";
import MiddleSection from "../../Shared/MiddleSection";
import * as styles from "./TextMessagingFeatures.module.scss";
import iconAppointmentReminders from "../../../assets/icons/TextMessaging/i-textmessaging-appointment-reminders.svg";
import iconSendNotifications from "../../../assets/icons/TextMessaging/i-textmessaging-send-notifications.svg";
import iconSendReceive from "../../../assets/icons/TextMessaging/i-textmessaging-send-receive.svg";
import iconServiceRecommendation from "../../../assets/icons/TextMessaging/i-textmessaging-service-recommendation.svg";

const INCREASE_CUSOMER_RESPONSE_RATE_TOOLS = [
  {
    toolTitle: "Send & receive SMS photos",
    toolDescription:
      "Connect to your customers with our web-based text messaging platform. Provide timely customer service with the ability to send and receive text and photo messages.",
    icon: iconSendReceive,
  },
  {
    toolTitle: "Send customer offers or notifications",
    toolDescription:
      "Sync your existing customers through our Shop Management System integrations to easily send customized text message offers based on service history, vehicle model and more.",
    icon: iconSendNotifications,
  },
];

const AUTOMATED_TEXT_REMINDERS_TOOLS = [
  {
    toolTitle: "Appointment reminders",
    toolDescription:
      "Create automated text message alerts reminding a customer of their upcoming appointments. Reducing no show appointments and cancellations.",
    icon: iconAppointmentReminders,
  },
  {
    toolTitle: "Service recommendation",
    toolDescription:
      "Send friendly text message reminders to customers when they have a declined or recommended service due.",
    icon: iconServiceRecommendation,
  },
];

export const TextMessagingFeatures = () => {
  return (
    <>
      <MiddleSection
        textTitle="Increase customer response rate"
        description="On average, a customer will respond to a text message from their Auto Repair Shop within 3 minutes."
        tools={INCREASE_CUSOMER_RESPONSE_RATE_TOOLS}
        cls="textMessagingUpper"
      >
        <div className={styles.image}></div>
      </MiddleSection>
      <MiddleSection
        textTitle="Automated text reminders & follow-up"
        tools={AUTOMATED_TEXT_REMINDERS_TOOLS}
        isReversed
        cls="textMessagingBottom"
        clsImg="textMessagingBottomImg"
      >
        <div className={styles.secondImage}></div>
      </MiddleSection>
    </>
  );
};
