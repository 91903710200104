import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { Helmet } from "react-helmet";
import IntegrationsPage from "../components/Integrations";

const Integrations: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return (
    <>
      <Helmet>
        <title>Shop Management Software Integrations | Steer</title>
      </Helmet>
      <IntegrationsPage />
    </>
  );
};

export default Integrations;
