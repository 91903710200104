import React, { useEffect, useRef, useState } from "react";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import cn from "classnames";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import * as styles from "./VideoTestimonials.module.scss";
import "./slick.css";
import {
  imgCarmedix,
  imgCreech,
  imgPlayButton,
  imgCreechSlide,
  imgCarmedixSlide,
} from "../../../assets/images/VideoTestimonials";
import { useScreenSize } from "../../../hooks";

const SHOPS_DESCRIPTION = [
  {
    logo: imgCarmedix,
    logoClassName: styles.imgCarmedix,
    description:
      "Jay Huh, Owner of Carmedix, started his shop out of his home garage in 2015 because he saw early on that there was a need for a more transparent repair experience for customers. Since then, he started using Steer to help his shop become one of the most popular in the area, competing alongside 17+ others within a 3-mile radius.",
  },
  {
    logo: imgCreech,
    logoClassName: styles.imgCreech,
    description:
      "Stan Creech started Creech Import Repair in Raleigh, NC, in 1993 with just one lift, a toolbox, and a bucket to sit on. Listen to his story and how his business has grown into what it is today, servicing an average of 30 cars daily, thanks to technology and Steer.",
  },
];

const VIDEO_LIST = [
  {
    name: "Carmedix",
    videoUrl: "https://www.youtube.com/watch?v=Yn9nLZtYtnc&amp;t=7s",
    img: imgCarmedixSlide,
  },
  {
    name: "Creech",
    videoUrl: "https://www.youtube.com/watch?v=ya9Q-Qmqs4Y&amp;t=10s",
    img: imgCreechSlide,
  },
];

export const VideoCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  let sliderRef = useRef(null);
  const { windowWidth } = useScreenSize();
  const isDotsShown = windowWidth < 1024;

  const settings = {
    dots: isDotsShown,
    arrows: true,
    infinite: !isFullscreen,
    swipeToSlide: true,
    variableWidth: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    beforeChange: (oldIndex: number, newIndex: number) => {
      setCurrentIndex(newIndex);
    },

    customPaging: () => {
      return <button></button>;
    },
    nextArrow: (
      <SliderArrow cls={styles.slickNextArrow} onClick={() => next()} />
    ),
    prevArrow: (
      <SliderArrow cls={styles.slickPrevArrow} onClick={() => previous()} />
    ),
  };

  const next = () => sliderRef.current.slickNext();
  const previous = () => sliderRef.current.slickPrev();

  return (
    <div
      className={cn(styles.videoTestimonials, {
        [styles.centered]: isFullscreen,
      })}
    >
      <div className={styles.testimonial}>
        <img
          src={SHOPS_DESCRIPTION[currentIndex].logo}
          alt="logo"
          className={cn(
            styles.logo,
            SHOPS_DESCRIPTION[currentIndex].logoClassName
          )}
        />
        <div className={styles.text}>
          {SHOPS_DESCRIPTION[currentIndex].description}
        </div>
      </div>

      {isFullscreen && (
        <>
          <RemoveScrollBar gapMode="padding" />
          {currentIndex === VIDEO_LIST.length - 1 ? (
            <div
              className={cn(
                styles.slickButton,
                styles.videoArrow,
                styles.videoPrevArrow
              )}
              onClick={previous}
            >
              <div className={styles.arrowIcon}></div>
            </div>
          ) : (
            <div
              className={cn(
                styles.slickButton,
                styles.videoArrow,
                styles.videoNextArrow
              )}
              onClick={next}
            >
              <div className={styles.arrowIcon}></div>
            </div>
          )}
        </>
      )}
      <Slider
        ref={sliderRef}
        {...settings}
        className={cn({
          [styles.fullScreenVideo]: isFullscreen,
        })}
      >
        {VIDEO_LIST.map((video, i) => (
          <div key={i}>
            <VideoCard
              {...video}
              currentIndex={currentIndex}
              isFullscreen={isFullscreen}
              handleFullscreen={setIsFullscreen}
              isPlaying={isFullscreen && currentIndex === i}
              handleOnNext={next}
              isActive={currentIndex === i}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

type Card = {
  videoUrl: string;
  img: string;
  currentIndex?: any;
  isFullscreen: boolean;
  isPlaying?: boolean;
  isActive?: boolean;
  handleFullscreen: (arg: boolean) => void;
  handleOnNext: () => void;
};

const VideoCard: React.FC<Card> = ({
  videoUrl,
  img,
  currentIndex,
  isFullscreen,
  isPlaying,
  handleFullscreen,
  handleOnNext,
  isActive,
}) => {
  const playerRef = useRef(null);

  const handleClickPreview = () => {
    handleFullscreen(true);
  };

  const handleOnClose = () => {
    handleFullscreen(false);
    playerRef.current.showPreview();
  };

  useEffect(() => {
    if (isPlaying) {
      playerRef.current.handleClickPreview();
    } else {
      playerRef.current.showPreview();
    }
  }, [currentIndex]);

  return (
    <div
      className={styles.videoCard}
      onClick={() => !isActive && handleOnNext()}
    >
      {isFullscreen && (
        <div
          className={styles.buttonWrapper}
          onClick={() => {
            handleOnClose();
          }}
        >
          <div className={styles.closeIcon}></div>
        </div>
      )}
      <ReactPlayer
        url={videoUrl}
        light={<img src={img} className={styles.preview} />}
        ref={playerRef}
        playing={isPlaying}
        config={{
          youtube: {
            playerVars: {
              controls: 1,
              playsinline: 1,
              rel: 0,
              iv_load_policy: 3,
              hd: 1,
              showinfo: 0,
            },
          },
        }}
        playIcon={
          <img src={imgPlayButton} alt="play" className={styles.playButton} />
        }
        onClickPreview={() => isActive && handleClickPreview()}
        onEnded={handleOnNext}
        className={styles.videoPlayer}
      ></ReactPlayer>
    </div>
  );
};

const SliderArrow = ({ cls, onClick }) => {
  return (
    <div
      className={cn(styles.slickButton, styles.slickArrow, cls)}
      onClick={onClick}
    >
      <div className={styles.arrowIcon}></div>
    </div>
  );
};
