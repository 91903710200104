import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import { useScreenSize } from "../../hooks";
import cn from "classnames";
import * as styles from "./ReserveWithGoogle.module.scss";
import UpperSection from "../Shared/UpperSection";
import {
  ReserveWithGoogleIcon,
  GetInstantComfirmationIcon,
  ShowServicesIcon,
  SyncSchedulerIcon,
} from "../../assets/icons/ReserveGoogle";
import iconArrow from "../../assets/icons/i-arrow-up-dark-grey.svg";
import iconArrowLight from "../../assets/icons/i-arrow-up-light-grey.svg";
import Card from "../Shared/Card/Card";
import { FeaturesSlider } from "../Shared/FeaturesSlider";
import {
  ANALYTICS_EVENTS,
  addAnalyticsEvent,
} from "../../services/analyticsService";
import SubscribePopup from "../SubscribePopup";

const CARDS = [
  {
    title: "Streamline scheduling",
    description:
      "With a simple search, potential customers can see your availability and lock in an appointment in real-time, all without navigating away from their Google search.",
  },
  {
    title: "Get instant confirmations",
    description:
      "No more waiting around for manual confirmations. Once a customer books through Google, they get an immediate confirmation, saving both of you time. ",
    icon: GetInstantComfirmationIcon,
  },
  {
    title: "Sync with Steer's scheduler",
    description:
      "Have your Google appointments automatically sync with your Steer calendar for zero double bookings!",
    icon: SyncSchedulerIcon,
  },
  {
    title: "Show off your services",
    description:
      "Give potential customers a clear and complete picture of everything you offer.",
    icon: ShowServicesIcon,
  },
];

const ADVANTAGES = [
  {
    title: "Harness Google reviews for enhanced trust",
    description:
      "A satisfied customer is more likely to leave a positive review. With direct Google integration, encourage happy clients to share their experience, bolstering your shop's reputation.",
    className: "imgAdvantage1",
  },
  {
    title: "Stand out in search results",
    description:
      "Capitalize on 'Near Me' searches and turn search results into bookings.",
    className: "imgAdvantage2",
  },
];

const ReserveWithGooglePage = () => {
  const { isMobile } = useScreenSize();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const FREQUENTLY_ASKED_QUESTIONS = [
    {
      question: "How do I setup Reserve with Google in Steer?",
      answer: (
        <div>
          <span>
            <span>
              Shops must turn on <b>Accept All Appointments</b> in their
              Calendar settings for this integration to work. The default
              settings for this field are <b>Require Approval</b>, so you'll
              need to change that. <br />
            </span>
            <span className={styles.paragraph}>
              Once this is completed, your Steer account will be matched with
              your Google Business Profile. It can take up to 1 day for your
              "Book" button to appear on your profile. Still have questions?
              Check out our Knowledge Center article{" "}
              <a
                href="https://help.steercrm.com/knowledge/how-to-use-reserve-with-google-in-your-shop?utm_campaign=MKTG+-+CPGN+-+Reserve+with+Google&utm_medium=email&_hsenc=p2ANqtz-_B6RH5BqDlRBlhsHcad1P6j86ZCngEz9dUun5pEyG8f3cTE7BirE0mNwuv0aL6Y9Fm5rW_&utm_source=hs_email"
                className={styles.link}
              >
                here
              </a>
              .
            </span>
          </span>
          <div className={styles.answerImg}></div>
        </div>
      ),
    },
    {
      question: "Are there any associated costs?",
      answer: (
        <span>Nope! This is 100% free to use with your Steer membership.</span>
      ),
    },
    {
      question:
        "What are the differences between this integration and my Business Profile on Google?",
      answer: (
        <span>
          <span>
            A{" "}
            <a href="https://www.google.com/business/" className={styles.link}>
              Business Profile
            </a>{" "}
            on Google is a way for a business to show up on Google Search and
            Maps and interact with customers online. A business can showcase all
            key details such as business hours, contact information, and
            services offered.
          </span>
          <span className={styles.paragraph}>
            The Reserve with Google integration is an add-on feature that links
            up to your Business Profile and allows customers to be redirected to
            your online appointment scheduler by clicking a blue “Book Online”
            button placed prominently on profile. These products work together
            to help you reach new customers for appointments.
          </span>
        </span>
      ),
    },
    {
      question: "What if I don't have a Google Business listing?",
      answer: (
        <span>
          You need a claimed and verified Business Profile on Google to use
          Reserve with Google.
        </span>
      ),
    },
  ];

  return (
    <>
      <UpperSection
        sectionNameIcon={ReserveWithGoogleIcon}
        textTitle="Supercharge your shop's schedule through Reserve with Google"
        description="Our collaboration with Google means one thing: effortless bookings and the potential for busier bays. Your customers can now book your services straight from their search results. No fuss, just more business."
        clsLeft="ReserveWithGoogleHero"
        clsRight="ReserveWithGoogleImg"
        formClass="Hidden"
        className={styles.hero}
        isDemoBtnShown
        demoBtnTitle="Claim Your Google Book Button"
        clsBtn={styles.demoBtn}
      >
        {!isMobile && (
          <StaticImage
            src="../../assets/images/ReserveGoogle/hero-image.png"
            alt="google search image"
            className={styles.heroImg}
            loading="eager"
            quality={100}
            objectFit="contain"
            style={{
              position: "absolute",
            }}
          />
        )}
      </UpperSection>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <SectionTitle
            title="Reach 72% of Google users searching for local businesses"
            description="Keep your calendar busy and tap into the millions of potential customers searching for services you offer through Google Search and Maps."
            className={styles.topSectionTitle}
          />
        </div>
      </div>
      <div className={styles.bannerWrapper}>
        <div className={styles.emptyContainer}></div>
        <div className={styles.container}>
          <div className={styles.bannerCard}>
            <div className={styles.bannerTitle}>How it works</div>
            <div className={styles.bannerDescription}>
              Setup is easy and done through Steer. Once you sign-up, we'll walk
              you through how Reserve with Google adds a "Book" button to your
              Google Business Profile so customers can book your services.
              <br />
              <br />
              Ready to turn your Google Business Profile into a booking engine?
            </div>
            <div
              className={styles.bannerButton}
              onClick={() => {
                addAnalyticsEvent(ANALYTICS_EVENTS.getEarlyAccessClick);
                setOpen(true);
              }}
            >
              Book a Demo
            </div>
          </div>
          <div className={styles.bannerImg}></div>
        </div>
        <div className={styles.emptyContainer}></div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <SectionTitle
            title="Unlock a new channel"
            description="Put the power in the consumer's hands and make it as easy as possible for them to discover your business and make an appointment with you."
            name="Seamless Integration"
            className={styles.middleSectionTitle}
          />
          <div className={styles.cardsContainer}>
            {CARDS.map(({ title, description, icon }, i) => (
              <Card
                title={title}
                description={description}
                key={i}
                variant="GoogleReserve"
                icon={icon}
              />
            ))}
          </div>
          <SectionTitle
            title="Put your business ahead of the competition"
            name="Enhanced Exposure"
          />
          <div className={styles.advantagesContainer}>
            {ADVANTAGES.map(({ title, description, className }, i) => (
              <div className={styles.advantage} key={i}>
                <div
                  className={`${styles.advantageImgWrap} ${styles[className]}`}
                ></div>
                <div className={styles.advantageText}>
                  <div className={styles.advantageTitle}>{title}</div>
                  <div className={styles.advantageDescription}>
                    {description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <FeaturesSlider
        title="Online booking is just the beginning"
        variant="GoogleReserve"
      />
      <div className={styles.wrapper}>
        <div className={styles.FAQSections}>
          <div className={styles.info}>
            <div className={styles.title}>Frequently asked questions</div>
          </div>
          <div className={styles.main}>
            {FREQUENTLY_ASKED_QUESTIONS.map((el, i) => (
              <Question {...el} key={i} />
            ))}
          </div>
        </div>
      </div>

      {open && (
        <>
          <RemoveScrollBar gapMode="padding" />
          <SubscribePopup close={handleClose} />
        </>
      )}
    </>
  );
};

export default ReserveWithGooglePage;

type SectionTitleProps = {
  title: string;
  description?: string;
  className?: any;
  name?: string;
};

const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  description,
  className = "",
  name,
}) => {
  return (
    <div className={`${styles.sectionTitle}  ${className}`}>
      {name && <div className={styles.name}>{name}</div>}
      <div className={styles.title}>{title}</div>
      {description && <div className={styles.description}>{description}</div>}
    </div>
  );
};

type QuestionProps = {
  question: string;
  answer: JSX.Element;
};

const Question: React.FC<QuestionProps> = ({ question, answer }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleIsExpanded = () => setIsExpanded((prev) => !prev);
  const [hover, setHover] = useState(false);

  return (
    <div
      className={cn(styles.questionWrapper, { [styles.expanded]: isExpanded })}
    >
      <div
        className={styles.question}
        onClick={toggleIsExpanded}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <div
          className={cn(styles.text, {
            [styles.textLight]: hover,
          })}
        >
          {question}
        </div>
        {hover ? (
          <img src={iconArrowLight} alt="icon" className={styles.arrowIcon} />
        ) : (
          <img src={iconArrow} alt="icon" className={styles.arrowIcon} />
        )}
      </div>
      <div className={styles.answer}>{answer}</div>
    </div>
  );
};
