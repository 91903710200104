import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { Helmet } from "react-helmet";
import { FeaturesSlider } from "../components/Shared/FeaturesSlider";
import WebsiteChatProduct from "../components/WebsiteChat";

const WebsiteChat: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return (
    <>
      <Helmet>
        <title>Auto Repair Live Chat | Steer</title>
      </Helmet>
      <WebsiteChatProduct />
      <FeaturesSlider excludedSlideIdx={6} />
    </>
  );
};

export default WebsiteChat;
