import React from "react";
import * as styles from "./Advantage.module.scss";
import Card from "../../Shared/Card/Card";

type AdvantageProps = {
  cards: Array<{ title: string; description: string; icon?: any }>;
  variant?: string;
  children?: any;
};

const Advantage: React.FC<AdvantageProps> = ({ cards, variant, children }) => {
  return (
    <div className={`${styles.wrapper} ${styles[`variant${variant}`]}`}>
      <div className={`${styles.container} ${styles[`variant${variant}`]}`}>
        <div className={styles.cards}>
          {cards.map((card, i) => (
            <Card {...card} key={i} variant="Additional" />
          ))}
        </div>
      </div>
      {children}
    </div>
  );
};

export default Advantage;
