import React, { useRef, useEffect, useState } from "react";
import * as styles from "./UpperSection.module.scss";
import { EarlyAccessForm } from "../EarlyAccessForm";
import { animateOnScroll } from "../../../services/animationService";
import {
  ANALYTICS_EVENTS,
  addAnalyticsEvent,
} from "../../../services/analyticsService";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import SubscribePopup from "../../SubscribePopup";
import cn from "classnames";

type UpperSectionProps = {
  sectionName?: string;
  textTitle: string;
  description: string;
  children?: any;
  className?: any;
  clsLeft?: string;
  clsRight?: string;
  formClass?: string;
  clsWrapper?: any;
  isDemoBtnShown?: boolean;
  clsBtn?: any;
  demoBtnTitle?: string;
  sectionNameIcon?: string;
  externalLink?: string;
  linkTitle?: string;
  buttonHandler?: () => void;
};

const UpperSection: React.FC<UpperSectionProps> = ({
  sectionName,
  textTitle,
  description,
  children,
  className,
  clsLeft = "",
  clsRight = "",
  formClass = "",
  clsWrapper,
  isDemoBtnShown = false,
  clsBtn,
  demoBtnTitle = "",
  sectionNameIcon,
  externalLink,
  linkTitle,
  buttonHandler,
}) => {
  const [open, setOpen] = useState(false);
  const refSectionName = useRef(null);
  const refTextTitle = useRef(null);
  const refDescription = useRef(null);
  const refSection = useRef(null);
  const refImgWrapper = useRef(null);
  const refDemoBtn = useRef(null);
  const refSectionNameIcon = useRef(null);
  const refExternalLink = useRef(null);

  const refs = [refTextTitle, refDescription, refSection, refImgWrapper];

  if (isDemoBtnShown) {
    refs.push(refDemoBtn);
  }

  if (sectionNameIcon) {
    refs.push(refSectionNameIcon);
  }

  if (sectionName) {
    refs.push(refSectionName);
  }

  if (externalLink || buttonHandler) {
    refs.push(refExternalLink);
  }

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => animateOnScroll(refs.map((ref) => ref.current)), []);

  return (
    <>
      <div className={`${styles.wrapper} ${clsWrapper}`}>
        <div className={`${styles.section} ${className}`} ref={refSection}>
          <div
            className={`${styles.sectionText} ${clsLeft && styles[clsLeft]}`}
          >
            <div className={styles.textWraper}>
              {sectionName && (
                <div className={styles.sectionName} ref={refSectionName}>
                  {sectionName}
                </div>
              )}
              {sectionNameIcon && (
                <img
                  src={sectionNameIcon}
                  className={styles.sectionNameIcon}
                  ref={refSectionNameIcon}
                  alt="section name icon"
                />
              )}
              <h1 className={styles.textTitle} ref={refTextTitle}>
                {textTitle}
              </h1>
              <p className={styles.description} ref={refDescription}>
                {description}
              </p>
              <EarlyAccessForm className={formClass} animated />
              {isDemoBtnShown && (
                <button
                  className={cn(styles.demoBtn, clsBtn)}
                  onClick={() => {
                    addAnalyticsEvent(ANALYTICS_EVENTS.getEarlyAccessClick);
                    setOpen(true);
                  }}
                  ref={refDemoBtn}
                >
                  {demoBtnTitle}
                </button>
              )}
              {externalLink && (
                <a
                  href={externalLink}
                  className={cn(styles.demoBtn, clsBtn)}
                  target={"_blank"}
                  rel="nofollow"
                  ref={refExternalLink}
                >
                  {linkTitle}
                </a>
              )}
              {buttonHandler && (
                <div
                  className={cn(styles.demoBtn, clsBtn)}
                  ref={refExternalLink}
                  onClick={buttonHandler}
                >
                  {linkTitle}
                </div>
              )}
            </div>
          </div>

          <div
            className={cn(styles.imagesWrapper, clsRight && styles[clsRight])}
            ref={refImgWrapper}
          >
            {children}
          </div>
        </div>
      </div>
      {open && (
        <>
          <RemoveScrollBar gapMode="padding" />
          <SubscribePopup close={handleClose} />
        </>
      )}
    </>
  );
};

export default UpperSection;
