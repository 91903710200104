import React from "react";
import * as styles from "./FeaturesSlider.module.scss";
import Slider from "react-slick";
import { Link } from "@reach/router";
import cn from "classnames";
import { PATHS } from "../../../constants";
import chevronIcon from "../../../assets/icons/i-arrow-up-active.svg";
import chevronIconDark from "../../../assets/icons/i-arrow-up-dark-blue.svg";

const FEATURES = [
  {
    title: "Email marketing",
    description:
      "Our email marketing service automates email campaigns, notifications, and important service reminders.",
    imagesClass: styles.emailMarketing,
    link: PATHS.EMAIL_MARKETING,
  },
  {
    title: "Service reminders & alerts",
    description:
      "Provide your customers with friendly reminders when they are past due for a routine visit and/or have a pending declined service.",
    imagesClass: styles.serviceReminder,
    link: PATHS.SERVICE_REMINDERS,
  },
  {
    title: "Text messaging",
    description:
      "Get instantaneous responses and send automated alerts with our easy-to-use text messaging platform.",
    imagesClass: styles.textMessaging,
    link: PATHS.TEXT_MESSAGING,
  },
  {
    title: "Direct mail postcards",
    description:
      "Make your business standout with our professional postcard mailing service. Send high quality postcards with a click of a button.",
    imagesClass: styles.postcardMailing,
    link: PATHS.DIRECT_MAIL,
  },
  {
    title: "Reputation management",
    description:
      "Collect customer reviews on all the major review websites and keep track of all the reviews you are receiving.",
    imagesClass: styles.reputationManagement,
    link: PATHS.REPUTATION_MANAGEMENT,
  },
  {
    title: "Online scheduling",
    description:
      "Our automotive appointment software allows businesses to easily manage their customer appointments and gives potential customers the ability to schedule appointments online.",
    imagesClass: styles.onlineScheduling,
    link: PATHS.ONLINE_SCHEDULING,
  },
  {
    title: "Website chat",
    description:
      "Give potential customers the opportunity to send your business a text message directly from your website.",
    imagesClass: styles.websiteChat,
    link: PATHS.WEBSITE_CHAT,
  },
  {
    title: "Telematics",
    description:
      "Provide commercial fleets with a remote diagnostic service program and helped them prevent unforeseen repairs.",
    imagesClass: styles.telematics,
    link: PATHS.TELEMATICS,
  },
  {
    title: "Advanced reporting",
    description:
      "Full performance tracking and customer insights that will help improve your business.",
    imagesClass: styles.advancedReporting,
    link: PATHS.ADVANCED_REPORTING,
  },
];

export const Carousel = ({ excludedSlideIdx, variant = "" }) => {
  const settings = {
    arrows: true,
    infinite: true,
    swipeToSlide: true,
    variableWidth: true,
    dots: true,
    slidesToScroll: 2,
    customPaging: () => {
      return <button></button>;
    },
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={cn(styles.slider, styles[`variant${variant}`])}>
      <Slider {...settings}>
        {FEATURES.map(
          (feature, i) =>
            i !== excludedSlideIdx && (
              <div className={styles.slide} key={i}>
                <Card {...feature} />
              </div>
            )
        )}
      </Slider>
    </div>
  );
};

type Card = {
  title: string;
  description: string;
  imagesClass: string;
  link: string;
};

const Card: React.FC<Card> = ({ title, description, imagesClass, link }) => (
  <div className={`${styles.card} ${imagesClass}`}>
    <div className={styles.title}>{title}</div>
    <div className={styles.description}>{description}</div>
    <Link to={link} className={styles.link}>
      <span>Learn More</span>
      <img className={styles.linkIcon} src={chevronIcon}></img>
      <img className={styles.linkIconDark} src={chevronIconDark}></img>
    </Link>
    <div className={imagesClass}></div>
  </div>
);
