import React from "react";
import PartnerHero from "../Shared/PartnerHero";
import * as styles from "./ShopWare.module.scss";
import { imgShopWare } from "../../assets/images/OurPartners";
import {
  iconInfo,
  iconCloudSync,
  iconCalendar,
} from "../../assets/icons/SinglePartner";
import Card from "../Shared/Card/Card";
import icon from "../../assets/icons/i-arrow-up-active.svg";
import iconDark from "../../assets/icons/i-arrow-up-dark-blue.svg";
import { useHabspotLinkLoad } from "../../hooks";

const FEATURES = [
  {
    title: "Real-time data sync",
    description:
      "View accurate customer details, service information, and appointment calendars with real-time data sync.",
    icon: iconCloudSync,
  },
  {
    title: "Two-way calendar integration",
    description:
      "View accurate customer details, service information, and appointment calendars with real-time data sync.",
    icon: iconCalendar,
  },
  {
    title: "Deferred services information",
    description:
      "Keep track of customer service recommendations and utilize Steer’s communication tools to keep customers returning.",
    icon: iconInfo,
  },
];

const ShopWare = ({}) => {
  const isHabspotLoaded = useHabspotLinkLoad(
    "a95d1f26-d36c-4401-8196-cdd7f3525546"
  );

  return (
    <>
      <PartnerHero
        textTitle="Shop-Ware"
        description="Shop-Ware is a preferred Shop Management System that has partnered with Steer CRM to provide powerful integrations for your shop. Transform your shop with automotive shop software that generates more profits."
        partnerLogo={imgShopWare}
        className={styles.textTitle}
        cnLogoPartner={styles.shopWare}
        linkTitle="Interested in Shop-Ware Shop Management System?"
        demoLinkTitle="Already a Shop-Ware user?"
        hasSectionTitle
      >
        {!isHabspotLoaded && (
          <span
            className="hs-cta-wrapper"
            id="hs-cta-wrapper-a95d1f26-d36c-4401-8196-cdd7f3525546"
          >
            <span
              className="hs-cta-node hs-cta-a95d1f26-d36c-4401-8196-cdd7f3525546"
              id="hs-cta-a95d1f26-d36c-4401-8196-cdd7f3525546"
            >
              <div id="hs-cta-ie-element"></div>
              <a
                href="https://cta-redirect.hubspot.com/cta/redirect/6191634/a95d1f26-d36c-4401-8196-cdd7f3525546"
                target="_blank"
                rel="noopener"
                className={styles.link}
              >
                <span>Visit Website</span>
                <img className={styles.linkIcon} src={icon}></img>
                <img className={styles.linkIconDark} src={iconDark}></img>
              </a>
            </span>
          </span>
        )}
        {isHabspotLoaded && (
          <a className={styles.link}>
            <span>Visit Website</span>
            <img className={styles.linkIcon} src={icon}></img>
            <img className={styles.linkIconDark} src={iconDark}></img>
          </a>
        )}
      </PartnerHero>
      <div className={styles.wrapper}>
        <div className={styles.innerWrapper}>
          <div className={styles.title}>Shop-Ware features</div>
          <div className={styles.features}>
            {FEATURES.map(({ title, description, icon }, i) => (
              <Card
                title={title}
                description={description}
                icon={icon}
                key={i}
                variant="Partner"
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopWare;
