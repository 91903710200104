import { useEffect, useState } from "react";

const fixedHeaderPositionY = 400;
const headerAnimationDurationMs = 420;

export function useHeaderPosition() {
  const [fixed, setFixed] = useState(false);
  const [dismissed, setDismissed] = useState(false);

  useEffect(() => {
    const listenScrollEvent = () => {
      if (window.scrollY >= fixedHeaderPositionY) {
        setFixed(true);
      } else if (window.scrollY < fixedHeaderPositionY && fixed) {
        setDismissed(true);

        setTimeout(() => {
          setDismissed(false);
          setFixed(false);
        }, headerAnimationDurationMs);
      }
    };
    listenScrollEvent();
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, [fixed, dismissed]);

  return [fixed, dismissed];
}
