import React, { useEffect, useState } from "react";
import { Router } from "@reach/router";
import "../styles/index.scss";
import Layout from "../components/Layout";
import {
  NotFound,
  Home,
  TextMessaging,
  EmailMarketing,
  ReputationManagement,
  PrivacyPolicy,
  TermsAndConditions,
  SteermaticsTermsAndConditions,
  DirectMail,
  OnlineScheduling,
  SupportRequest,
  Telematics,
  WebsiteChat,
  EnterpriseSolutions,
  AboutUs,
  CustomerTestimonials,
  Integrations,
  Partners,
  PricingPlans,
  AdvanceProfessional,
  Protractor,
  ShopWare,
  Tekmetric,
  VastSQL,
  ServiceReminders,
  ReserveWithGoogle,
  CareersAtSteer,
} from "../routes";
import AppLoader from "../components/AppLoader";
import { PATHS } from "../constants";
import { Helmet } from "react-helmet";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => setIsLoading(false), []);

  return isLoading ? (
    <AppLoader />
  ) : (
    <Layout>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
        <title>Steer | Auto Repair Shop Marketing and CRM</title>
        <meta
          name="description"
          content="Get your customers to return for necessary repairs and services with Steer CRM — an automated marketing and communication platform for automotive shops."
        />
      </Helmet>
      <Router>
        <Home path="/" />
        <Home path="/rw" />
        <TextMessaging path={PATHS.TEXT_MESSAGING} />
        <EmailMarketing path={PATHS.EMAIL_MARKETING} />
        <ReputationManagement path={PATHS.REPUTATION_MANAGEMENT} />
        <PrivacyPolicy path={PATHS.PRIVACY_POLICY} />
        <DirectMail path={PATHS.DIRECT_MAIL} />
        <OnlineScheduling path={PATHS.ONLINE_SCHEDULING} />
        <Telematics path={PATHS.TELEMATICS} />
        <WebsiteChat path={PATHS.WEBSITE_CHAT} />
        <NotFound path="*" />
        <SupportRequest path={PATHS.SUPPORT_REQUEST} />
        <EnterpriseSolutions path={PATHS.ENTERPRISE_SOLUTIONS} />
        <AboutUs path={PATHS.ABOUT_US} />
        <CustomerTestimonials path={PATHS.CUSTOMER_TESTIMONIALS} />
        <Integrations path={PATHS.INTEGRATIONS} />
        <Partners path={PATHS.PARTNERS} />
        <PricingPlans path={PATHS.PRICING} />
        <TermsAndConditions path={PATHS.TERMS_AND_CONDITIONS} />
        <SteermaticsTermsAndConditions
          path={PATHS.STEERMATICS_TERMS_AND_CONDITIONS}
        />
        <AdvanceProfessional path={PATHS.ADVANCE_PROFESSIONAL} />
        <Protractor path={PATHS.PROTRACTOR} />
        <ShopWare path={PATHS.SHOP_WARE} />
        <Tekmetric path={PATHS.TEKMETRIC} />
        <VastSQL path={PATHS.VAST_SQL} />
        <ServiceReminders path={PATHS.SERVICE_REMINDERS} />
        <ReserveWithGoogle path={PATHS.RESERVE_WITH_GOOGLE} />
        <CareersAtSteer path={PATHS.CAREERS_AT_STEER} />
      </Router>
    </Layout>
  );
};

const IndexPage = () => {
  return <App />;
};

export default IndexPage;
