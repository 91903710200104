import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import cn from "classnames";
import * as styles from "./JobBoard.module.scss";
import {
  iconLocation,
  iconTime,
  iconEmptySearch,
} from "../../assets/icons/Careers";
import Select from "../Shared/SelectInput";

const CONFIG = {
  departmentStyles: {
    4025315007: {
      backgroundColor: "rgba(73, 80, 90, 0.07)",
      color: "#49505A",
    },
    4025316007: {
      backgroundColor: "rgba(73, 80, 90, 0.07)",
      color: "#49505A",
    },
    4025317007: {
      backgroundColor: "rgba(73, 80, 90, 0.07)",
      color: "#49505A",
    },
    4025318007: {
      backgroundColor: "rgba(40, 190, 195, 0.07)",
      color: "#24ADB1",
    },
    4025319007: {
      backgroundColor: "rgba(248, 177, 49, 0.07)",
      color: "#E1950D",
    },
    4025320007: {
      backgroundColor: "rgba(121, 180, 18, 0.07)",
      color: "#79B412",
    },
    4025321007: {
      backgroundColor: "rgba(251, 109, 3, 0.07)",
      color: "#FB6D03",
    },
    4025322007: {
      backgroundColor: "rgba(43, 187, 251, 0.07)",
      color: "#2BBBFB",
    },
    4025323007: {
      backgroundColor: "rgba(20, 164, 98, 0.07)",
      color: "#14A462",
    },
    4025324007: {
      backgroundColor: "rgba(255, 81, 81, 0.07)",
      color: "#FF5151",
    },
    4025325007: {
      backgroundColor: "rgba(120, 69, 209, 0.07)",
      color: "#8B439B",
    },
    4025326007: {
      backgroundColor: "rgba(87, 95, 207, 0.07)",
      color: "#575FCF",
    },
    4025327007: {
      backgroundColor: "rgba(16, 138, 249, 0.07)",
      color: "#108AF9",
    },
    default: { backgroundColor: "rgba(215, 40, 47, 0.07)", color: "#D7282F" },
  },
};

const JobBoard = () => {
  const [isLoading, setLoading] = useState(true);
  const [departments, setDepartments] = useState({});
  const [offices, setOffices] = useState({});
  const [jobs, setJobs] = useState([]);
  const [filter, setFilter] = useState({
    department: 0,
    office: 0,
    type: 0, // 0 All, 1 Full-Time, 2 Part-Time
  });
  const [filteredJobs, setFilteredJobs] = useState([]);

  useEffect(() => {
    (async function () {
      const { jobs, offices, departments } = await getGreenhouseData();

      const { jobsList, officesMap, departmentsMap } = formatGreenhouseData({
        jobs,
        offices,
        departments,
      });

      setDepartments(departmentsMap);
      setOffices(officesMap);
      setJobs(jobsList);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    const { department, office, type } = filter;
    let newFilteredJobs = [...jobs];

    if (department) {
      newFilteredJobs = newFilteredJobs.filter(
        (job) =>
          job.department_id === department ||
          job.parent_department_id === department
      );
    }

    if (office) {
      newFilteredJobs = newFilteredJobs.filter((job) => {
        let { office_id } = job;
        while (office_id) {
          if (office_id === office) return true;
          office_id = offices[office_id]?.parent_id;
        }

        return false;
      });
    }

    if (type) {
      if (type === 1) {
        newFilteredJobs = newFilteredJobs.filter((job) => job.isFullTime);
      } else {
        newFilteredJobs = newFilteredJobs.filter((job) => !job.isFullTime);
      }
    }

    setFilteredJobs(newFilteredJobs);
  }, [filter, jobs]);

  const departmentFilterOptions = useMemo(() => {
    const structuredDepartments = {};

    for (const [key, { name, parent_id }] of Object.entries(departments)) {
      const id = Number(key);

      if (!parent_id) {
        if (!structuredDepartments[id]) {
          structuredDepartments[id] = [{ id, value: name }];
        } else {
          structuredDepartments[id].unshift({ id, value: name });
        }
      } else {
        if (!structuredDepartments[parent_id]) {
          structuredDepartments[parent_id] = [
            { id, value: name, className: "secondary" },
          ];
        } else {
          structuredDepartments[parent_id].push({
            id,
            value: name,
            className: "secondary",
          });
        }
      }
    }

    return Object.values(structuredDepartments).flat();
  }, [departments]);
  const officeFilterOptions = useMemo(() => {
    const officeTree = {};
    for (let { id, name, parent_id } of Object.values(offices)) {
      const parentChain = [];
      while (parent_id) {
        parentChain.push(parent_id);
        parent_id = offices[parent_id].parent_id;
      }
      parentChain.reverse();
      const className = ["", "second", "third"][parentChain.length];

      let treePointer = officeTree;
      for (let parentId of parentChain) {
        if (!treePointer[parentId]) treePointer[parentId] = { children: {} };
        treePointer = treePointer[parentId].children;
      }

      if (!treePointer[id]) treePointer[id] = { children: {} };
      treePointer[id].id = id;
      treePointer[id].value = name;
      treePointer[id].className = className;
    }

    const addOfficeAndChildrenToList = (office, list) => {
      list.push(office);
      Object.values(office.children).forEach((childOffice) => {
        addOfficeAndChildrenToList(childOffice, list);
      });
    };

    const officeList = Object.values(officeTree).reduce((acc, office) => {
      addOfficeAndChildrenToList(office, acc);
      return acc;
    }, []);

    return officeList;
  }, [offices]);

  const updateFilterBy = (key) => (value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  if (isLoading) return <div className={styles.board} />;

  return (
    <div className={styles.board}>
      <div className={styles.filters}>
        <Select
          options={departmentFilterOptions}
          onChange={({ id }) => updateFilterBy("department")(id)}
          value={departments[filter.department].name}
          dropdownHeight={"390px"}
        />
        <Select
          options={officeFilterOptions}
          onChange={({ id }) => updateFilterBy("office")(id)}
          value={offices[filter.office].name}
          dropdownHeight={"390px"}
        />
        <TypeFilter value={filter.type} onChange={updateFilterBy("type")} />
      </div>
      <div className={styles.line} />
      {filteredJobs.length ? (
        <div className={styles.cards}>
          {filteredJobs.map((job, i) => (
            <Card job={job} key={i} />
          ))}
        </div>
      ) : (
        <div className={styles.emptySearch}>
          <img src={iconEmptySearch} alt="empty search icon" />
          <div className={styles.text}>
            <div className={styles.heading}>No Results Found</div>
            <p>We can't find any results corresponding to your request.</p>
            <p>Please try to change the filter configuration.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobBoard;

const TypeFilter = ({ value, onChange }) => {
  const handleClick = (e) => {
    const newValue = Number(e.target.attributes["data-id"]?.value || value);
    if (newValue !== value) onChange(newValue);
  };

  return (
    <div className={styles.typeFilter} onClick={handleClick}>
      <div
        data-id={0}
        className={cn(styles.button, { [styles.active]: value === 0 })}
      >
        All job types
      </div>
      <div
        data-id={1}
        className={cn(styles.button, { [styles.active]: value === 1 })}
      >
        Full-Time
      </div>
      <div
        data-id={2}
        className={cn(styles.button, { [styles.active]: value === 2 })}
      >
        Part-Time
      </div>
    </div>
  );
};

const Card = ({ job = {} }) => {
  const {
    title,
    description,
    isRemote,
    isOnsite,
    isFullTime,
    isMultipleOpenings,
    url,
    office_name,
    department_id,
    department_name,
    department_style,
    parent_department_id,
    parent_department_name,
    parent_department_style,
  } = job;
  return (
    <div className={styles.card}>
      <div className={styles.departments}>
        {parent_department_id && (
          <div className={styles.label} style={parent_department_style}>
            {parent_department_name}
          </div>
        )}
        {department_id && (
          <div className={styles.label} style={department_style}>
            {department_name}
          </div>
        )}
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.location}>{`${office_name} ${
        isMultipleOpenings ? "(Multiple Openings)" : ""
      }`}</div>
      <div className={styles.description}>
        {description || "Description unavailable"}
      </div>
      <div className={styles.line} />
      <div className={styles.details}>
        <div className={styles.tag}>
          <img src={iconLocation} className={styles.icon} alt="location icon" />
          {(isRemote && "Remote") || (isOnsite && "Onsite") || "Hybrid"}
        </div>

        <div className={styles.tag}>
          <img src={iconTime} className={styles.icon} alt="time icon" />
          {isFullTime ? "Full-Time" : "Part-Time"}
        </div>
        <div className={styles.line} />
        <a target="_blank" href={url} className={styles.button}>
          More Details
        </a>
      </div>
    </div>
  );
};

async function getGreenhouseData() {
  const [
    {
      data: { departments },
    },
    {
      data: { offices },
    },
    {
      data: { jobs },
    },
  ] = await Promise.all([
    axios.get(
      `https://boards-api.greenhouse.io/v1/boards/steercrm/departments`
    ),
    axios.get(`https://boards-api.greenhouse.io/v1/boards/steercrm/offices`),
    axios.get(
      `https://boards-api.greenhouse.io/v1/boards/steercrm/jobs?content=true`
    ),
  ]);

  return { jobs, offices, departments };
}

function formatGreenhouseData({ jobs, offices, departments }) {
  const departmentsMap = departments.reduce((acc, department) => {
    const { id, parent_id, name } = department;
    acc[id] = { id, parent_id, name };
    return acc;
  }, {});
  departmentsMap["0"] = { parent_id: null, name: "All Departments" };

  const officeIdByNameMap = {};

  const officesMap = offices.reduce((acc, office) => {
    const { id, parent_id, name } = office;
    acc[id] = {
      id,
      parent_id,
      name: name.replace(
        / \(Remote\)| - Remote| \(Onsite\)| - Onsite| \(Hybrid\)| - Hybrid/i,
        ""
      ),
    };
    officeIdByNameMap[name] = id;
    return acc;
  }, {});
  officesMap["0"] = { id: 0, parent_id: null, name: "All Offices" };

  const uniqueJobIds = [];
  const jobsList = jobs.reduce((acc, input) => {
    const {
      absolute_url,
      id,
      title,
      content,
      departments,
      location,
      metadata = [],
    } = input;

    const isDuplicate = uniqueJobIds.some((i) => i === title + location.name);
    if (isDuplicate) return acc;
    uniqueJobIds.push(title + location.name);

    const job = {
      url: absolute_url,
      id,
    };

    job.title = title.replace(
      / \(Remote\)| - Remote| \(Onsite\)| - Onsite| \(Hybrid\)| - Hybrid/i,
      ""
    );

    job.isRemote = /remote/i.test(`${title}`);

    job.isOnsite = /onsite/i.test(`${title}`);

    job.isFullTime = true;

    job.office_id = officeIdByNameMap[location.name];

    job.office_name = job.office_id
      ? officesMap[job.office_id].name
      : location.name;
    if (job.office_name) {
      job.office_name = job.office_name.replace(
        / \(Remote\)| - Remote| \(Onsite\)| - Onsite| \(Hybrid\)| - Hybrid/i,
        ""
      );
    }

    const multipleOpeningsData = metadata.find(
      (data) => data.id === 8397378007 // "name": "Multiple Openings"
    );
    job.isMultipleOpenings = multipleOpeningsData
      ? multipleOpeningsData.value
      : false;

    const descriptionMatch = content.match(
      /(?:about the role[\s\S]*?&gt;)(?!&)(.+?)(?=&lt;)/i
    );
    job.description = descriptionMatch
      ? descriptionMatch[1].replace("&#39;", "'")
      : null;

    const { id: department_id, parent_id: parent_department_id } =
      departments[0];

    job.department_id = department_id;
    if (department_id) {
      job.department_name = departmentsMap[department_id].name;
      job.department_style =
        CONFIG.departmentStyles[department_id] ||
        CONFIG.departmentStyles.default;
    }

    job.parent_department_id = parent_department_id;
    if (parent_department_id) {
      job.parent_department_name = departmentsMap[parent_department_id].name;
      job.parent_department_style =
        CONFIG.departmentStyles[parent_department_id] ||
        CONFIG.departmentStyles.default;
    }

    acc.push(job);

    return acc;
  }, []);

  return { jobsList, officesMap, departmentsMap };
}
