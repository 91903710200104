import React, { useRef, useEffect } from "react";
import * as styles from "./Promotions.module.scss";
import Promo from "./Promo";
import icon from "../../../assets/icons/i-arrow-up-active.svg";
import iconDark from "../../../assets/icons/i-arrow-up-dark-blue.svg";
import {
  iconCampaignsEmail,
  iconCampaignsLetter,
  iconCampaignsPostcard,
  iconCampaignsTextMessage,
} from "../../../assets/icons/Home";
import Video from "./Video";
import { animateOnScroll } from "../../../services/animationService";
import { Link } from "@reach/router";
import { PATHS } from "../../../constants";

const CAMPAIGNS = [
  {
    title: "Text message campaigns",
    icon: iconCampaignsTextMessage,
  },
  {
    title: "Letter campaigns",
    icon: iconCampaignsLetter,
  },
  {
    title: "Email campaigns",
    icon: iconCampaignsEmail,
  },
  {
    title: "Postcard campaigns",
    icon: iconCampaignsPostcard,
  },
];

export const Promotions = () => {
  const refSchedulingImg = useRef(null);
  const refDayImg = useRef(null);
  const refCalendarImg = useRef(null);
  const refWorkflowImg = useRef(null);
  const refConfirmationsImg = useRef(null);
  const refRingImg = useRef(null);
  const refClockImg = useRef(null);
  const refCustomerImg = useRef(null);
  const refTabsImg = useRef(null);
  const refMarkCampImg1 = useRef(null);
  const refMarkCampImg2 = useRef(null);
  const refMarkCampImg3 = useRef(null);
  const refAdminImg = useRef(null);
  const refServiceImg = useRef(null);
  const refPartners1 = useRef(null);
  const refPartners2 = useRef(null);
  const refPartners3 = useRef(null);
  const refReview1 = useRef(null);
  const refReview2 = useRef(null);
  const refGoogleImg = useRef(null);
  const refMaImg = useRef(null);
  const refReceivedImg = useRef(null);
  const refReviewsData = useRef(null);
  const refRateReview = useRef(null);
  const refReviewSources = useRef(null);
  const refReviewRating = useRef(null);
  const refCarParam = useRef(null);

  const refs = [
    refSchedulingImg,
    refDayImg,
    refCalendarImg,
    refWorkflowImg,
    refConfirmationsImg,
    refRingImg,
    refClockImg,
    refCustomerImg,
    refTabsImg,
    refMarkCampImg1,
    refMarkCampImg2,
    refMarkCampImg3,
    refAdminImg,
    refServiceImg,
    refPartners1,
    refPartners2,
    refPartners3,
    refReview1,
    refReview2,
    refGoogleImg,
    refMaImg,
    refReceivedImg,
    refReviewsData,
    refRateReview,
    refReviewSources,
    refReviewRating,
    refCarParam,
  ];

  useEffect(() => animateOnScroll(refs.map((ref) => ref.current)), []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.promotions}>
        <Promo
          sectionTitle="01. Scheduling Services"
          promoTitle="Managing appointments has never been easier"
          description="Remove the hassle of receiving appointment requests by email or phone calls. Our scheduling software integrates directly into your shop management system and creates an amazing customer experience while saving your shop time."
          className={styles.greenTheme}
        >
          <div className={styles.poligonManagingAppointmets}></div>
          <div className={styles.cardsWrapper}>
            <div className={styles.card}>
              <CardTitle
                title="Online scheduling"
                description="Our automotive appointment software allows you to easily manage customer appointments and gives potential customers the ability to schedule appointments online."
                link={PATHS.ONLINE_SCHEDULING}
              />
              <div className={styles.schedulingImg} ref={refSchedulingImg}>
                <div className={styles.dayImg} ref={refDayImg}></div>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.multipleCalendars}>
                <CardTitle
                  title="Multiple views"
                  description="Manage all your appointments through an easy-to-use calendar and separate appointments by drop-off and waiting appointments."
                  link={PATHS.ONLINE_SCHEDULING}
                />
              </div>
              <div
                className={styles.multipleCalendarsImg}
                ref={refCalendarImg}
              ></div>
            </div>
            <div className={styles.card}>
              <CardTitle
                title="Workflow scheduling"
                description="Manage your workflow through our appointment scheduler and give customers timely notifications, like when a service is completed."
                link={PATHS.ONLINE_SCHEDULING}
              />
              <div className={styles.workflowImg} ref={refWorkflowImg}>
                <div className={styles.ringImg} ref={refRingImg}></div>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.halfCard}>
                <CardTitle
                  title="Confirmations"
                  description="Our appointment scheduler will confirm and remind your customers of their appointment."
                  className={styles.halfCardTitle}
                  link={PATHS.ONLINE_SCHEDULING}
                />
                <div
                  className={styles.confirmationsImg}
                  ref={refConfirmationsImg}
                >
                  <div className={styles.clockImg} ref={refClockImg}></div>
                </div>
              </div>
              <div className={styles.halfCard}>
                <CardTitle
                  title="Tracking"
                  description="Easily see whether customers are new or returning."
                  className={styles.halfCardTitle}
                  link={PATHS.ONLINE_SCHEDULING}
                />
                <div className={styles.newCustomerImg} ref={refCustomerImg}>
                  <div className={styles.repeatCustomerImg}></div>
                </div>
              </div>
            </div>
          </div>
        </Promo>
        <Promo
          sectionTitle="02. Essential Marketing Tools"
          promoTitle="Essential marketing tools to help your business grow"
          description="Steer allows you to connect with your customers how you, and they, prefer — whether that's via text, email or both. Our marketing tools help you build a relationship with your customers and keeps them returning for necessary services and repairs."
          className={styles.redTheme}
        >
          <div className={styles.poligonEssentialMarketing}></div>
          <div className={styles.cardsWrapper}>
            <div className={`${styles.card} ${styles.marketingCampaigns}`}>
              <CardTitle
                title="Marketing campaigns"
                description="Steer provides a mixed marketing approach that allows you to attract and build relationships with customers through multiple marketing channels."
                link={PATHS.EMAIL_MARKETING}
              />
              <div className={styles.tabs} ref={refTabsImg}>
                <div
                  className={styles.marketingCampaigns1}
                  ref={refMarkCampImg1}
                ></div>
                <div
                  className={styles.marketingCampaigns2}
                  ref={refMarkCampImg2}
                ></div>
                <div
                  className={styles.marketingCampaigns3}
                  ref={refMarkCampImg3}
                ></div>
              </div>
            </div>
            <div className={`${styles.card} ${styles.cardCampaigns}`}>
              {CAMPAIGNS.map((campaign, i) => (
                <div className={styles.campaigns} key={i}>
                  <div className={styles.campaignWrap}>
                    <img src={campaign.icon}></img>
                    <span>{campaign.title}</span>
                  </div>
                </div>
              ))}
            </div>
            <div className={`${styles.card} ${styles.marketingTools}`}>
              <div className={styles.adminImg} ref={refAdminImg}></div>
            </div>
            <div className={`${styles.card} ${styles.reminders}`}>
              <div className={styles.serviceReminders}>
                <CardTitle
                  title="Service reminders & alerts"
                  description="Provide your customers with friendly reminders when they are past due for a routine visit and/or have a pending declined services."
                  link={PATHS.SERVICE_REMINDERS}
                />
                <div className={styles.customerImg}>
                  <div className={styles.recommendedServiceImg}></div>
                  <div className={styles.serviceImg} ref={refServiceImg}></div>
                </div>
              </div>
            </div>
            <div className={styles.card}>
              <CardTitle
                title="Integration partners"
                description="Steer has partnered and integrated with 45+ shop management systems that service the auto repair industry. We currently cover more than 95% of the shop management systems market."
                link={PATHS.INTEGRATIONS}
              />
              <div className={styles.partners}>
                <div className={styles.partners1} ref={refPartners1}></div>
                <div className={styles.partners2} ref={refPartners2}></div>
                <div className={styles.partners3} ref={refPartners3}></div>
              </div>
            </div>
          </div>
        </Promo>
        <Promo
          sectionTitle="03. Reputation Management"
          promoTitle="Manage online reviews"
          description="Reputation management allows you to request new reviews and get notifications when a new review is added."
          className={styles.violetTheme}
        >
          <div className={styles.poligonManageOnlineReviews}></div>
          <div className={styles.cardsWrapper}>
            <div className={styles.card}>
              <CardTitle
                title="Multiple review sources in one platform"
                description="See your review scores from over 30 review sources including: Facebook, Google, Yelp, and Mechanic Advisor."
                link={PATHS.REPUTATION_MANAGEMENT}
              />
              <div className={styles.reviewSourcesImg} ref={refReviewSources}>
                <div
                  className={styles.reviewRatingImg}
                  ref={refReviewRating}
                ></div>
              </div>
            </div>
            <div className={`${styles.card} ${styles.remarks}`}>
              <div className={styles.commonRemarks}>
                <CardTitle
                  title="Common remarks from customers"
                  description="See what your customers are saying about your shop and identify how often employees are mentioned."
                  link={PATHS.REPUTATION_MANAGEMENT}
                />
                <div className={styles.review1} ref={refReview1}>
                  <div className={styles.googleImg} ref={refGoogleImg}></div>
                  <div className={styles.maImg} ref={refMaImg}></div>
                </div>
                <div className={styles.review2} ref={refReview2}></div>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.boostReviews}>
                <CardTitle
                  title="Monitor your shops reviews & take action"
                  description="Increase your shops rating across major review platforms. Our average shop sees 40+ new reviews each month."
                  link={PATHS.REPUTATION_MANAGEMENT}
                />
                <div className={styles.imgWrapper}>
                  <div
                    className={styles.reviewsReceivedImg}
                    ref={refReceivedImg}
                  >
                    <div
                      className={styles.reviewsDataImg}
                      ref={refReviewsData}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles.card} ${styles.rateReviewWrapper}`}>
              <div className={styles.rateReviewImg} ref={refRateReview}></div>
            </div>
          </div>
        </Promo>
        <Promo
          sectionTitle="04. Fleet Diagnostics"
          promoTitle="Remote diagnostic services for fleets"
          description="Monitor your fleet vehicles remotely, detect potential issues and receive alerts for necessary repairs and services. Eliminate preventable vehicle failure and repairs within your fleet."
          className={styles.orangeTheme}
          promoTitleCsl={styles.lastPromoTitle}
        >
          <div className={styles.poligonRemoteDiagnostic}></div>
          <Video />
          <div className={styles.paramWrapper} ref={refCarParam}>
            <div className={styles.carParam}></div>
            <div className={styles.carParam}></div>
            <div className={styles.shadows}>
              <div className={styles.shadowBox}></div>
              <div className={styles.shadowBox}></div>
            </div>
          </div>
        </Promo>
      </div>
    </div>
  );
};

type Title = {
  title: string;
  description: string;
  link: string;
  className?: object;
};

const CardTitle: React.FC<Title> = ({
  title,
  description,
  link = "",
  className,
}) => {
  const refTitle = useRef(null);
  const refDescription = useRef(null);
  const refButton = useRef(null);
  const refs = [refTitle, refDescription, refButton];

  useEffect(() => animateOnScroll(refs.map((ref) => ref.current)), []);

  return (
    <div className={styles.cardTitle}>
      <div className={`${styles.title} ${className}`} ref={refTitle}>
        {title}
      </div>
      <div className={styles.description} ref={refDescription}>
        {description}
      </div>
      <Link to={link} className={styles.link} ref={refButton}>
        <span>Learn More</span>
        <img className={styles.linkIcon} src={icon}></img>
        <img className={styles.linkIconDark} src={iconDark}></img>
      </Link>
    </div>
  );
};
