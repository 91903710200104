import React, { useRef, useEffect, useState } from "react";
import * as styles from "./PartnerHero.module.scss";
import { animateOnScroll } from "../../../services/animationService";
import steerLogo from "../../../assets/icons/i-steer-logo-partner.svg";
import { HSplit } from "../Divider";
import {
  ANALYTICS_EVENTS,
  addAnalyticsEvent,
} from "../../../services/analyticsService";
import SubscribePopup from "../../SubscribePopup";
import icon from "../../../assets/icons/i-arrow-up-active.svg";
import iconDark from "../../../assets/icons/i-arrow-up-dark-blue.svg";

type PartnerHeroProps = {
  hasSectionTitle?: boolean;
  textTitle: string;
  description: string;
  className?: any;
  partnerLogo?: string;
  cnLogoPartner?: string;
  children?: any;
  linkTitle: string;
  demoLinkTitle: string;
};

const PartnerHero: React.FC<PartnerHeroProps> = ({
  hasSectionTitle,
  textTitle,
  description,
  className,
  partnerLogo,
  cnLogoPartner = "",
  children,
  linkTitle,
  demoLinkTitle,
}) => {
  const refSectionName = useRef(null);
  const refTextTitle = useRef(null);
  const refDescription = useRef(null);
  const refSection = useRef(null);
  const refImgWrapper = useRef(null);
  const refLink = useRef(null);
  const refSecondLink = useRef(null);
  const [open, setOpen] = useState(false);

  const refs = [
    refTextTitle,
    refDescription,
    refSection,
    refImgWrapper,
    refLink,
    refSecondLink,
  ];

  useEffect(() => {
    if (hasSectionTitle) {
      refs.push(refSectionName);
    }
    animateOnScroll(refs.map((ref) => ref.current));
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.section} ref={refSection}>
          <div className={styles.sectionText}>
            <div className={`${styles.textWraper} ${className}`}>
              {hasSectionTitle && (
                <div className={styles.sectionName} ref={refSectionName}>
                  Shop Management System
                </div>
              )}
              <h1 className={styles.textTitle} ref={refTextTitle}>
                {textTitle}
              </h1>
              <p className={styles.description} ref={refDescription}>
                {description}
              </p>
              <div className={styles.linkWrapper} ref={refLink}>
                <div className={styles.linkTitle}>{linkTitle}</div>
                {children}
              </div>
              <div className={styles.linkWrapper} ref={refSecondLink}>
                <div className={styles.linkTitle}>{demoLinkTitle}</div>
                <div
                  onClick={() => {
                    addAnalyticsEvent(ANALYTICS_EVENTS.getEarlyAccessClick);
                    setOpen(true);
                  }}
                  className={styles.link}
                >
                  <span> Book a Steer Demo</span>
                  <img className={styles.linkIcon} src={icon}></img>
                  <img className={styles.linkIconDark} src={iconDark}></img>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.sectionLogos} ref={refImgWrapper}>
            <div className={styles.logosWrapper}>
              <img src={steerLogo} alt="logo" className={styles.logoSteer} />
              <HSplit className={styles.divider} />
              <img src={partnerLogo} alt="logo" className={cnLogoPartner} />
            </div>
          </div>
        </div>
      </div>
      {open && <SubscribePopup close={handleClose} />}
    </>
  );
};

export default PartnerHero;
