import React from "react";
import PartnerHero from "../Shared/PartnerHero";
import * as styles from "./VastSQL.module.scss";
import { imgVastSQL } from "../../assets/images/OurPartners";
import Card from "../Shared/Card/Card";
import {
  iconInfo,
  iconCloudSync,
  iconCalendar,
} from "../../assets/icons/SinglePartner";
import icon from "../../assets/icons/i-arrow-up-active.svg";
import iconDark from "../../assets/icons/i-arrow-up-dark-blue.svg";
import { useHabspotLinkLoad } from "../../hooks";

const FEATURES = [
  {
    title: "Real-Time Data Sync",
    description:
      "View accurate customer details, service information, and appointment calendars with real-time data sync.",
    icon: iconCloudSync,
  },
  {
    title: "Two-Way Calendar Integration",
    description:
      "Access your shop schedule and appointment information with 100% accuracy on both platforms.",
    icon: iconCalendar,
  },
  {
    title: "Deferred Services Information",
    description:
      "Keep track of customer service recommendations and utilize Steer’s communication tools to keep customers returning.",
    icon: iconInfo,
  },
];

const VastSQL = ({}) => {
  const isHabspotLoaded = useHabspotLinkLoad(
    "4ac5997b-323a-4592-9d54-bd4c278a0f76"
  );

  return (
    <>
      <PartnerHero
        textTitle="VastSQL"
        description="VastSQL is a preferred Shop Management System that integrates with Steer CRM to keep your auto repair shop running smoothly and your customers returning."
        partnerLogo={imgVastSQL}
        className={styles.textTitle}
        cnLogoPartner={styles.logo}
        linkTitle="Interested in VastSQL software?"
        demoLinkTitle="Already a VastSQL customer?"
        hasSectionTitle
      >
        {!isHabspotLoaded && (
          <span
            className="hs-cta-wrapper"
            id="hs-cta-wrapper-4ac5997b-323a-4592-9d54-bd4c278a0f76"
          >
            <span
              className="hs-cta-node hs-cta-4ac5997b-323a-4592-9d54-bd4c278a0f76"
              id="hs-cta-4ac5997b-323a-4592-9d54-bd4c278a0f76"
            >
              <div id="hs-cta-ie-element"></div>
              <a
                href="https://cta-redirect.hubspot.com/cta/redirect/6191634/4ac5997b-323a-4592-9d54-bd4c278a0f76"
                target="_blank"
                rel="noopener"
                className={styles.link}
              >
                <span>Visit Website</span>
                <img className={styles.linkIcon} src={icon}></img>
                <img className={styles.linkIconDark} src={iconDark}></img>
              </a>
            </span>
          </span>
        )}
        {isHabspotLoaded && (
          <a className={styles.link}>
            <span>Visit Website</span>
            <img className={styles.linkIcon} src={icon}></img>
            <img className={styles.linkIconDark} src={iconDark}></img>
          </a>
        )}
      </PartnerHero>
      <div className={styles.wrapper}>
        <div className={styles.innerWrapper}>
          <div className={styles.title}>VastSQL Features</div>
          <div className={styles.features}>
            {FEATURES.map(({ title, description, icon }, i) => (
              <Card
                title={title}
                description={description}
                icon={icon}
                key={i}
                variant="Partner"
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default VastSQL;
