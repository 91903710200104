import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import TekmetricPage from "../components/Tekmetric";

const Tekmetric: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return <TekmetricPage />;
};

export default Tekmetric;
