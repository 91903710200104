// extracted by mini-css-extract-plugin
export var heroImg = "DirectMail-module--heroImg--2iHoL";
export var standOut = "DirectMail-module--standOut--28Ojj";
export var heroPostcadBottom = "DirectMail-module--heroPostcadBottom--JzUF_";
export var heroPostcadUpper = "DirectMail-module--heroPostcadUpper--2iRmG";
export var heroMobile = "DirectMail-module--heroMobile--3XUhD";
export var standOutWrappe = "DirectMail-module--standOutWrappe--3-Xb3";
export var fadeIn = "DirectMail-module--fade-in--ECJgg";
export var fadeOut = "DirectMail-module--fade-out--3pX0T";
export var spin = "DirectMail-module--spin--3q3hE";
export var slideInFromRight = "DirectMail-module--slide-in-from-right--3Rvh8";
export var slideInFromLeft = "DirectMail-module--slide-in-from-left--5bJqU";
export var slideInFromTop = "DirectMail-module--slide-in-from-top--OcKEl";
export var slideOutFromBottom = "DirectMail-module--slide-out-from-bottom--6bZHJ";
export var rotateToUp = "DirectMail-module--rotate-to-up--1vaLC";
export var rotateToDown = "DirectMail-module--rotate-to-down--2RTAM";
export var pulseWhite = "DirectMail-module--pulse-white--2Qvoj";
export var scaleToHoleScreen = "DirectMail-module--scale-to-hole-screen--1kNao";
export var toTop = "DirectMail-module--to-top--NH_Pz";
export var grow = "DirectMail-module--grow--1YA2X";
export var Header = "DirectMail-module--Header--Ga0U9";
export var HeaderMiddle = "DirectMail-module--HeaderMiddle--1BB9b";
export var HeaderTablet = "DirectMail-module--HeaderTablet--1wJUo";
export var HeaderMobile = "DirectMail-module--HeaderMobile--3SPdD";
export var HeaderFixed = "DirectMail-module--HeaderFixed--2eFyC";
export var Hero = "DirectMail-module--Hero--1luWj";
export var HeroBody = "DirectMail-module--HeroBody--3FZak";
export var HeroForm = "DirectMail-module--HeroForm--3sVaG";
export var HeroPolygonUp = "DirectMail-module--HeroPolygonUp--2wEDX";
export var HeroPolygonDown = "DirectMail-module--HeroPolygonDown--1cR1P";
export var HeroImg = "DirectMail-module--HeroImg--1SBhE";
export var HeroAudiImg = "DirectMail-module--HeroAudiImg--116Ub";
export var HeroFordImg = "DirectMail-module--HeroFordImg--2SUio";
export var HeroGirlImg = "DirectMail-module--HeroGirlImg--z3aZx";
export var HeroGirlTablet = "DirectMail-module--HeroGirlTablet--1AomB";
export var HeroDeclinedServicesImg = "DirectMail-module--HeroDeclinedServicesImg--3RxnO";
export var HeroTablet = "DirectMail-module--HeroTablet--2tKZF";
export var HeroMobile = "DirectMail-module--HeroMobile--PisIp";
export var HeroButton = "DirectMail-module--HeroButton--1qeYn";
export var ToolsNavigationTitle = "DirectMail-module--ToolsNavigationTitle--1K7TW";
export var ToolsNavigationCard1 = "DirectMail-module--ToolsNavigationCard1--34BdL";
export var ToolsNavigationCard2 = "DirectMail-module--ToolsNavigationCard2--2h65J";
export var ToolsNavigationCard3 = "DirectMail-module--ToolsNavigationCard3--3d02t";
export var ToolsNavigationCard4 = "DirectMail-module--ToolsNavigationCard4--1lBp3";
export var ToolsNavigationCard1Tablet = "DirectMail-module--ToolsNavigationCard1Tablet--1qx0S";
export var ToolsNavigationCard2Tablet = "DirectMail-module--ToolsNavigationCard2Tablet--2nIL7";
export var ToolsNavigationCard3Tablet = "DirectMail-module--ToolsNavigationCard3Tablet--1vNT4";
export var ToolsNavigationCard4Tablet = "DirectMail-module--ToolsNavigationCard4Tablet--3mQR3";
export var ToolsNavigationCardMobile = "DirectMail-module--ToolsNavigationCardMobile--2p97f";
export var TestimonialsOpacity = "DirectMail-module--TestimonialsOpacity--1TH8Q";
export var TestimonialsOpacityLate = "DirectMail-module--TestimonialsOpacityLate--1F5CH";
export var PromoEmerge = "DirectMail-module--PromoEmerge--1eeUi";
export var PromoTitle = "DirectMail-module--PromoTitle--2Nf-S";
export var PromoCardTitle = "DirectMail-module--PromoCardTitle--1_5lY";
export var PromoCardTitleButton = "DirectMail-module--PromoCardTitleButton--3M0kH";
export var PromoCardTitleArrow = "DirectMail-module--PromoCardTitleArrow--bZ8Od";
export var PromoScheduler = "DirectMail-module--PromoScheduler--3vAOx";
export var PromoSchedulerMob = "DirectMail-module--PromoSchedulerMob--3J0PS";
export var PromoRing = "DirectMail-module--PromoRing--2xXum";
export var PromoRingMob = "DirectMail-module--PromoRingMob--FBHbB";
export var PromoClock = "DirectMail-module--PromoClock--2kuof";
export var PromoClockMiddle = "DirectMail-module--PromoClockMiddle--13Hj2";
export var MarketingCampaigns1 = "DirectMail-module--MarketingCampaigns1--3okCY";
export var MarketingCampaigns1Mob = "DirectMail-module--MarketingCampaigns1Mob--2V4kt";
export var MarketingCampaigns1MobSM = "DirectMail-module--MarketingCampaigns1MobSM--2Srj0";
export var ServiceImg = "DirectMail-module--ServiceImg--1YH09";
export var ServiceImgMob = "DirectMail-module--ServiceImgMob--3efu5";
export var Partners1 = "DirectMail-module--Partners1--LeYL9";
export var Partners900 = "DirectMail-module--Partners-900--2Tw9Y";
export var Partners2 = "DirectMail-module--Partners2--E7FSS";
export var Partners2900 = "DirectMail-module--Partners2-900--6xDMA";
export var Partners3 = "DirectMail-module--Partners3--1Oi4u";
export var Partners3900 = "DirectMail-module--Partners3-900--219ON";
export var Partners1Mobile = "DirectMail-module--Partners1Mobile--1DESS";
export var Partners2Mobile = "DirectMail-module--Partners2Mobile--zvEU1";
export var Partners3Mobile = "DirectMail-module--Partners3Mobile--1xMSd";
export var Partners1Tablet = "DirectMail-module--Partners1Tablet--z6-PE";
export var Partners2Tablet = "DirectMail-module--Partners2Tablet--3ab0O";
export var Partners3Tablet = "DirectMail-module--Partners3Tablet--3is6n";
export var Review1 = "DirectMail-module--Review1--3UWXq";
export var GoogleImg = "DirectMail-module--GoogleImg--67z5g";
export var MaImg = "DirectMail-module--MaImg--1AP4Y";
export var ReceivedImg = "DirectMail-module--ReceivedImg--t-IAA";
export var ReceivedImgMob = "DirectMail-module--ReceivedImgMob--1BRZw";
export var ReviewsData = "DirectMail-module--ReviewsData--3ZkMA";
export var ReviewSources = "DirectMail-module--ReviewSources--24yBE";
export var ReviewSourcesMobile = "DirectMail-module--ReviewSourcesMobile--bSzv0";
export var CarParamString1 = "DirectMail-module--CarParamString1--RBlzd";
export var CarParamString2 = "DirectMail-module--CarParamString2--2fNrf";
export var ImgHelloPage = "DirectMail-module--ImgHelloPage--3ho5u";
export var ImgHelloPageXXL = "DirectMail-module--ImgHelloPageXXL--1bmIY";
export var ImgHelloPageXL = "DirectMail-module--ImgHelloPageXL--EOmZh";
export var ImgHelloPageL = "DirectMail-module--ImgHelloPageL--3unLT";
export var ImgHelloPageT = "DirectMail-module--ImgHelloPageT--183XZ";
export var ImgBubbleXXL = "DirectMail-module--ImgBubbleXXL--3MnzP";
export var ImgBubbleXL = "DirectMail-module--ImgBubbleXL--2B1gk";
export var ImgBubbleL = "DirectMail-module--ImgBubbleL--1nudo";
export var ImgGirl = "DirectMail-module--ImgGirl--1eMVG";
export var ImgGirlXXL = "DirectMail-module--ImgGirlXXL--3ezRr";
export var ImgGirlXL = "DirectMail-module--ImgGirlXL--1iWO4";
export var SupportRequestContacts = "DirectMail-module--SupportRequestContacts--2I_jr";
export var PartnersUpperLine1 = "DirectMail-module--PartnersUpperLine1--19bGT";
export var PartnersUpperLine2 = "DirectMail-module--PartnersUpperLine2--1syi_";
export var PartnersBottomLine1 = "DirectMail-module--PartnersBottomLine1--2sHqt";
export var PartnersBottomLine2 = "DirectMail-module--PartnersBottomLine2--3ADV9";
export var servicesListImg = "DirectMail-module--servicesListImg--2-91N";
export var servicesListImgLarge = "DirectMail-module--servicesListImgLarge--3YDd-";
export var servicesListImgTablet = "DirectMail-module--servicesListImgTablet--3nebQ";
export var laptopImg = "DirectMail-module--laptopImg--2YFx4";
export var laptopImgXL = "DirectMail-module--laptopImgXL--2w3M-";
export var laptopImgLarge = "DirectMail-module--laptopImgLarge--35Pak";
export var laptopImgTablet = "DirectMail-module--laptopImgTablet--3aKUk";
export var laptopImgMob = "DirectMail-module--laptopImgMob--1fMR1";
export var laptopInfoLarge = "DirectMail-module--laptopInfoLarge--3qoPI";
export var heroPersonUpper = "DirectMail-module--heroPersonUpper--1K8Y_";
export var heroPersonBottom = "DirectMail-module--heroPersonBottom--Mf5_i";
export var heroPersonUpperTablet = "DirectMail-module--heroPersonUpperTablet--39nQ5";
export var heroPersonBottomTablet = "DirectMail-module--heroPersonBottomTablet--1NtNm";
export var welcomeMessageTablet = "DirectMail-module--welcomeMessageTablet--3Qu7z";
export var welcomeMessageMobile = "DirectMail-module--welcomeMessageMobile--b-j1w";
export var growXXL = "DirectMail-module--growXXL--1skNS";
export var growL = "DirectMail-module--growL--3rNOJ";
export var tableToTop = "DirectMail-module--tableToTop--23Ro2";
export var growImgOneTablet = "DirectMail-module--growImgOneTablet--Pv9-M";
export var fadeAndScale = "DirectMail-module--fadeAndScale--QT6pv";
export var fadeAndScaleTab = "DirectMail-module--fadeAndScaleTab--1LbUh";
export var ReviewRatingTablet = "DirectMail-module--ReviewRatingTablet--A40Ms";
export var growTabletMessageIcon = "DirectMail-module--growTabletMessageIcon--2AP7w";
export var growTabletRingIcon = "DirectMail-module--growTabletRingIcon--39Y4y";
export var reserveGoogleHero = "DirectMail-module--reserveGoogleHero--2jr4K";
export var reserveGoogleHeroTablet = "DirectMail-module--reserveGoogleHeroTablet--2gfVZ";
export var scaleToHoleScreenPopup = "DirectMail-module--scale-to-hole-screen-popup--3RaCr";
export var CareersImagesString = "DirectMail-module--CareersImagesString--2539S";