import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import EnterpriseSolutionsPage from "../components/EnterpriseSolutions";

const EnterpriseSolutions: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return <EnterpriseSolutionsPage />;
};

export default EnterpriseSolutions;
