// extracted by mini-css-extract-plugin
export var wrapper = "MiddleSection-module--wrapper--17oxu";
export var textMessagingUpper = "MiddleSection-module--textMessagingUpper--3PXOr";
export var textMessagingBottom = "MiddleSection-module--textMessagingBottom--UIdHz";
export var serviceRemindersUpper = "MiddleSection-module--serviceRemindersUpper--1ZUlz";
export var serviceRemindersBottom = "MiddleSection-module--serviceRemindersBottom--35yJJ";
export var section = "MiddleSection-module--section--3Vgdr";
export var reversed = "MiddleSection-module--reversed--2Dl3M";
export var sectionText = "MiddleSection-module--sectionText--2_D8D";
export var textWraper = "MiddleSection-module--textWraper--2yLeO";
export var textTitle = "MiddleSection-module--textTitle--_gr1t";
export var description = "MiddleSection-module--description--27WQR";
export var noDescription = "MiddleSection-module--noDescription--2_ash";
export var serviceReminders = "MiddleSection-module--serviceReminders--2oqZy";
export var toolWrapper = "MiddleSection-module--toolWrapper--WQUqa";
export var toolIcon = "MiddleSection-module--toolIcon--18M0z";
export var toolTitle = "MiddleSection-module--toolTitle--3PhWz";
export var serviceRemindersToolTitle = "MiddleSection-module--serviceRemindersToolTitle--cyZgK";
export var toolDescription = "MiddleSection-module--toolDescription--DXWt6";
export var imagesWrapper = "MiddleSection-module--imagesWrapper--1x5Jv";
export var serviceRemindersImgsUpper = "MiddleSection-module--serviceRemindersImgsUpper--3Ufpq";
export var serviceRemindersImgsBottom = "MiddleSection-module--serviceRemindersImgsBottom--3igkL";
export var textMessagingBottomImg = "MiddleSection-module--textMessagingBottomImg--2Ky0a";
export var standOutWrapper = "MiddleSection-module--standOutWrapper--1zvWC";