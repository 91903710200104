import React from "react";
import * as styles from "./FeaturesSlider.module.scss";
import cn from "classnames";
import { Carousel } from "./Carousel";

type FeaturesSliderProps = {
  excludedSlideIdx?: string;
  title?: string;
  variant?: string;
};

export const FeaturesSlider: React.FC<FeaturesSliderProps> = ({
  excludedSlideIdx,
  title = "Other features to help grow your auto repair shop business",
  variant = "",
}) => {
  return (
    <div className={cn(styles.wrapper, styles[`variant${variant}`])}>
      <div className={cn(styles.title, styles[`variant${variant}`])}>
        {title}
      </div>
      <Carousel excludedSlideIdx={excludedSlideIdx} variant={variant} />
    </div>
  );
};
