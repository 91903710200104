import React from "react";
import * as styles from "./Solution.module.scss";

type SolutionProps = {
  title: string;
  description: string;
  className?: any;
  children?: any;
};

const Solution: React.FC<SolutionProps> = ({
  title,
  description,
  className,
  children,
}) => {
  return (
    <div className={styles.solution}>
      <div className={`${styles.solutionHeader}  ${styles[`${className}`]}`}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
      </div>
      {children}
    </div>
  );
};

export default Solution;
