import React from "react";
import * as styles from "./Timeline.module.scss";
import Card from "../../Shared/Card/Card";
import { VSplit } from "../../Shared/Divider";
import icon1 from "../../../assets/icons/i-about-us-timeline1.svg";
import icon2 from "../../../assets/icons/i-about-us-timeline2.svg";
import icon3 from "../../../assets/icons/i-about-us-timeline3.svg";
import icon4 from "../../../assets/icons/i-about-us-timeline4.svg";

const Timeline = ({}) => {
  return (
    <div className={styles.timelineWrapper}>
      <div className={styles.redDot}>
        <div className={styles.cardWrapper}>
          <Card
            title="June of 2006"
            description="Parker Swift and Bob Gendron founded Mechanic Advisor with the mission to improve the auto repair experience by connecting motorists to repair shops based on ratings, reviews, and services."
            variant="Timeline"
            icon={icon1}
          />
        </div>
        <VSplit className={styles.divider} />
      </div>
      <div className={styles.redDot}>
        <div className={styles.cardWrapper}>
          <Card
            title="October of 2015"
            description="The idea of building software for repair shops to communicate and build loyalty and trust with their customers was born."
            variant="Timeline"
            icon={icon2}
          />
        </div>
        <VSplit className={styles.divider} />
      </div>
      <div className={styles.redDot}>
        <div className={styles.cardWrapper}>
          <Card
            title="June of 2018"
            description="Mechanic Advisor developed a full-service marketing platform with the ability to email, text, and send postcards to customers, and began the transition to a SaaS company."
            variant="Timeline"
            icon={icon3}
          />
        </div>
        <VSplit className={styles.divider} />
      </div>
      <div className={styles.redDot}>
        <div className={styles.cardWrapper}>
          <Card
            title="June of 2022"
            description="Steer by Mechanic Advisor launched. Steer is an automated retention marketing and communications platform for auto repair shops."
            variant="Timeline"
            icon={icon4}
          />
        </div>
      </div>
    </div>
  );
};

export default Timeline;
