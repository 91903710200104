import React from "react";
import PartnerHero from "../Shared/PartnerHero";
import * as styles from "./AdvanceProfessional.module.scss";
import { imgAdvanceProfessional } from "../../assets/images/OurPartners";
import {
  imgCTI_WTI,
  imgCarquest,
  imgMotoLogic,
  imgMotoVisuals,
  imgWorldpac,
} from "../../assets/images/AdvanceProfessional";
import icon from "../../assets/icons/i-arrow-up-active.svg";
import iconDark from "../../assets/icons/i-arrow-up-dark-blue.svg";
import { useHabspotLinkLoad } from "../../hooks";

const SOLUTIONS = [
  {
    name: "Carquest",
    description:
      "Carquest offers a full line of OE-quality parts with industry-leading coverage, allowing your business to service more vehicles on the road today with fewer comebacks. They are the private-label brand of Advance Professional and Carquest.",
    logo: imgCarquest,
    className: "carquest",
    link: "https://www.carquest.com",
  },
  {
    name: "CTI+WTI",
    description:
      "Carquest Technical Institute + Worldpac Training Institute provides industry-leading technician and business management training. Help your team build skills and earn certifications to service more vehicles on the road today.",
    logo: imgCTI_WTI,
    className: "CTIandWTI",
    link: "https://ctionline.com",
  },
  {
    name: "MotoLogic",
    description:
      "MotoLogic provides leading OEM repair and diagnostic information so you can be prepared to service more vehicles on the road today. Access 100% unedited OEM information without any edits or exclusions on an easy-to-master platform.",
    logo: imgMotoLogic,
    className: "motoLogic",
    link: "https://www.motologic.com",
  },
  {
    name: "MotoVisuals",
    description:
      "MotoVisuals allows shops to communicate more effectively with customers using digital service and repair animations. Explain complex services and repairs to customers in-person, via text, and email.",
    logo: imgMotoVisuals,
    className: "motoVisuals",
    link: "https://motovisuals.com/#!/bhome",
  },
  {
    name: "Worldpac",
    description:
      "Worldpac provides premium-quality OE and aftermarket parts with leading import and domestic coverage. Their offering includes over 120,000 products for 40+ import and domestic vehicle lines.",
    logo: imgWorldpac,
    className: "worldpac",
    link: "https://www.worldpac.com",
  },
];

const AdvanceProfessional = ({}) => {
  const isHabspotLoaded = useHabspotLinkLoad(
    "e0a80eb0-c275-4cf0-b037-7f232b607fc1"
  );

  return (
    <>
      <PartnerHero
        textTitle="Advance Professional"
        description="Advance Professional, an Advance Auto Parts brand, provides premium OE-quality parts and business solutions that can help keep your business running smoothly. Steer has partnered with Advance Professional to connect its service with industry-leading customer relationship tools."
        partnerLogo={imgAdvanceProfessional}
        className={styles.textTitle}
        cnLogoPartner={styles.advanceProfessional}
        linkTitle="Learn more about Advance Professional."
        demoLinkTitle="Already an Advance Professional customer?"
      >
        {!isHabspotLoaded && (
          <span
            className="hs-cta-wrapper"
            id="hs-cta-wrapper-e0a80eb0-c275-4cf0-b037-7f232b607fc1"
          >
            <span
              className="hs-cta-node hs-cta-e0a80eb0-c275-4cf0-b037-7f232b607fc1"
              id="hs-cta-e0a80eb0-c275-4cf0-b037-7f232b607fc1"
            >
              <div id="hs-cta-ie-element"></div>
              <a
                href="https://cta-redirect.hubspot.com/cta/redirect/6191634/e0a80eb0-c275-4cf0-b037-7f232b607fc1"
                target="_blank"
                rel="noopener"
                className={styles.link}
              >
                <span>Visit Website</span>
                <img className={styles.linkIcon} src={icon}></img>
                <img className={styles.linkIconDark} src={iconDark}></img>
              </a>
            </span>
          </span>
        )}
        {isHabspotLoaded && (
          <a className={styles.link}>
            <span>Visit Website</span>
            <img className={styles.linkIcon} src={icon}></img>
            <img className={styles.linkIconDark} src={iconDark}></img>
          </a>
        )}
      </PartnerHero>
      <div className={styles.wrapper}>
        <div className={styles.innerWrapper}>
          <div className={styles.title}>Business solutions</div>
          <div className={styles.solutions}>
            {SOLUTIONS.map(
              ({ name, description, className, logo, link }, i) => (
                <Solution
                  name={name}
                  description={description}
                  className={className}
                  logo={logo}
                  key={i}
                  variant={i % 2 ? "Reverse" : ""}
                  link={link}
                />
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdvanceProfessional;

type SolutionProps = {
  name: string;
  description: string;
  logo: string;
  className?: string;
  variant?: string;
  link?: string;
};

const Solution: React.FC<SolutionProps> = ({
  className,
  logo,
  name,
  description,
  variant,
  link = "",
}) => {
  return (
    <div className={`${styles.solution} ${styles[`variant${variant}`]}`}>
      <div className={styles.infoContainer}>
        <div className={styles.name}>{name}</div>
        <div className={styles.description}>{description}</div>
        <a href={link} className={styles.link} target={"_blank"} rel="nofollow">
          <span>Visit Website</span>
          <img className={styles.linkIcon} src={icon}></img>
          <img className={styles.linkIconDark} src={iconDark}></img>
        </a>
      </div>
      <div className={styles.logoContainer}>
        <img
          className={`${styles.logo} ${styles[className]}`}
          src={logo}
          alt="logo"
        />
      </div>
    </div>
  );
};
