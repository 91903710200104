import React, { useEffect, useState } from "react";
import * as styles from "./GoTopButton.module.scss";

type props = {
  isFooterShown: boolean;
};

const GoTopButton: React.FC<props> = ({ isFooterShown }) => {
  const [isBtnShown, setIsBtnShown] = useState(false);
  const fixedButtonPositionY = 400;
  const [buttonPositionLeft, setButtonPositionLeft] = useState<
    number | undefined
  >();

  function topFunction() {
    document.body.scrollTo({ top: 0, behavior: "smooth" });
    document.documentElement.scrollTo({ top: 0, behavior: "smooth" });
  }

  const listenScrollEvent = () => {
    if (
      document.body.scrollTop > fixedButtonPositionY ||
      document.documentElement.scrollTop > fixedButtonPositionY
    ) {
      setIsBtnShown(true);
      const windowWidth = window.innerWidth;
      setButtonPositionLeft(windowWidth - 250);
    } else {
      setIsBtnShown(false);
    }
  };

  useEffect(() => {
    listenScrollEvent();
    window.addEventListener("scroll", listenScrollEvent);

    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, [isBtnShown]);

  return (
    <>
      <button
        onClick={(e) => topFunction()}
        className={styles.button}
        style={
          isBtnShown && !isFooterShown
            ? {
                opacity: 1,
                left: buttonPositionLeft,
              }
            : { opacity: 0, pointerEvents: "none" }
        }
      >
        <div className={styles.arrow}></div>
        <span>Back to Top</span>
      </button>
    </>
  );
};

export default GoTopButton;
