// extracted by mini-css-extract-plugin
export var wrapper = "FeaturesSlider-module--wrapper--2Vcm4";
export var variantGoogleReserve = "FeaturesSlider-module--variantGoogleReserve--3oaxv";
export var title = "FeaturesSlider-module--title--31Zsp";
export var slider = "FeaturesSlider-module--slider--1_ZNw";
export var slide = "FeaturesSlider-module--slide--2eb4i";
export var card = "FeaturesSlider-module--card--2Na4W";
export var description = "FeaturesSlider-module--description--UPmIX";
export var link = "FeaturesSlider-module--link--1ac7D";
export var linkIcon = "FeaturesSlider-module--linkIcon--2h9lM";
export var linkIconDark = "FeaturesSlider-module--linkIconDark--1RSXy";
export var img = "FeaturesSlider-module--img--2qiML";
export var emailMarketing = "FeaturesSlider-module--emailMarketing--3aRSJ";
export var serviceReminder = "FeaturesSlider-module--serviceReminder--1cUxZ";
export var textMessaging = "FeaturesSlider-module--textMessaging--29MmP";
export var postcardMailing = "FeaturesSlider-module--postcardMailing--1ZnKJ";
export var reputationManagement = "FeaturesSlider-module--reputationManagement--1i68U";
export var onlineScheduling = "FeaturesSlider-module--onlineScheduling--1HYMZ";
export var websiteChat = "FeaturesSlider-module--websiteChat--3yPqO";
export var telematics = "FeaturesSlider-module--telematics--qIb-9";
export var advancedReporting = "FeaturesSlider-module--advancedReporting--2UHBQ";