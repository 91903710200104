import React from "react";
import PartnerHero from "../Shared/PartnerHero";
import * as styles from "./Protractor.module.scss";
import { imgProtractor } from "../../assets/images/OurPartners";
import Card from "../Shared/Card/Card";
import {
  iconInfo,
  iconCloudSync,
  iconCalendar,
} from "../../assets/icons/SinglePartner";
import icon from "../../assets/icons/i-arrow-up-active.svg";
import iconDark from "../../assets/icons/i-arrow-up-dark-blue.svg";
import { useHabspotLinkLoad } from "../../hooks";

const FEATURES = [
  {
    title: "Real-time data sync",
    description:
      "View accurate customer details, service information, and appointment calendars with real-time data sync.",
    icon: iconCloudSync,
  },
  {
    title: "Two-way calendar integration",
    description:
      "Access your shop schedule and appointment information with 100% accuracy on both platforms.",
    icon: iconCalendar,
  },
  {
    title: "Deferred services information",
    description:
      "Keep track of customer service recommendations and utilize Steer’s communication tools to keep customers returning.",
    icon: iconInfo,
  },
];

const Protractor = ({}) => {
  const isHabspotLoaded = useHabspotLinkLoad(
    "2557fe4e-ab5f-4e52-be5a-2fe63a40e446"
  );

  return (
    <>
      <PartnerHero
        textTitle="Protractor"
        description="Protractor provides powerful Shop Management System tools all on an easy-to-use cloud platform. Steer CRM has partnered with Protractor to make customer communication and retention as easy as using your favorite Shop Management System tool."
        partnerLogo={imgProtractor}
        className={styles.textTitle}
        cnLogoPartner={styles.protractor}
        linkTitle="Interested in Protractor Shop Management System?"
        demoLinkTitle="Already a Protractor user?"
        hasSectionTitle
      >
        {!isHabspotLoaded && (
          <span
            className="hs-cta-wrapper"
            id="hs-cta-wrapper-2557fe4e-ab5f-4e52-be5a-2fe63a40e446"
          >
            <span
              className="hs-cta-node hs-cta-2557fe4e-ab5f-4e52-be5a-2fe63a40e446"
              id="hs-cta-2557fe4e-ab5f-4e52-be5a-2fe63a40e446"
            >
              <div id="hs-cta-ie-element"></div>
              <a
                href="https://cta-redirect.hubspot.com/cta/redirect/6191634/2557fe4e-ab5f-4e52-be5a-2fe63a40e446"
                target="_blank"
                rel="noopener"
                className={styles.link}
              >
                <span>Visit Website</span>
                <img className={styles.linkIcon} src={icon}></img>
                <img className={styles.linkIconDark} src={iconDark}></img>
              </a>
            </span>
          </span>
        )}
        {isHabspotLoaded && (
          <a className={styles.link}>
            <span>Visit Website</span>
            <img className={styles.linkIcon} src={icon}></img>
            <img className={styles.linkIconDark} src={iconDark}></img>
          </a>
        )}
      </PartnerHero>
      <div className={styles.wrapper}>
        <div className={styles.innerWrapper}>
          <div className={styles.title}>Protractor features</div>
          <div className={styles.features}>
            {FEATURES.map(({ title, description, icon }, i) => (
              <Card
                title={title}
                description={description}
                icon={icon}
                key={i}
                variant="Partner"
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Protractor;
