import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import PricingPlansPage from "../components/PricingPlans";

const PricingPlans: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return <PricingPlansPage />;
};

export default PricingPlans;
