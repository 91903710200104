import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useScreenSize } from "../../hooks";
import UpperSection from "../Shared/UpperSection";
import * as styles from "./CustomerTestimonials.module.scss";
import { Testimonial } from "./Testimonial";
import starsIcon from "../../assets/icons/i-stars-green.svg";
import smileIcon from "../../assets/icons/i-smile-green.svg";
import { VideoTestimonials } from "../Shared/VideoTestimonials";

const CustomerTestimonials = ({}) => {
  const { isMobile } = useScreenSize();

  return (
    <>
      <UpperSection
        sectionName="Customer Testimonials"
        textTitle="What our customers have to say"
        description="Auto repair shops are using Steer by Mechanic Advisor to grow their businesses and retain customers. Read how we’ve changed their businesses."
        clsLeft="customerTestimonialsDistance"
        clsRight="customerTestimonialsImgDistance"
        formClass="Hidden"
        className={styles.heroWrapper}
      >
        {isMobile ? (
          <StaticImage
            src="../../assets/images/CustomerTestimonials/hero-tablet-mobile.png"
            alt="testimonials"
            className={styles.heroMobile}
            loading="eager"
            quality={100}
            objectFit="contain"
          />
        ) : (
          <div className={styles.heroImages}>
            <StaticImage
              src="../../assets/images/CustomerTestimonials/hero-man-1.png"
              alt="customer"
              className={styles.heroPersonUpper}
              loading="eager"
              quality={100}
              style={{
                position: "absolute",
              }}
            />
            <StaticImage
              src="../../assets/images/CustomerTestimonials/hero-man-2.png"
              alt="customer"
              className={styles.heroPersonBottom}
              loading="eager"
              quality={100}
              style={{
                position: "absolute",
              }}
            />
            <StaticImage
              src="../../assets/images/CustomerTestimonials/review-1.png"
              alt="testimonial"
              className={styles.heroReviewUpper}
              loading="eager"
              quality={100}
              style={{
                position: "absolute",
              }}
            />
            <StaticImage
              src="../../assets/images/CustomerTestimonials/review-2.png"
              alt="testimonial"
              className={styles.heroReviewBottom}
              loading="eager"
              quality={100}
              style={{
                position: "absolute",
              }}
            />
            <div className={styles.hexagon}></div>
            <div className={styles.triangle}></div>
            <div className={styles.circle}></div>
          </div>
        )}
      </UpperSection>
      <VideoTestimonials />
      <div className={styles.wrapper}>
        <div className={styles.sectionWrapper}>
          <div className={styles.sectionTitle}>Customer quotes</div>{" "}
          <div className={styles.testimonials}>
            <div className={styles.twrapper}>
              <div className={styles.row}>
                <div className={styles.customerImg1}></div>
                <div className={styles.column}>
                  <Testimonial
                    text="The programs they provide bring in thousands of dollars of sales."
                    author="David Anker"
                  />
                  <Testimonial
                    text="Really great people that understand how to push a business ethically and honestly. Proper marketing works!"
                    author="Erich Schmidt"
                  />
                </div>
              </div>
              <div className={styles.row}>
                <Testimonial
                  text="This is the absolute best CRM company we have worked with! I’m so happy with them, their system creates such an easy way for us to communicate with our customers and has helped us get so many more reviews!"
                  author="Rachel Kosack"
                />
                <Testimonial
                  text="I love the product. Keeps me in touch with my customers efficiently with follow ups, review requests, and reminders. The platform is intuitive and when I need help the guys at MA are there to help quickly."
                  author="Joel Johnson"
                />
              </div>
            </div>
            <div className={styles.twrapper}>
              <Testimonial
                text="Wanna know what really sets this company apart from any other online company, you can always talk to someone quickly or right away. When I get in a panic they are so quick to respond. I couldn’t ask for any better customer service. The company is ran like they have 200 employees but when you need something its like they have 2 employees dedicated to helping your needs. You never feel like you are just another client in a pool of other clients. Highly recommended!"
                author="Tim Suggs"
              />
              <div className={styles.row}>
                <div className={styles.customerImg2}></div>
                <Testimonial
                  text="Mechanic Advisor has been a great asset to my company. Since starting with them our appointments have grown substantially. Plus it gives us the ability to talk to our customers via text and email! Love this program! And the customer service I have received from the Mechanic Advisor team is phenomenal!"
                  author="Chris Probst"
                />
              </div>
            </div>
            <div className={styles.poligon1}></div>
          </div>
          <div className={styles.testimonialsTablet}>
            <div className={styles.twrapper}>
              <Testimonial
                text="Wanna know what really sets this company apart from any other online company, you can always talk to someone quickly or right away. When I get in a panic they are so quick to respond. I couldn’t ask for any better customer service. The company is ran like they have 200 employees but when you need something its like they have 2 employees dedicated to helping your needs. You never feel like you are just another client in a pool of other clients. Highly recommended!"
                author="Tim Suggs"
              />
              <div className={styles.row}>
                <div className={styles.column}>
                  <div className={styles.customerImg1}></div>
                  <Testimonial
                    text="The programs they provide bring in thousands of dollars of sales."
                    author="David Anker"
                  />
                  <Testimonial
                    text="This is the absolute best CRM company we have worked with! I’m so happy with them, their system creates such an easy way for us to communicate with our customers and has helped us get so many more reviews!"
                    author="Rachel Kosack"
                  />
                </div>
                <div className={styles.column}>
                  <Testimonial
                    text="Really great people that understand how to push a business ethically and honestly. Proper marketing works!"
                    author="Erich Schmidt"
                  />
                  <Testimonial
                    text="I love the product. Keeps me in touch with my customers efficiently with follow ups, review requests, and reminders. The platform is intuitive and when I need help the guys at MA are there to help quickly."
                    author="Joel Johnson"
                  />
                  <Testimonial
                    text="Mechanic Advisor has been a great asset to my company. Since starting with them our appointments have grown substantially. Plus it gives us the ability to talk to our customers via text and email! Love this program! And the customer service I have received from the Mechanic Advisor team is phenomenal!"
                    author="Chris Probst"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.banner}>
            <div className={styles.card}>
              <img
                className={styles.bannerStars}
                src={starsIcon}
                alt="rating icon"
              />
              <div className={styles.bannerDivider}></div>
              <img
                className={styles.bannerSmile}
                src={smileIcon}
                alt="smile icon"
              />
              <div className={styles.bannerText}>
                The staff at Steer by Mechanic Advisor is always so helpful and
                prompt at getting back to me. They have helped with email, text,
                and mail outs. We have seen great results with each one. They
                also check in and review our dashboard with helpful suggestions
                on marketing. We have been very pleased with their great
                service!
              </div>
              <div className={styles.bannerAuthor}>
                Brenda O’Neal, Repair One Auto | Woodlands
              </div>
              <div className={styles.quotesIcon}></div>
            </div>
          </div>
          <div className={styles.testimonials}>
            <div className={styles.twrapper}>
              <Testimonial
                text="Just made the move from another text provider. Glad we did! Mechanic Advisor Customer Service is great! They are always available to help. Good Statistical information. Especially like the chat widget they have enabled on our website."
                author="Laura Frank"
              />
              <div className={styles.row}>
                <div className={styles.column}>
                  <Testimonial
                    text="It is amazing. I was using a differently company for a year and a half and have not seen results the way I get from Mechanic Advisor in just 3 months."
                    author="Meineke of West Boca"
                  />
                  <Testimonial
                    text="I was weary about doing the Post Card Blitz to our customers, but they guaranteed the results. I see why now, it worked, and they knew it!! The texting campaign is a hit! Great Service!!! Thanks"
                    author="Ronald Goins"
                  />
                </div>
                <div className={styles.customerImg3}></div>
              </div>
            </div>
            <div className={styles.twrapper}>
              <div className={styles.row}>
                <div className={styles.column}>
                  <Testimonial
                    text="Amazing service! They are always around to help if you have any issues and my customers love the texting interaction."
                    author="Jason Klann"
                  />
                  <Testimonial
                    text="Mechanic Advisor has worked well for our shop. We are seeing increase in traffic, and our customer's have responded well to the text and email follow up."
                    author="Chris Hubert"
                  />
                </div>
                <div className={styles.customerImg4}></div>
              </div>
              <Testimonial
                text="I have recently started using Mechanic Advisor at my new place of employment and I think this system is fantastic! I love how attentive everyone is. If I ever need help with anything their support team is readily available to direct me. I would recommend this system to anyone in the automotive industry looking for an innovative, easy to follow, automated communication program. This is the best one yet!"
                author="Abi Ariza"
              />
            </div>
            <div className={styles.poligon2}></div>
          </div>
          <div className={styles.testimonialsTablet}>
            <div className={styles.twrapper}>
              <Testimonial
                text="Just made the move from another text provider. Glad we did! Mechanic Advisor Customer Service is great! They are always available to help. Good Statistical information. Especially like the chat widget they have enabled on our website."
                author="Laura Frank"
              />
              <div className={styles.row}>
                <div className={styles.column}>
                  <Testimonial
                    text="Amazing service! They are always around to help if you have any issues and my customers love the texting interaction."
                    author="Jason Klann"
                  />
                  <Testimonial
                    text="It is amazing. I was using a differently company for a year and a half and have not seen results the way I get from Mechanic Advisor in just 3 months."
                    author="Meineke of West Boca"
                  />
                  <Testimonial
                    text="Mechanic Advisor has worked well for our shop. We are seeing increase in traffic, and our customer's have responded well to the text and email follow up."
                    author="Chris Hubert"
                  />
                </div>
                <div className={styles.column}>
                  <Testimonial
                    text="I was weary about doing the Post Card Blitz to our customers, but they guaranteed the results. I see why now, it worked, and they knew it!! The texting campaign is a hit! Great Service!!! Thanks"
                    author="Ronald Goins"
                  />
                  <div className={styles.customerImg2}></div>
                </div>
              </div>
              <Testimonial
                text="I have recently started using Mechanic Advisor at my new place of employment and I think this system is fantastic! I love how attentive everyone is. If I ever need help with anything their support team is readily available to direct me. I would recommend this system to anyone in the automotive industry looking for an innovative, easy to follow, automated communication program. This is the best one yet!"
                author="Abi Ariza"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerTestimonials;
