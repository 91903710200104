import React from "react";
import UpperSection from "../Shared/UpperSection";
import * as styles from "./WebsiteChat.module.scss";
import starsIcon from "../../assets/icons/i-stars-green.svg";
import smileIcon from "../../assets/icons/i-smile-green.svg";
import { StaticImage } from "gatsby-plugin-image";
import { useScreenSize } from "../../hooks";

const FEATURES = [
  {
    title: "Appointment request",
    description:
      "Allow customers to conveniently schedule their vehicle’s service and repair appointments with our auto repair chat software.",
    image: <div className={styles.imgAppointment}></div>,
  },
  {
    title: "Share shop reviews",
    description:
      "Easily share commonly searched information about your auto repair shop with prospective customers, such as your shop’s reviews.",
    image: <div className={styles.imgReviews}></div>,
  },
  {
    title: "Answer all customer questions",
    description:
      "Because our Auto Repair Chat Software immediately takes the conversation to text, customers can stay connected on their terms while you manage it from the same inbox as everything else.",
    image: <div className={styles.imgQuestions}></div>,
  },
];

const BANNER = {
  text: "Just made the move from another text provider. Glad we did! Mechanic Advisor Customer Service is great! They are always available to help. Good Statistical information. Especially like the chat widget they have enabled on our website.",
  author: "Laura Frank",
};

const WebsiteChatProduct = ({}) => {
  const { isMobile, windowWidth } = useScreenSize();
  const isGirlImgShown = windowWidth > 1199;

  return (
    <>
      <UpperSection
        sectionName="Website Chat"
        textTitle="Connect with customers through live chat"
        description="And because it’s text message-based, you can stay connected after they leave your website."
        clsLeft="websiteChatDistance"
        clsRight="websiteChatImgDistance"
        formClass="WebsiteChatForm"
      >
        <div className={styles.imagesWrapper}>
          {isMobile ? (
            <>
              <StaticImage
                src="../../assets/images/WebsiteChat/widget-mob.png"
                alt="widget image"
                className={styles.imgWidget}
                loading="eager"
                quality={100}
              />
              <div className={styles.hexagon}></div>
              <div className={styles.circle}></div>
              <div className={styles.rectangle}></div>
            </>
          ) : (
            <>
              {isGirlImgShown && (
                <StaticImage
                  src="../../assets/images/WebsiteChat/img-girl.png"
                  alt="girl image"
                  className={styles.imgGirl}
                  loading="eager"
                  quality={100}
                  style={{
                    position: "absolute",
                  }}
                  objectFit="contain"
                />
              )}
              <StaticImage
                src="../../assets/images/WebsiteChat/img-bubble.png"
                alt="bubble image"
                className={styles.imgBubble}
                loading="eager"
                quality={100}
                style={{
                  position: "absolute",
                }}
              />
              <StaticImage
                src="../../assets/images/WebsiteChat/img-hello-page.png"
                alt="widget image"
                className={styles.imgHelloPage}
                loading="eager"
                quality={100}
                style={{
                  position: "absolute",
                }}
              />
            </>
          )}
        </div>
      </UpperSection>

      <div className={styles.featuresContainer}>
        {FEATURES.map(({ title, description, image }, i) => (
          <div className={styles.feature} key={i}>
            <div className={styles.text}>
              <div className={styles.title}>{title}</div>
              <div className={styles.description}>{description}</div>
            </div>
            <div className={styles.image}>{image}</div>
          </div>
        ))}
      </div>
      <div className={styles.bannerContainer}>
        <div className={styles.banner}>
          <div className={styles.card}>
            <img className={styles.stars} src={starsIcon} alt="rating icon" />
            <div className={styles.divider}></div>
            <img className={styles.smile} src={smileIcon} alt="smile icon" />
            <div className={styles.text}>{BANNER.text}</div>
            <div className={styles.author}>{BANNER.author}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebsiteChatProduct;
