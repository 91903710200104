import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import AdvanceProfessionalPage from "../components/AdvanceProfessional";

const AdvanceProfessional: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return <AdvanceProfessionalPage />;
};

export default AdvanceProfessional;
