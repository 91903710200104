// extracted by mini-css-extract-plugin
export var heroWrapper = "Integrations-module--heroWrapper--1ixQE";
export var hero = "Integrations-module--hero--1YHju";
export var icon = "Integrations-module--icon--cLaND";
export var spin = "Integrations-module--spin--2L20X";
export var Shapes = "Integrations-module--Shapes--211iZ";
export var Tekmetric = "Integrations-module--Tekmetric--1cHiI";
export var Andreoli = "Integrations-module--Andreoli--ETw-v";
export var NapaTracs = "Integrations-module--NapaTracs--j5Yvz";
export var MaxxTraxxCE = "Integrations-module--MaxxTraxxCE--5O1lG";
export var ShopController = "Integrations-module--ShopController--2tggd";
export var MAMSoftware = "Integrations-module--MAMSoftware--1tEPr";
export var Lankar = "Integrations-module--Lankar--3OcBj";
export var Shopboss = "Integrations-module--Shopboss--2Eqy2";
export var AutoFluent = "Integrations-module--AutoFluent--oytFU";
export var Protractor = "Integrations-module--Protractor--Ki2AP";
export var Rowriter = "Integrations-module--Rowriter--3vfNd";
export var wrapper = "Integrations-module--wrapper--2QmNZ";
export var sectionWrapper = "Integrations-module--sectionWrapper--1-VMP";
export var sectionTitle = "Integrations-module--sectionTitle--3MyKa";
export var sectionDescription = "Integrations-module--sectionDescription--hxsf1";
export var cardsContainer = "Integrations-module--cardsContainer--3oOMt";
export var card = "Integrations-module--card--3fAMD";
export var logoContainer = "Integrations-module--logoContainer--34B_Y";
export var logo = "Integrations-module--logo--1Pzf8";
export var tekmetric = "Integrations-module--tekmetric--399ed";
export var shopware = "Integrations-module--shopware--2m7Wm";
export var mitchell = "Integrations-module--mitchell--2LayE";
export var rowriter = "Integrations-module--rowriter--1Cln-";
export var vastsql = "Integrations-module--vastsql--3C9Ta";
export var protractor = "Integrations-module--protractor--3L-3k";
export var napatracs = "Integrations-module--napatracs--1BVvo";
export var meineke = "Integrations-module--meineke--1Z4FJ";
export var lankar = "Integrations-module--lankar--1nEnL";
export var alldata = "Integrations-module--alldata--2K5tP";
export var shopboss = "Integrations-module--shopboss--_f4ho";
export var autofluent = "Integrations-module--autofluent--1BbiR";
export var andreoli = "Integrations-module--andreoli--1oKZn";
export var omnique = "Integrations-module--omnique--3ywKM";
export var autos2000 = "Integrations-module--autos2000--3KTZG";
export var fasttrak = "Integrations-module--fasttrak--22L3R";
export var gemcar = "Integrations-module--gemcar--10P5r";
export var maxxtraxx = "Integrations-module--maxxtraxx--iDSPM";
export var precisiontune = "Integrations-module--precisiontune--2TMr9";
export var stocktrac = "Integrations-module--stocktrac--1fr8V";
export var shopcontroller = "Integrations-module--shopcontroller--2kWAz";
export var shopkey = "Integrations-module--shopkey--2YZyO";
export var tiremaster = "Integrations-module--tiremaster--to5mQ";
export var tireshop = "Integrations-module--tireshop--xzB3H";
export var winworks = "Integrations-module--winworks--2AX2U";
export var mojio = "Integrations-module--mojio--3Tp_k";
export var geotab = "Integrations-module--geotab--1jlE7";
export var info = "Integrations-module--info--1lwDs";
export var type = "Integrations-module--type--1mROL";
export var name = "Integrations-module--name--3xZ5z";
export var poligon1 = "Integrations-module--poligon1--3ph7v";
export var poligon2 = "Integrations-module--poligon2--1BTIa";