import React from "react";
import * as styles from "./SubscribePopup.module.scss";
import logo from "../../assets/images/logo-popup.svg";
import rating from "../../assets/icons/i-stars-white.svg";
import iconItemCheck from "../../assets/icons/i-item-checked.svg";
import { HSplit } from "../Shared/Divider";
import Carousel from "./Carousel";
import { PATHS } from "../../constants";
import { Link } from "@reach/router";

export const About: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <div className={styles.about}>
      <header className={styles.aboutHeader}>
        <img src={logo} alt="steer logo" className={styles.aboutLogo} />
        <div className={styles.aboutRate}>
          <img src={rating} alt="rating" />
          <p>Rated 5 stars by current users</p>
        </div>
      </header>
      <div className={styles.dividerWrap}>
        <HSplit className={styles.divider} />
      </div>
      <ul className={styles.aboutList}>
        <li className={styles.aboutListItem}>
          <img src={iconItemCheck} alt="icon checked" />
          See immediate results
        </li>
        <li className={styles.aboutListItem}>
          <img src={iconItemCheck} alt="icon checked" />
          No long-term commitment necessary
        </li>
        <li className={styles.aboutListItem}>
          <img src={iconItemCheck} alt="icon checked" />
          Dedicated marketing specialist with every account
        </li>
      </ul>
      <div className={styles.sliderWrap}>
        <Carousel />
      </div>
      <HSplit className={styles.divider} />
      <footer className={styles.aboutFooter}>
        <span>Need more info?</span>
        <Link to={PATHS.SUPPORT_REQUEST}>
          <button className={styles.btnTalkUs} onClick={onClose}>
            Talk to Us
          </button>
        </Link>
      </footer>
    </div>
  );
};
