import React from "react";
import * as styles from "./404.module.scss";
import { Link } from "@reach/router";
import IconHome from "../../assets/icons/404/i-home.svg";
import IconHelp from "../../assets/icons/404/i-help.svg";
import { PATHS } from "../../constants";

export default () => {
  return (
    <div className={styles.container}>
      <div className={styles.image} />
      <div className={styles.content}>
        <div className={styles.title}>Looks like you got lost</div>
        <div className={styles.text}>
          The page you are looking for does not exist.
        </div>
        <div className={styles.buttonsContainer}>
          <Link to="/">
            <div className={styles.button}>
              <img className={styles.icon} src={IconHome}></img>
              <span className={styles.text}>Go Home</span>
            </div>
          </Link>
          <Link to={PATHS.SUPPORT_REQUEST}>
            <div className={styles.button}>
              <img className={styles.icon} src={IconHelp}></img>
              <span className={`${styles.text} ${styles.textDesktop}`}>
                Contact Support
              </span>
              <span className={`${styles.text} ${styles.textMobile}`}>
                Support
              </span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
