import React from "react";
import MiddleSection from "../Shared/MiddleSection";
import UpperSection from "../Shared/UpperSection";
import { FeaturesSlider } from "../Shared/FeaturesSlider";
import * as styles from "./DirectMail.module.scss";
import iconDesign from "../../assets/icons/DirectMail/i-direct-mail-icon-design.svg";
import iconQuantities from "../../assets/icons/DirectMail/i-direct-mail-icon-quantities.svg";
import iconCustomer from "../../assets/icons/DirectMail/i-direct-mail-icon-customer.svg";
import { StaticImage } from "gatsby-plugin-image";
import { useScreenSize } from "../../hooks";

const DIRECT_MAIL_TOOLS = [
  {
    toolTitle: "Professional template designs",
    toolDescription:
      "Choose from dozens of postcard templates and customize each to fit your auto repair shop. Build your own postcard from scratch with our template design tool.",
    icon: iconDesign,
  },
  {
    toolTitle: "No minimum quantities",
    toolDescription:
      "Send as many postcards or letters as necessary to fulfill your auto repair shop direct mail needs. Our system is flexible enough to handle any print quantity.",
    icon: iconQuantities,
  },
  {
    toolTitle: "Target new customers",
    toolDescription:
      "Find new customers with targeted mailing options, including area and demographic specific data.",
    icon: iconCustomer,
  },
];

const DirectMailProduct = ({}) => {
  const { isMobile } = useScreenSize();

  return (
    <>
      <UpperSection
        sectionName="Direct Mail Postcards"
        textTitle="Gain new customers with direct mail postcards"
        description="Find new customers, send repair reminders, and share offers with auto shop postcards and auto repair direct mail marketing."
        clsLeft="directMailDistance"
        clsRight="directMailImgDistance"
        formClass="DirectMailForm"
      >
        {isMobile ? (
          <StaticImage
            src="../../assets/images/DirectMail/postcards_mobile.png"
            alt="postcards"
            className={styles.heroMobile}
            loading="eager"
            quality={100}
          />
        ) : (
          <div className={styles.heroImg}>
            <StaticImage
              src="../../assets/images/DirectMail/postcard_bottom.png"
              alt="postcard"
              className={styles.heroPostcadBottom}
              loading="eager"
              quality={100}
              style={{
                position: "absolute",
              }}
            />
            <StaticImage
              src="../../assets/images/DirectMail/postcard_upper.png"
              alt="postcard"
              className={styles.heroPostcadUpper}
              loading="eager"
              quality={100}
              style={{
                position: "absolute",
              }}
            />
          </div>
        )}
      </UpperSection>
      <MiddleSection
        textTitle="Stand out with auto repair direct mail"
        description="Find new customers and contact existing ones with custom postcards and letters for your repair shop."
        tools={DIRECT_MAIL_TOOLS}
        clsImg="standOutWrapper"
      >
        <div className={styles.standOut}></div>
      </MiddleSection>
      <FeaturesSlider excludedSlideIdx={3} />
    </>
  );
};

export default DirectMailProduct;
