import React, { useState, useEffect } from "react";
import * as styles from "./SubscribePopup.module.scss";
import { About } from "./About";
import { SubscribeForm } from "./SubscribeForm";
import { Success } from "./Success";
import HubspotSignupForm from "./HubspotSignupForm";
import HubspotChilipiperSignupForm from "./HubspotChilipiperSignupForm";
import HubspotMeetingsForm from "./HubspotMeetingsForm";
import { Success as HubspotMeetingsSuccess } from "./HubspotMeetingsSuccess";
import { Error as HubspotMeetingsError } from "./HubspotMeetingsError";
import {
  ANALYTICS_EVENTS,
  addAnalyticsEvent,
} from "../../services/analyticsService";

export type SubscribePopupProps = {
  userEmail?: string;
  close: () => void;
};

const SubscribePopup: React.FC<SubscribePopupProps> = ({
  userEmail = "",
  close,
}) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [meetingInfo, setMeetingInfo] = useState({});

  useEffect(() => {
    addAnalyticsEvent(ANALYTICS_EVENTS.getEarlyAccessPopupOpened);
  }, []);

  const handleClose = () => {
    setSuccess(false);
    close();
  };
  const handleLoad = () => {
    addAnalyticsEvent(ANALYTICS_EVENTS.getEarlyAccessFormShown);
  };
  const handleSubmit = () => {
    addAnalyticsEvent(ANALYTICS_EVENTS.getEarlyAccessFormSubmitAttempt);
  };
  const handleError = () => {
    setError(true);
  };
  const handleSuccess = ({ timestamp, name }) => {
    addAnalyticsEvent(ANALYTICS_EVENTS.getEarlyAccessFormSubmitted);
    setMeetingInfo({ timestamp, name });
    setSuccess(true);
  };

  return (
    <div className={styles.subscribePopup}>
      <div className={styles.popup}>
        <button className={styles.btnClose} onClick={handleClose}></button>
        <About onClose={close} />
        {/* <HubspotChilipiperSignupForm
          userEmail={userEmail}
          onLoad={handleLoad}
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
        /> */}
        {error ? (
          <HubspotMeetingsError onTryAgain={() => setError(false)} />
        ) : success ? (
          // regular success page (not hubspot meetings)
          // <Success close={close} />
          <HubspotMeetingsSuccess meetingInfo={meetingInfo} />
        ) : (
          // regular form(not hubspot)
          // <SubscribeForm
          //   userEmail={userEmail}
          //   close={handleClose}
          //   onSuccess={setSuccess}
          // />
          // <HubspotSignupForm
          //   userEmail={userEmail}
          //   onLoad={() => {
          //     addAnalyticsEvent(ANALYTICS_EVENTS.getEarlyAccessFormShown);
          //   }}
          //   onSubmit={() => {
          //     addAnalyticsEvent(
          //       ANALYTICS_EVENTS.getEarlyAccessFormSubmitAttempt
          //     );
          //   }}
          //   onSuccess={() => {
          //     addAnalyticsEvent(ANALYTICS_EVENTS.getEarlyAccessFormSubmitted);
          //     setSuccess(true);
          //   }}
          // />
          <HubspotMeetingsForm
            userEmail={userEmail}
            onLoad={handleLoad}
            onError={handleError}
            onSuccess={handleSuccess}
          />
        )}
      </div>
    </div>
  );
};

export default SubscribePopup;
