import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import SteermaticsTermsAndConditionsPage from "../components/SteermaticsTermsAndConditions";
import { Helmet } from "react-helmet";

const TermsAndConditions: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return (
    <>
      <Helmet>
        <title>Steermatics Terms and Conditions | Steer</title>
        <meta
          name="description"
          content="Steer CRM Steermatics Terms and Conditions."
        />
      </Helmet>
      <SteermaticsTermsAndConditionsPage />
    </>
  );
};

export default TermsAndConditions;
