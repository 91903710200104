import React, { useEffect } from "react";
import * as styles from "./SubscribePopup.module.scss";
import cn from "classnames";

const HubspotMeetingsForm = ({ userEmail, onLoad, onError, onSuccess }) => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const script = document.createElement("script");
      script.src =
        "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
      script.async = true;
      document.body.appendChild(script);

      const handleMessage = (e) => {
        if (e.data) {
          if (e.data === "readyForConsentListener") {
            onLoad();
          } else if (e.data.meetingBookFailed) {
            onError();
          } else if (e.data.meetingBookSucceeded) {
            const {
              event: { dateTime: timestamp },
              postResponse: {
                organizer: { name },
              },
            } = e.data.meetingsPayload.bookingResponse;
            onSuccess({ timestamp, name });
          }
        }
      };
      window.addEventListener("message", handleMessage);

      return () => {
        document.body.removeChild(script);
        window.removeEventListener("message", handleMessage);
      };
    }
  }, []);

  return (
    <div className={styles.meetingsFormWrapper}>
      <div className={styles.meetingsFormContainer}>
        <div
          className={cn("meetings-iframe-container", styles.hubspotContainer)}
          data-src={`https://learn.steercrm.com/meetings/mechanic-advisor/steers-crm-scheduler?embed=true&email=${userEmail}`}
        ></div>
      </div>
    </div>
  );
};

export default HubspotMeetingsForm;
