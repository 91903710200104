import React from "react";
import * as styles from "./Footer.module.scss";
import { PATHS } from "../../constants";

export const BannerQuestion: React.FC = () => {
  return (
    <>
      <div className={styles.banner}>
        <p className={styles.title}>Have a question?</p>
        <p className={styles.subtitle}>Get in touch! We are here to help</p>
        <a href={PATHS.SUPPORT_REQUEST} style={{ color: "inherit" }}>
          <div className={styles.button}>
            Contact Us
            <span className={styles.arrow}>&#8594;</span>
          </div>
        </a>
        <div className={styles.icon}></div>
      </div>
      <div className={styles.bannerPlaceholder}></div>
    </>
  );
};
