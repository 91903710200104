import React, { useState } from "react";
import TextField from "../Shared/TextField";
import { saveEmail } from "../../api/emails";
import * as styles from "./SubscribePopup.module.scss";
import iconLoading from "../../assets/icons/i-loader.svg";
import { validateEmail } from "../../utils/validate";
import { PhoneField } from "../Shared/PhoneField";
import { SubscribePopupProps } from ".";

type FormValues = {
  business: string;
  system: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  businessInvalid: string;
  firstNameInvalid: string;
  lastNameInvalid: string;
  emailInvalid: string;
};

const emptyFieldMsg = "Field cannot be blank";
export const invalidEmailMsg = "We do not recognize this email address";

export const SubscribeForm: React.FC<
  SubscribePopupProps & { onSuccess: (success: boolean) => void }
> = ({ userEmail, close, onSuccess }) => {
  const initialFormValues: FormValues = {
    business: "",
    system: "",
    firstName: "",
    lastName: "",
    email: userEmail,
    phone: "",
    businessInvalid: "",
    firstNameInvalid: "",
    lastNameInvalid: "",
    emailInvalid: "",
  };
  const [formValues, setFormValues] = useState(initialFormValues);
  const [submitting, setSubmitting] = useState(false);

  const handleBusinessChange = (business: string) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      business,
      businessInvalid: "",
    }));
  };
  const handleSystemChange = (system: string) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      system,
    }));
  };
  const handleFirstNameChange = (firstName: string) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      firstName,
      firstNameInvalid: "",
    }));
  };
  const handleLastNameChange = (lastName: string) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      lastName,
      lastNameInvalid: "",
    }));
  };
  const handleEmailChange = (email: string) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      email,
      emailInvalid: "",
    }));
  };
  const handlePhoneChange = (phone: string) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      phone,
    }));
  };

  const validateForm = (): boolean => {
    if (!formValues.business) {
      setFormValues((prevValues) => ({
        ...prevValues,
        businessInvalid: emptyFieldMsg,
      }));
      return false;
    }
    if (!formValues.firstName) {
      setFormValues((prevValues) => ({
        ...prevValues,
        firstNameInvalid: emptyFieldMsg,
      }));
      return false;
    }
    if (!formValues.lastName) {
      setFormValues((prevValues) => ({
        ...prevValues,
        lastNameInvalid: emptyFieldMsg,
      }));
      return false;
    }
    if (!formValues.email) {
      setFormValues((prevValues) => ({
        ...prevValues,
        emailInvalid: emptyFieldMsg,
      }));
      return false;
    }
    if (!validateEmail(formValues.email)) {
      setFormValues((prevValues) => ({
        ...prevValues,
        emailInvalid: invalidEmailMsg,
      }));
      return false;
    }
    return true;
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
    } else {
      setSubmitting(true);
      const result = await saveEmail(formValues);
      if (result) {
        setSubmitting(false);
        onSuccess(true);
      }
    }
  };

  return (
    <form className={styles.subscribeForm} onSubmit={handleSubmit}>
      <h1 className={styles.popupTitle}>Get Early Access</h1>
      <TextField
        value={formValues.business}
        onChange={handleBusinessChange}
        label="Business name"
        className={styles.fieldRow}
        errorText={formValues.businessInvalid}
        name="entry.1671411871"
      />
      <div className={styles.fieldRow}>
        <TextField
          value={formValues.firstName}
          onChange={handleFirstNameChange}
          label="First name"
          errorText={formValues.firstNameInvalid}
          className={styles.field}
        />
        <TextField
          value={formValues.lastName}
          onChange={handleLastNameChange}
          label="Last name"
          className={styles.field}
          errorText={formValues.lastNameInvalid}
        />
      </div>
      <TextField
        value={formValues.email}
        onChange={handleEmailChange}
        label="Email"
        className={styles.fieldRow}
        errorText={formValues.emailInvalid}
      />
      <PhoneField
        className={styles.fieldRow}
        value={formValues.phone}
        label="Phone number"
        onChange={handlePhoneChange}
      />
      <TextField
        value={formValues.system}
        onChange={handleSystemChange}
        label="What SMS do you use?"
        className={styles.fieldRow}
      />
      <button type="submit" className={styles.formBtn}>
        {submitting ? (
          <img className={styles.loader} src={iconLoading} alt="loading" />
        ) : (
          "Get Early Access"
        )}
      </button>
    </form>
  );
};
