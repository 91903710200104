import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { FeaturesSlider } from "../components/Shared/FeaturesSlider";
import ReputationManagementProduct from "../components/ReputationManagement";
import { Helmet } from "react-helmet";

const ReputationManagement: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return (
    <>
      <Helmet>
        <title>Auto Repair Reputation Management | Steer</title>
        <meta
          name="description"
          content="Steer allows auto shops to manage their online reputation from various sources in one platform and provides alerts of new reviews."
        />
      </Helmet>
      <ReputationManagementProduct />
      <FeaturesSlider excludedSlideIdx={4} />
    </>
  );
};

export default ReputationManagement;
