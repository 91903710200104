import React from "react";
import * as styles from "./Footer.module.scss";
import { PATHS } from "../../constants";
import cn from "classnames";

export const BannerCareers: React.FC = () => {
  return (
    <>
      <div className={cn(styles.banner, styles.bannerCareers)}>
        <p className={cn(styles.title, styles.titleCareers)}>
          Our story, your future
        </p>
        <p className={cn(styles.subtitle, styles.subtitleCareers)}>
          We're more than a CRM. We're redefining how shops keep customers
          coming back. Join Steer's story.
        </p>

        <a href={PATHS.ABOUT_US} style={{ color: "inherit" }}>
          <div className={cn(styles.button, styles.buttonCareers)}>
            About Steer
            <span className={styles.arrow}>&#8594;</span>
          </div>
        </a>
        <div className={cn(styles.icon, styles.iconCareers)}></div>
      </div>
      <div className={styles.bannerPlaceholder}></div>
    </>
  );
};
