import React from "react";
import cn from "classnames";
import * as styles from "./Switch.module.scss";

type SwitchProps = {
  onChange: () => void;
  isChecked: boolean;
  leftLabel: string;
  rightLabel?: string;
  highlightedText?: string;
};

const SwitchButton: React.FC<SwitchProps> = ({
  isChecked,
  onChange,
  leftLabel,
  rightLabel,
  highlightedText,
}) => {
  return (
    <button className={styles.switchWrapper} onClick={onChange}>
      {leftLabel && (
        <label
          className={cn(styles.leftLabel, {
            [styles.checked]: isChecked,
          })}
        >
          {leftLabel}
        </label>
      )}
      <span
        className={cn(styles.handle, {
          [styles.checked]: isChecked,
        })}
      >
        <span className={styles.toggle}></span>
      </span>

      {rightLabel && (
        <label
          className={cn(styles.rightLabel, {
            [styles.disabled]: !isChecked,
          })}
        >
          {rightLabel}
          {highlightedText && (
            <span className={styles.highlightedText}>
              &nbsp;{highlightedText}
            </span>
          )}
        </label>
      )}
    </button>
  );
};

export default SwitchButton;
