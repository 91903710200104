import React from "react";
import { Link } from "@reach/router";
import { StaticImage } from "gatsby-plugin-image";
import { useScreenSize } from "../../hooks";
import * as styles from "./ServiceReminders.module.scss";
import { PATHS } from "../../constants";
import UpperSection from "../Shared/UpperSection";
import MiddleSection from "../Shared/MiddleSection";
import Card from "../Shared/Card/Card";
import {
  CustomizeRemindersIcon,
  EfficientConfigurationIcon,
  CustomersInformedIcon,
} from "../../assets/icons/ServiceReminders";
import iconServiceReminders from "../../assets/icons/ServiceReminders/i-service-reminders.svg";
import iconServiceRecommendation from "../../assets/icons/TextMessaging/i-textmessaging-service-recommendation.svg";
import icon from "../../assets/icons/i-arrow-up-active.svg";
import iconDark from "../../assets/icons/i-arrow-up-dark-blue.svg";

const MAINTENANCE_REMINDERS_TOOLS = [
  {
    toolTitle: "Automated text & email reminders",
    toolDescription:
      "Save time with automated reminders for essentials like oil changes and state inspections. Want to customize them? We've got you covered.",
    icon: iconServiceReminders,
    className: "serviceRemindersToolTitle",
  },
  {
    toolTitle: "Maintenance roadmap",
    toolDescription:
      "In their vehicle profile, customers can easily plan future services, spotting every recommended maintenance. This direct view boosts the likelihood of multi-service bookings, maximizing the value of each appointment.",
    icon: iconServiceRecommendation,
    className: "serviceRemindersToolTitle",
  },
];

const DEFFERED_SERVICE_ALERTS_TOOLS = [
  {
    toolTitle: "Shop Management System integration",
    toolDescription:
      "Steer seamlessly connects with your Shop Management System, making it a breeze to automate your deferred services reminders without double data entry.",
    icon: iconServiceReminders,
    className: "serviceRemindersToolTitle",
  },
  {
    toolTitle: "Tailored service reminders",
    toolDescription:
      "Send automated texts and emails to your customers, reminding them of services they need to keep their vehicle in top shape.",
    icon: iconServiceRecommendation,
    className: "serviceRemindersToolTitle",
  },
];

const CARDS = [
  {
    title: "Customize your reminders",
    description:
      "Add custom services, reminders and service intervals based on your business needs. Steer provides flexibility, offering your customers a personalized experience.",
    icon: CustomizeRemindersIcon,
  },
  {
    title: "Keep customers informed",
    description:
      "Customers don't always know when their vehicle needs maintenance. Automated service reminders prompt them to book a service and inform them about their vehicle's maintenance needs.",
    icon: CustomersInformedIcon,
  },
  {
    title: "Efficient configuration ",
    description:
      "Build campaigns that are not only more targeted but also more effective. Choose the exact services to be included in reminders and apply additional filters to target specific customers.",
    icon: EfficientConfigurationIcon,
  },
];

const ServiceRemindersProduct = ({}) => {
  const { windowWidth, isPC, isMiddlePC, isTablet, isMobile } = useScreenSize();
  const isMiddleSize = (isPC || isMiddlePC) && windowWidth <= 1400;

  return (
    <>
      <UpperSection
        sectionName="Service Reminders"
        textTitle="Maximize service and minimize hassle with service reminders"
        description="Increase car count and repeat customers with automatic reminders for routine, recommended maintenance and deferred services."
        clsLeft="ServiceRemindersHero"
        clsRight="ServiceRemindersImgDistance"
        formClass="ServiceRemindersForm"
      >
        <div className={styles.imgWrapper}>
          {!isMiddleSize && !isTablet && !isMobile && <LargeImages />}
          {isMiddleSize && <MiddleImages />}
          {isTablet && <TabletImages />}
          {isMobile && <MobileImages />}
        </div>
      </UpperSection>

      <MiddleSection
        textTitle="Keep cars on track with maintenance reminders"
        tools={MAINTENANCE_REMINDERS_TOOLS}
        cls="serviceRemindersUpper"
        clsDescription="serviceReminders"
        clsImg="serviceRemindersImgsUpper"
      >
        <div className={styles.maintenanceRemindersImage}></div>
      </MiddleSection>
      <MiddleSection
        textTitle="Power retention with deferred service alerts"
        tools={DEFFERED_SERVICE_ALERTS_TOOLS}
        isReversed
        cls="serviceRemindersBottom"
        clsDescription="serviceReminders"
        clsImg="serviceRemindersImgsBottom"
      >
        <div className={styles.deferredServiceAlertsImage}></div>
      </MiddleSection>
      <div className={styles.serviceRemindersWrapper}>
        <div className={styles.serviceReminders}>
          <div className={styles.serviceRemindersSection}>
            <div className={styles.serviceRemindersHeader}>
              <div className={styles.title}>
                Integrated appointment scheduling
              </div>
              <div className={styles.description}>
                Your customers will appreciate the ease of scheduling
                appointments for both routine maintenance and deferred services.
                A few clicks are all it takes to keep their vehicle in top shape
                and your bays full.
              </div>
            </div>
            <div className={styles.cardsContainer}>
              {CARDS.map(({ title, description, icon }, i) => (
                <Card
                  title={title}
                  description={description}
                  key={i}
                  variant="ServiceReminders"
                  icon={icon}
                />
              ))}
            </div>
          </div>
          <div className={styles.serviceRemindersSection}>
            <div className={styles.serviceRemindersHeader}>
              <div className={styles.title}>Manage online reviews</div>
              <div className={styles.description}>
                Take control of your reviews and improve your score across the
                internet.
              </div>
            </div>
            <div className={styles.cardsWrapper}>
              <div className={styles.card}>
                <div className={styles.boostReviews}>
                  <div className={styles.cardTitle}>
                    <div className={styles.cardTitleText}>
                      Monitor your shop's reviews & take action
                    </div>
                    <div className={styles.cardTitleDescription}>
                      Increase your shop's rating across major review platforms.
                      Our average shop sees 40+ new reviews each month.
                    </div>
                    <Link
                      to={PATHS.REPUTATION_MANAGEMENT}
                      className={styles.link}
                    >
                      <span>Learn More</span>
                      <img className={styles.linkIcon} src={icon}></img>
                      <img className={styles.linkIconDark} src={iconDark}></img>
                    </Link>
                  </div>
                  <div className={styles.imgWrapper}>
                    <div className={styles.reviewsReceivedImg}>
                      <div className={styles.reviewsDataImg}></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.card}>
                <div className={styles.rateReviewImg}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceRemindersProduct;

function LargeImages() {
  return (
    <>
      <StaticImage
        src="../../assets/images/ServiceReminders/servicesList.png"
        alt="service list"
        className={styles.servicesListImg}
        loading="eager"
        quality={100}
        objectFit="contain"
      />
      <StaticImage
        src="../../assets/images/ServiceReminders/shopLogo.png"
        alt="shop logo"
        className={styles.shopLogoImg}
        loading="eager"
        quality={100}
        objectFit="contain"
        style={{
          position: "absolute",
        }}
      />
      <StaticImage
        src="../../assets/images/ServiceReminders/message.png"
        alt="message image"
        className={styles.messageImg}
        loading="eager"
        quality={100}
        objectFit="contain"
        style={{
          position: "absolute",
        }}
      />
      <StaticImage
        src="../../assets/images/ServiceReminders/girl.png"
        alt="girl image"
        className={styles.girlImg}
        loading="eager"
        quality={100}
        objectFit="contain"
        style={{
          position: "absolute",
        }}
      />
      <StaticImage
        src="../../assets/images/ServiceReminders/ringRed.png"
        alt="ring image"
        className={styles.ringRedImg}
        loading="eager"
        quality={100}
        objectFit="contain"
        style={{
          position: "absolute",
        }}
      />
    </>
  );
}

function MiddleImages() {
  return (
    <>
      <StaticImage
        src="../../assets/images/ServiceReminders/servicesList-large.png"
        alt="service list"
        className={styles.servicesListImg}
        loading="eager"
        quality={100}
        objectFit="contain"
      />
      <StaticImage
        src="../../assets/images/ServiceReminders/shopLogo-large.png"
        alt="shop logo"
        className={styles.shopLogoImg}
        loading="eager"
        quality={100}
        objectFit="contain"
        style={{
          position: "absolute",
        }}
      />
      <StaticImage
        src="../../assets/images/ServiceReminders/message-large.png"
        alt="message image"
        className={styles.messageImg}
        loading="eager"
        quality={100}
        objectFit="contain"
        style={{
          position: "absolute",
        }}
      />
      <StaticImage
        src="../../assets/images/ServiceReminders/girl-large.png"
        alt="girl image"
        className={styles.girlImg}
        loading="eager"
        quality={100}
        objectFit="contain"
        style={{
          position: "absolute",
        }}
      />
      <StaticImage
        src="../../assets/images/ServiceReminders/ringRed-large.png"
        alt="ring image"
        className={styles.ringRedImg}
        loading="eager"
        quality={100}
        objectFit="contain"
        style={{
          position: "absolute",
        }}
      />
    </>
  );
}

function TabletImages() {
  return (
    <>
      <StaticImage
        src="../../assets/images/ServiceReminders/servicesList-tablet.png"
        alt="service list"
        className={styles.servicesListImg}
        loading="eager"
        quality={100}
        objectFit="contain"
      />
      <StaticImage
        src="../../assets/images/ServiceReminders/shopLogo-tablet.png"
        alt="shop logo"
        className={styles.shopLogoImg}
        loading="eager"
        quality={100}
        objectFit="contain"
        style={{
          position: "absolute",
        }}
      />
      <StaticImage
        src="../../assets/images/ServiceReminders/message-tablet.png"
        alt="message image"
        className={styles.messageImg}
        loading="eager"
        quality={100}
        objectFit="contain"
        style={{
          position: "absolute",
        }}
      />
      <StaticImage
        src="../../assets/images/ServiceReminders/girl-tablet.png"
        alt="girl image"
        className={styles.girlImg}
        loading="eager"
        quality={100}
        objectFit="contain"
        style={{
          position: "absolute",
        }}
      />
      <StaticImage
        src="../../assets/images/ServiceReminders/ringRed-tablet.png"
        alt="ring image"
        className={styles.ringRedImg}
        loading="eager"
        quality={100}
        objectFit="contain"
        style={{
          position: "absolute",
        }}
      />
    </>
  );
}

function MobileImages() {
  return (
    <>
      <StaticImage
        src="../../assets/images/ServiceReminders/servicesList-mobile.png"
        alt="service list"
        className={styles.servicesListImg}
        loading="eager"
        quality={100}
        objectFit="contain"
      />
      <StaticImage
        src="../../assets/images/ServiceReminders/shopLogo-mobile.png"
        alt="shop logo"
        className={styles.shopLogoImg}
        loading="eager"
        quality={100}
        objectFit="contain"
        style={{
          position: "absolute",
        }}
      />
      <StaticImage
        src="../../assets/images/ServiceReminders/message-mobile.png"
        alt="message image"
        className={styles.messageImg}
        loading="eager"
        quality={100}
        objectFit="contain"
        style={{
          position: "absolute",
        }}
      />
      <StaticImage
        src="../../assets/images/ServiceReminders/girl-mobile.png"
        alt="girl image"
        className={styles.girlImg}
        loading="eager"
        quality={100}
        objectFit="contain"
        style={{
          position: "absolute",
        }}
      />
      <StaticImage
        src="../../assets/images/ServiceReminders/ringRed-mobile.png"
        alt="ring image"
        className={styles.ringRedImg}
        loading="eager"
        quality={100}
        objectFit="contain"
        style={{
          position: "absolute",
        }}
      />
    </>
  );
}
