import React from "react";
import * as styles from "./Card.module.scss";
import { iconCalendar } from "../../../assets/icons/Header/index";
import cn from "classnames";

type CardProps = {
  title: string;
  description: string;
  variant?: string;
  cls?: any;
  icon?: any;
};

const Card: React.FC<CardProps> = ({
  title,
  description,
  variant,
  cls,
  icon,
}) => {
  return (
    <div className={cn(styles.card, styles[`variant${variant}`], cls)}>
      <div className={styles.imgWrapper}>
        <img
          src={icon || iconCalendar}
          className={cn(styles.cardIcon, styles[`variant${variant}`])}
          alt="calendar"
        />
      </div>
      <div className={cn(styles.textWrapper, styles[`variant${variant}`])}>
        <div className={cn(styles.cardTitle, styles[`variant${variant}`])}>
          {title}
        </div>
        <div
          className={cn(styles.cardDescription, styles[`variant${variant}`])}
        >
          {description}
        </div>
      </div>
    </div>
  );
};

export default Card;
