import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useScreenSize } from "../../hooks";
import MiddleSection from "../Shared/MiddleSection";
import UpperSection from "../Shared/UpperSection";
import * as styles from "./OnlineScheduling.module.scss";
import iconOnlineScheduling from "../../assets/icons/OnlineScheduling/i-online-scheduling.svg";
import iconTimeManagement from "../../assets/icons/OnlineScheduling/i-online-scheduling-time-management.svg";

const ONLINE_SCHEDULING_TOOLS = [
  {
    toolTitle: "Complete time management",
    toolDescription:
      "Our adaptive scheduling and calendar tools are designed specifically for auto repair shops. Easily manage your shop’s calendar from online customer service requests to the moment the service is completed. Arrange your schedule around services, technician availability, bays and more.",
    icon: iconTimeManagement,
  },
  {
    toolTitle: "Convenient online scheduling",
    toolDescription:
      "Spend less time scheduling by phone with integrated online scheduling tools. New and existing customers can choose available appointment times on your website, Facebook, and local business listings using our Auto Repair Shop Scheduling Software. After scheduling, you can send follow-up reminders to increase appointment show-ups.",
    icon: iconOnlineScheduling,
  },
];

const OnlineSchedulingProduct = ({}) => {
  const { isTablet, isMobile } = useScreenSize();

  return (
    <>
      <UpperSection
        sectionName="Online Scheduling"
        textTitle="Auto repair scheduling software"
        description="Streamline your services, staffing, and calendar with customizable scheduling tools. Plus, save time on the phone with easy online customer appointment setting."
        clsLeft="onlineSchedulingDistance"
        clsRight="onlineSchedulingImgDistance"
        formClass="OnlineSchedulingForm"
      >
        {isMobile ? (
          <StaticImage
            src="../../assets/images/OnlineScheduling/hero-mobile.png"
            alt="driver"
            className={styles.heroMobile}
            loading="eager"
            quality={100}
          />
        ) : (
          <div className={styles.imgWrapper}>
            <StaticImage
              src="../../assets/images/OnlineScheduling/img-table.png"
              alt="scheduler image"
              className={styles.table}
              loading="eager"
              quality={100}
              objectFit="contain"
            />
            <StaticImage
              src="../../assets/images/OnlineScheduling/img-tab-green.png"
              alt="tab green image"
              className={styles.tabGreen}
              loading="eager"
              quality={100}
              objectFit="contain"
              style={{
                position: "absolute",
              }}
            />
            <StaticImage
              src="../../assets/images/OnlineScheduling/img-tab-purple.png"
              alt="tab purple image"
              className={styles.tabPurple}
              loading="eager"
              quality={100}
              objectFit="contain"
              style={{
                position: "absolute",
              }}
            />
            <StaticImage
              src="../../assets/images/OnlineScheduling/img-tab-purple.png"
              alt="tab purple image"
              className={styles.tabPurple}
              loading="eager"
              quality={100}
              objectFit="contain"
              style={{
                position: "absolute",
              }}
            />
            <StaticImage
              src="../../assets/images/OnlineScheduling/img-tab-black.png"
              alt="tab black image"
              className={styles.tabBlack}
              loading="eager"
              quality={100}
              objectFit="contain"
              style={{
                position: "absolute",
              }}
            />
            <StaticImage
              src="../../assets/images/OnlineScheduling/img-tab-appointment.png"
              alt="tab appointment image"
              className={styles.tabAppointment}
              loading="eager"
              quality={100}
              objectFit="contain"
              style={{
                position: "absolute",
              }}
            />
            {!isTablet ? (
              <StaticImage
                src="../../assets/images/OnlineScheduling/img-one.png"
                alt="person image"
                className={styles.imgOne}
                loading="eager"
                quality={100}
                objectFit="contain"
                style={{
                  position: "absolute",
                }}
              />
            ) : (
              <StaticImage
                src="../../assets/images/OnlineScheduling/img-one-mob.png"
                alt="person image"
                className={styles.imgOne}
                loading="eager"
                quality={100}
                objectFit="contain"
                style={{
                  position: "absolute",
                }}
              />
            )}

            <StaticImage
              src="../../assets/images/OnlineScheduling/img-two.png"
              alt="person image"
              className={styles.imgTwo}
              loading="eager"
              quality={100}
              objectFit="contain"
              style={{
                position: "absolute",
              }}
            />
            <StaticImage
              src="../../assets/images/OnlineScheduling/img-three.png"
              alt="person image"
              className={styles.imgThree}
              loading="eager"
              quality={100}
              objectFit="contain"
              style={{
                position: "absolute",
              }}
            />
            <div className={styles.imgHexagon}></div>
            <div className={styles.imgCircle}></div>
            <div className={styles.imgTriangle}></div>
          </div>
        )}
      </UpperSection>
      <MiddleSection
        textTitle="Increase shop efficiency"
        description="Advanced Auto Repair Scheduling Software allows you to integrate services, technicians, and your shop calendar. Improve efficiency and decrease down time with connected scheduling through our auto repair scheduling software."
        tools={ONLINE_SCHEDULING_TOOLS}
      >
        <div className={styles.imgSchedulingFeatures}></div>
      </MiddleSection>
    </>
  );
};

export default OnlineSchedulingProduct;
