import React from "react";
import * as styles from "./Footer.module.scss";
import { PATHS } from "../../constants";

export const BannerIntegration: React.FC = () => {
  return (
    <>
      <div className={`${styles.banner} ${styles.bannerIntegration}`}>
        <p className={styles.title}>Integration request</p>
        <p className={`${styles.subtitle} ${styles.subtitleIntegration}`}>
          {`Our dedicated team is actively adding new integrations.
          If your platform is not listed, we would like to hear from you.`}
        </p>
        <a href={PATHS.SUPPORT_REQUEST} style={{ color: "inherit" }}>
          <div className={`${styles.button} ${styles.buttonIntegration}`}>
            Contact Us
            <span className={styles.arrow}>&#8594;</span>
          </div>
        </a>
        <div className={`${styles.icon} ${styles.iconIntegration}`}></div>
      </div>
      <div className={styles.bannerPlaceholder}></div>
    </>
  );
};
