import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import PrivacyPolicyPage from "../components/PrivacyPolicy ";
import { Helmet } from "react-helmet";

const PrivacyPolicy: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Steer</title>
        <meta
          name="description"
          content="Steer CRM Privacy Policy. Please read our Privacy Policy for information relating to the use of the site and our limitation of liability."
        />
      </Helmet>
      <PrivacyPolicyPage />;
    </>
  );
};

export default PrivacyPolicy;
