import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useScreenSize } from "../../hooks";
import MiddleSection from "../Shared/MiddleSection";
import UpperSection from "../Shared/UpperSection";
import * as styles from "./ReputationManagement.module.scss";
import iconMultipleReview from "../../assets/icons/ReputationManagement/i-reputation-management-multiple-review.svg";
import iconAnalyzeFeedback from "../../assets/icons/ReputationManagement/i-reputation-management-analyze-feedback.svg";
import iconRequestFeedback from "../../assets/icons/ReputationManagement/i-reputation-management-request-feedback.svg";

const REPUTATION_MANAGEMENT_TOOLS = [
  {
    toolTitle: "Multiple review sources in one platform",
    toolDescription:
      "View and manage all your online reviews in one place. You’ll receive an alert when a customer reviews your shop, and you can even respond to them with our Online Reputation Management for Auto Repair Shops.",
    icon: iconMultipleReview,
  },
  {
    toolTitle: "Analyze customer feedback",
    toolDescription:
      "Our review analyzer identifies common phrases and terms in your shops online reviews and provides you an understanding of your customers’ sentiment toward your shop.",
    icon: iconAnalyzeFeedback,
  },
  {
    toolTitle: "Request customer feedback",
    toolDescription:
      "Send automated texts or emails that request customer feedback and reviews of your auto repair shop.",
    icon: iconRequestFeedback,
  },
];

const ReputationManagementProduct = ({}) => {
  const { isTablet, isMobile } = useScreenSize();

  return (
    <>
      <UpperSection
        sectionName="Reputation Management"
        textTitle="Online reputation management for auto repair shops"
        description="Manage your online reputation within one platform. Request feedback from customers and receive alerts when a new review is received."
        clsLeft="reputationDistance"
        clsRight="reputationImgDistance"
        formClass="ReputationForm"
      >
        {isMobile ? (
          <StaticImage
            src="../../assets/images/ReputationManagement/hero-mobile.png"
            alt="driver"
            className={styles.heroMobile}
            loading="eager"
            quality={100}
            objectFit="contain"
          />
        ) : (
          <div className={styles.imgWrapper}>
            <DesktopTabletImages isTablet={isTablet} />
          </div>
        )}
      </UpperSection>
      <MiddleSection
        textTitle="Manage online reviews"
        description="With our Auto Repair Reputation Management Tool you can easily see how customers rate your shop and service."
        tools={REPUTATION_MANAGEMENT_TOOLS}
      >
        <div className={styles.manageReviews}></div>
      </MiddleSection>
    </>
  );
};

export default ReputationManagementProduct;

function DesktopTabletImages({ isTablet }) {
  return (
    <>
      <StaticImage
        src="../../assets/images/ReputationManagement/RatingBySite.png"
        alt="Rating By Site"
        className={styles.ratingBySite}
        loading="eager"
        quality={100}
      />
      <StaticImage
        src="../../assets/images/ReputationManagement/photo1.png"
        alt="driver"
        className={styles.photo1}
        loading="eager"
        quality={100}
        style={{
          position: "absolute",
        }}
      />
      {isTablet ? (
        <StaticImage
          src="../../assets/images/ReputationManagement/photo2-tablet.png"
          alt="car mechanic"
          className={styles.photo2}
          loading="eager"
          quality={100}
          style={{
            position: "absolute",
          }}
        />
      ) : (
        <StaticImage
          src="../../assets/images/ReputationManagement/photo2.png"
          alt="car mechanic"
          className={styles.photo2}
          loading="eager"
          quality={100}
          style={{
            position: "absolute",
          }}
        />
      )}
      {!isTablet && (
        <StaticImage
          src="../../assets/images/ReputationManagement/ratingByParam.png"
          alt="rating image"
          className={styles.ratingByParam}
          loading="eager"
          quality={100}
          style={{
            position: "absolute",
          }}
        />
      )}
      <StaticImage
        src="../../assets/images/ReputationManagement/ratingMA.png"
        alt="rating MA"
        className={styles.ratingMA}
        loading="eager"
        quality={100}
        style={{
          position: "absolute",
        }}
      />
      <StaticImage
        src="../../assets/images/ReputationManagement/starsRating.png"
        alt="rating stars"
        className={styles.starsRating}
        loading="eager"
        quality={100}
        style={{
          position: "absolute",
        }}
      />
      <div className={styles.hexagon}></div>
      <div className={styles.circle}></div>
      <div className={styles.rectangle}></div>
    </>
  );
}
