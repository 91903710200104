// extracted by mini-css-extract-plugin
export var card = "Card-module--card--1E9VT";
export var variantSecondary = "Card-module--variantSecondary--3pRPU";
export var variantAdditional = "Card-module--variantAdditional--1FmDw";
export var variantTimeline = "Card-module--variantTimeline--2XUzA";
export var variantServiceReminders = "Card-module--variantServiceReminders--3HNMV";
export var variantGoogleReserve = "Card-module--variantGoogleReserve--2BJpb";
export var variantCareers = "Card-module--variantCareers--2UO7-";
export var cardIcon = "Card-module--cardIcon--1kB_E";
export var variantPartner = "Card-module--variantPartner--B13A9";
export var textWrapper = "Card-module--textWrapper--tjSOG";
export var cardTitle = "Card-module--cardTitle--1J-fm";
export var cardDescription = "Card-module--cardDescription--1AvMH";
export var imgWrapper = "Card-module--imgWrapper--NLjBd";