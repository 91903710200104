import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import ReserveWithGooglePage from "../components/ReserveWithGoogle";

const ReserveWithGoogle: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return <ReserveWithGooglePage />;
};

export default ReserveWithGoogle;
