import React, { useEffect, useState, useRef } from "react";
import * as styles from "./EarlyAccessForm.module.scss";
import { validateEmail } from "../../../utils/validate";
import iconError from "../../../assets/icons/i-attention.svg";
import iconErrorActive from "../../../assets/icons/i-attention-active.svg";
import Tooltip from "../../Shared/Tooltip";
import SubscribePopup from "../../SubscribePopup";
import { invalidEmailMsg } from "../../SubscribePopup/SubscribeForm";
import { animateOnScroll } from "../../../services/animationService";
import {
  ANALYTICS_EVENTS,
  addAnalyticsEvent,
} from "../../../services/analyticsService";

const inputPlaceholders = {
  default: "Your email address",
  FooterForm: "Work email",
};

type EarlyAccessFormProps = {
  className: string;
  animated?: boolean;
  onCloseClick?(): void;
};

export const EarlyAccessForm = ({
  className = "",
  animated = false,
  onCloseClick,
}: EarlyAccessFormProps) => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [erroMsg, setErrorMsg] = useState("");
  const refHeroForm = useRef(null);

  useEffect(() => animated && animateOnScroll([refHeroForm.current]), []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setInvalid(false);
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!email || !validateEmail(email)) {
      setInvalid(true);
      setErrorMsg(email ? invalidEmailMsg : "Email field cannot be blank");
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setEmail("");
    onCloseClick && onCloseClick();
  };

  const handleCancel = () => {
    setEmail("");
  };

  useEffect(() => {
    if (document !== undefined) {
      if (open) {
        document.body.style.overflow = "hidden";
      } else document.body.style.overflow = "overlay";
    }
  }, [open]);

  return (
    <>
      <form
        className={`${styles.Form} ${className && styles[className]} ${
          animated && "animated"
        } ${invalid ? styles.formError : ""}`}
        onSubmit={handleSubmit}
        ref={refHeroForm}
      >
        <input
          className={styles.Input}
          placeholder={
            inputPlaceholders[className] || inputPlaceholders.default
          }
          type="email"
          value={email}
          onChange={handleChange}
          autoComplete="email"
          name="email"
        />
        {invalid && (
          <Tooltip
            className={styles.emailError}
            tooltip={erroMsg}
            icon={<img src={iconError} alt="error icon" />}
            hoverIcon={<img src={iconErrorActive} alt="error icon" />}
          />
        )}
        <div className={styles.cancelIcon} onClick={() => handleCancel()}></div>
        <button
          type="submit"
          className={styles.Btn}
          onClick={() => {
            addAnalyticsEvent(ANALYTICS_EVENTS.getEarlyAccessClick);
          }}
        >
          Get a Demo
        </button>
      </form>

      {open && <SubscribePopup userEmail={email} close={handleClose} />}
    </>
  );
};
