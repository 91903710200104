import React, { useEffect, useRef, useState } from "react";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import * as styles from "./Header.module.scss";
import iconArrowDown from "../../assets/icons/i-arrow-down.svg";
import iconArrowUp from "../../assets/icons/i-arrow-up-active.svg";
import logo from "../../assets/icons/Header/i-logo.svg";
import iconFacebook from "../../assets/icons/Header/i-header-facebook.svg";
import iconInstagram from "../../assets/icons/Header/i-header-instagram.svg";
import iconLinkedin from "../../assets/icons/Header/i-header-linkedin.svg";
import iconYoutube from "../../assets/icons/Header/i-header-youtube.svg";
import iconTwitter from "../../assets/icons/Header/i-header-twitter.svg";
import TextLink from "../Shared/TextLink";
import { HSplit, VSplit } from "../Shared/Divider";
import { Link } from "@reach/router";
import { PATHS, SOCIAL_MEDIA } from "../../constants";
import {
  iconCalendar,
  iconChat,
  iconEmail,
  iconEmailBlue,
  iconEmailDark,
  iconEmailMarketing,
  iconMenu,
  iconPhone,
  iconPostcard,
  iconReminder,
  iconStar,
  iconStatistics,
  iconTelematics,
  iconWebsiteChat,
  iconMenuClose,
  iconPhoneBlue,
  iconPhoneDark,
} from "../../assets/icons/Header";
import { useHeaderPosition } from "../../hooks";
import { animateOnScroll } from "../../services/animationService";
import {
  ANALYTICS_EVENTS,
  addAnalyticsEvent,
} from "../../services/analyticsService";
import SubscribePopup from "../SubscribePopup";

const steerLoginUrl = "https://app.steercrm.com";
const loggedInCookie = "Logged-In";

const Header = () => {
  const steerRef = useRef<HTMLDivElement>(null);
  const [fixed, dismissed] = useHeaderPosition();
  const [loggedIn, setLoggedIn] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [steerOpen, setSteerOpen] = useState(false);
  const [steerPositionTop, setSteerPositionTop] = useState<
    number | undefined
  >();
  const [steerPositionLeft, setSteerPositionLeft] = useState<
    number | undefined
  >();
  const [companyOpen, setCompanyOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const refHeader = useRef(null);

  useEffect(() => animateOnScroll([refHeader.current]), []);

  useEffect(() => {
    if (document !== undefined) {
      const cookie = document.cookie
        .split("; ")
        .find((cookie) => cookie.startsWith(`${loggedInCookie}=`))
        ?.split("=")[1];

      if (cookie === "true") {
        setLoggedIn(true);
      }
    }
  });

  const handleMenuOpen = () => {
    setCompanyOpen(false);
    setSteerOpen(false);
    setMenuOpen(!menuOpen);
    setAnimate(true);
  };
  const handleMenuClose = () => {
    setMenuOpen(false);
  };
  const handleSteerToggle = (e: any) => {
    if (menuOpen) {
      setCompanyOpen(false);
      setSteerOpen(!steerOpen);
    }
  };
  const handleSteerOpen = (e: any) => {
    if (!menuOpen) {
      setCompanyOpen(false);
      setSteerOpen(true);
      if (steerRef && steerRef.current) {
        const { top, height } = steerRef.current.getBoundingClientRect();
        setSteerPositionTop(top + height);
        const windowWidth = window.innerWidth;
        setSteerPositionLeft(windowWidth / 2);
      }
    }
  };
  const handleSteerClose = (e: any) => {
    if (!menuOpen) {
      setCompanyOpen(false);
      setSteerOpen(false);
      setSteerPositionTop(undefined);
    }
  };
  const handleCompanyToggle = (e: any) => {
    setSteerOpen(false);
    setCompanyOpen(!companyOpen);
  };
  const handleCompanyOpen = () => {
    if (!menuOpen) {
      setCompanyOpen(true);
    }
  };
  const handleCompanyClose = () => {
    setCompanyOpen(false);
  };
  const handleLinkClick = (e) => {
    handleSteerClose(e);
    handleCompanyClose();
    handleMenuClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        className={`${styles.headerWrap} ${fixed ? styles.fixed : ""} ${
          dismissed ? styles.dismissNav : ""
        }  ${
          menuOpen ? styles.headerOpen : animate ? styles.animateClose : ""
        }`}
      >
        <header className={`container ${styles.header}`} ref={refHeader}>
          <div className={styles.logos}>
            <Link className={styles.logo} to="/">
              <img src={logo} alt="logo" />
            </Link>
            <Link
              className={`${styles.logoMobile} ${menuOpen && styles.hidden}`}
              to="/"
            >
              <img src={logo} alt="logo" />
            </Link>
          </div>

          <div onClick={handleMenuOpen} className={`${styles.menuBtn}`}>
            <img
              className={`${menuOpen ? styles.menuBtnActive : styles.menuIcon}`}
              src={menuOpen ? iconMenuClose : iconMenu}
            />
          </div>

          <menu className={styles.menu}>
            <div className={styles.nav}>
              <div
                ref={steerRef}
                className={`${styles.dropdown} ${
                  steerOpen ? styles.dropdownOpen : styles.dropdownClose
                }`}
                onClick={handleSteerToggle}
                onMouseOver={handleSteerOpen}
                onMouseLeave={handleSteerClose}
              >
                <span className={styles.dropdownTitle}>
                  Why Steer?
                  <img
                    className={styles.arrowDown}
                    src={iconArrowDown}
                    alt="arrow down"
                  />
                  <img
                    className={styles.arrowUp}
                    src={iconArrowUp}
                    alt="arrow up"
                  />
                </span>
                {steerOpen && (
                  <>
                    <RemoveScrollBar gapMode="padding" />
                    <div
                      className={`${styles.dropdownPopup} ${styles.dropdownPopupSteer} 
                      `}
                      onMouseOver={handleSteerOpen}
                      onMouseLeave={handleSteerClose}
                      style={
                        steerPositionTop
                          ? {
                              top: fixed
                                ? steerPositionTop + 5
                                : steerPositionTop,
                              left: steerPositionLeft,
                            }
                          : {}
                      }
                    >
                      <div
                        className={`${styles.popupSection} ${styles.productsSection}`}
                      >
                        <h3 className={styles.sectionTitle}>Products</h3>
                        <HSplit className={styles.sectionDivider} />
                        <div className={styles.sectionContent}>
                          <Link
                            className={styles.dropdownLink}
                            to={PATHS.TEXT_MESSAGING}
                            onClick={handleLinkClick}
                          >
                            <img
                              className={styles.linkIcon}
                              src={iconChat}
                              alt="icon"
                            />
                            <div>
                              <h6 className={styles.linkTitle}>
                                Text Messaging
                              </h6>
                              <p className={styles.linkText}>
                                Get instantaneous responses and send automated
                                alerts with our easy-to-use text messaging
                                platform.
                              </p>
                            </div>
                          </Link>
                          <Link
                            className={styles.dropdownLink}
                            to={PATHS.SERVICE_REMINDERS}
                            onClick={handleLinkClick}
                          >
                            <img
                              className={styles.linkIcon}
                              src={iconReminder}
                              alt="icon"
                            />
                            <div>
                              <h6 className={styles.linkTitle}>
                                Service Reminders
                              </h6>
                              <p className={styles.linkText}>
                                Provide your customers with friendly reminders
                                when they are past due for a routine visit
                                and/or have a pending declined serviced.
                              </p>
                            </div>
                          </Link>
                          <Link
                            className={styles.dropdownLink}
                            to={PATHS.WEBSITE_CHAT}
                            onClick={handleLinkClick}
                          >
                            <img
                              className={styles.linkIcon}
                              src={iconWebsiteChat}
                              alt="icon"
                            />
                            <div>
                              <h6 className={styles.linkTitle}>Website Chat</h6>
                              <p className={styles.linkText}>
                                Give potential customers the opportunity to send
                                your business a text message directly from your
                                website.
                              </p>
                            </div>
                          </Link>
                          <Link
                            className={styles.dropdownLink}
                            to={PATHS.EMAIL_MARKETING}
                            onClick={handleLinkClick}
                          >
                            <img
                              className={styles.linkIcon}
                              src={iconEmailMarketing}
                              alt="icon"
                            />
                            <div>
                              <h6 className={styles.linkTitle}>
                                Email Marketing
                              </h6>
                              <p className={styles.linkText}>
                                Our email marketing service automates email
                                campaigns, notifications, and important service
                                reminders.
                              </p>
                            </div>
                          </Link>
                          <Link
                            className={styles.dropdownLink}
                            to={PATHS.REPUTATION_MANAGEMENT}
                            onClick={handleLinkClick}
                          >
                            <img
                              className={styles.linkIcon}
                              src={iconStar}
                              alt="icon"
                            />
                            <div>
                              <h6 className={styles.linkTitle}>
                                Reputation Management
                              </h6>
                              <p className={styles.linkText}>
                                Collect customer reviews on all the major review
                                websites and keep track of all the reviews you
                                are receiving.
                              </p>
                            </div>
                          </Link>
                          <Link
                            className={styles.dropdownLink}
                            to={PATHS.TELEMATICS}
                            onClick={handleLinkClick}
                          >
                            <img
                              className={styles.linkIcon}
                              src={iconTelematics}
                              alt="icon"
                            />
                            <div>
                              <h6 className={styles.linkTitle}>Telematics</h6>
                              <p className={styles.linkText}>
                                Provide commercial fleets with a remote
                                diagnostic service program and helped them
                                prevent unforeseen repairs.
                              </p>
                            </div>
                          </Link>
                          <Link
                            className={styles.dropdownLink}
                            to={PATHS.DIRECT_MAIL}
                            onClick={handleLinkClick}
                          >
                            <img
                              className={styles.linkIcon}
                              src={iconPostcard}
                              alt="icon"
                            />
                            <div>
                              <h6 className={styles.linkTitle}>
                                Direct Mail Postcards
                              </h6>
                              <p className={styles.linkText}>
                                Make your business standout with our
                                professional mailing service. Send high quality
                                postcards and letters with the click of a
                                button.
                              </p>
                            </div>
                          </Link>
                          <Link
                            className={styles.dropdownLink}
                            to={PATHS.ONLINE_SCHEDULING}
                            onClick={handleLinkClick}
                          >
                            <img
                              className={styles.linkIcon}
                              src={iconCalendar}
                              alt="icon"
                            />
                            <div>
                              <h6 className={styles.linkTitle}>
                                Online Scheduling
                              </h6>
                              <p className={styles.linkText}>
                                Our automotive appointment software allows
                                businesses to easily manage their customer
                                appointments and gives potential customers the
                                ability to schedule appointments online.
                              </p>
                            </div>
                          </Link>
                          <Link
                            className={`${styles.dropdownLink} ${styles.inactive}`}
                            to={PATHS.ADVANCED_REPORTING}
                            onClick={handleLinkClick}
                          >
                            <img
                              className={styles.linkIcon}
                              src={iconStatistics}
                              alt="icon"
                            />
                            <div>
                              <h6 className={styles.linkTitle}>
                                Advanced Reporting
                              </h6>
                              <p className={styles.linkText}>
                                Full performance tracking and customer insights
                                that will help improve your business.
                              </p>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className={styles.popupSection}>
                        <h3 className={styles.sectionTitle}>Additional</h3>
                        <HSplit className={styles.sectionDivider} />
                        <div className={styles.sectionContent}>
                          <div className={styles.linksCol}>
                            <Link
                              className={styles.dropdownLink}
                              to={PATHS.INTEGRATIONS}
                              onClick={handleLinkClick}
                            >
                              <div>
                                <h6 className={styles.linkTitle}>
                                  Shop Management Integrations
                                </h6>
                                <p className={styles.linkText}>
                                  Setup is easy. We integrate with most shop
                                  management systems.
                                </p>
                              </div>
                            </Link>
                            <Link
                              className={styles.dropdownLink}
                              to={PATHS.CUSTOMER_TESTIMONIALS}
                              onClick={handleLinkClick}
                            >
                              <div>
                                <h6 className={styles.linkTitle}>
                                  Customer Testimonials
                                </h6>
                                <p className={styles.linkText}>
                                  Read about experiences from real customers.
                                </p>
                              </div>
                            </Link>
                            <Link
                              className={styles.dropdownLink}
                              to={PATHS.RESERVE_WITH_GOOGLE}
                              onClick={handleLinkClick}
                            >
                              <div>
                                <h6 className={styles.linkTitle}>
                                  Reserve with Google
                                </h6>
                                <p className={styles.linkText}>
                                  Make your Google Business Profile stand out by
                                  allowing potential customers to book from
                                  Google.
                                </p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <TextLink
                className={styles.headerLink}
                text="Enterprise Solutions"
                to={PATHS.ENTERPRISE_SOLUTIONS}
                onClick={handleLinkClick}
              />
              <TextLink
                className={styles.headerLink}
                text="Pricing"
                to={PATHS.PRICING}
                onClick={handleLinkClick}
              />
              <div
                className={`${styles.dropdown} ${
                  companyOpen ? styles.dropdownOpen : styles.dropdownClose
                }`}
                onMouseOver={handleCompanyOpen}
              >
                <span
                  className={styles.dropdownTitle}
                  onClick={handleCompanyToggle}
                >
                  About
                  <img
                    className={styles.arrowDown}
                    src={iconArrowDown}
                    alt="arrow down"
                  />
                  <img
                    className={styles.arrowUp}
                    src={iconArrowUp}
                    alt="arrow up"
                  />
                </span>
                {companyOpen && (
                  <div
                    className={`${styles.dropdownPopup} ${styles.dropdownPopupCompany}`}
                    style={fixed ? { top: 110 + "%" } : {}}
                  >
                    <div className={styles.popupSection}>
                      <h3 className={styles.sectionTitle}>Overview</h3>
                      <HSplit className={styles.sectionDivider} />
                      <div className={styles.sectionContent}>
                        <div className={styles.linksCol}>
                          <Link
                            className={styles.dropdownLink}
                            to={PATHS.ABOUT_US}
                            onClick={handleLinkClick}
                          >
                            <div>
                              <h6 className={styles.linkTitle}>Our Story</h6>
                              <p className={styles.linkText}>
                                Learn about our company and the people that make
                                it great.
                              </p>
                            </div>
                          </Link>
                          <Link
                            className={styles.dropdownLink}
                            to={PATHS.PARTNERS}
                            onClick={handleLinkClick}
                          >
                            <div>
                              <h6 className={styles.linkTitle}>Partners</h6>
                              <p className={styles.linkText}>
                                We work with amazing companies that share our
                                vision of helping businesses.
                              </p>
                            </div>
                          </Link>
                          <Link
                            className={styles.dropdownLink}
                            to={PATHS.CAREERS_AT_STEER}
                            onClick={handleLinkClick}
                          >
                            <div>
                              <h6 className={styles.linkTitle}>Careers</h6>
                              <p className={styles.linkText}>
                                Become part of a passionate team that is
                                building a better future for auto repair.
                              </p>
                            </div>
                          </Link>
                          <Link
                            className={styles.dropdownLink}
                            to={PATHS.SUPPORT_REQUEST}
                            onClick={handleLinkClick}
                          >
                            <div>
                              <h6 className={styles.linkTitle}>
                                Support Request
                              </h6>
                              <p className={styles.linkText}>
                                Let us know how we can help.
                              </p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className={styles.popupSection}>
                      <h3 className={styles.sectionTitle}>Contact</h3>
                      <HSplit className={styles.sectionDivider} />
                      <div className={styles.sectionContent}>
                        <div className={styles.linksCol}>
                          <div className={styles.contactCard}>
                            <h3 className={styles.contactTitle}>Call Us</h3>
                            <div className={styles.contactBody}>
                              <img
                                src={iconPhone}
                                className={styles.iconPhone}
                                alt="icon phone"
                              />
                              <img
                                src={iconPhoneBlue}
                                className={styles.iconPhoneBlue}
                                alt="icon phone"
                              />
                              <img
                                src={iconPhoneDark}
                                className={styles.iconPhoneDark}
                                alt="icon phone"
                              />
                              <a
                                className={styles.contact}
                                href="tel:617-765-8187"
                              >
                                617-765-8187
                              </a>
                            </div>
                          </div>
                          <div className={styles.contactCard}>
                            <h3 className={styles.contactTitle}>Email Us</h3>
                            <div className={styles.contactBody}>
                              <img
                                className={styles.contactIconEmail}
                                src={iconEmail}
                                alt="icon email"
                              />
                              <img
                                className={styles.iconEmailBlue}
                                src={iconEmailBlue}
                                alt="icon email"
                              />
                              <img
                                className={styles.iconEmailDark}
                                src={iconEmailDark}
                                alt="icon email"
                              />
                              <a
                                className={styles.contact}
                                href="mailto:sales@steercrm.com"
                              >
                                sales@steercrm.com
                              </a>
                            </div>
                          </div>
                          <div className={styles.contactMedia}>
                            <a href={SOCIAL_MEDIA.FACEBOOK} target="_blank">
                              <img
                                className={styles.contactMediaItem}
                                src={iconFacebook}
                                alt="fb"
                              />
                            </a>
                            <a href={SOCIAL_MEDIA.INSTAGRAM} target="_blank">
                              <img
                                className={styles.contactMediaItem}
                                src={iconInstagram}
                                alt="ig"
                              />
                            </a>
                            <a href={SOCIAL_MEDIA.LINKEDIN} target="_blank">
                              <img
                                className={styles.contactMediaItem}
                                src={iconLinkedin}
                                alt="in"
                              />
                            </a>
                            <a href={SOCIAL_MEDIA.YOUTUBE} target="_blank">
                              <img
                                className={styles.contactMediaItem}
                                src={iconYoutube}
                                alt="yt"
                              />
                            </a>
                            <a href={SOCIAL_MEDIA.TWITTER} target="_blank">
                              <img
                                className={styles.contactMediaItem}
                                src={iconTwitter}
                                alt="t"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </menu>

          {loggedIn ? (
            <a href={steerLoginUrl} className={styles.accountLink}>
              Go to My Account
            </a>
          ) : (
            <div className={styles.unloggedwrapper}>
              <VSplit className={styles.divider} />
              <a href={steerLoginUrl} className={styles.loginBtn}>
                Login
              </a>
              <button
                className={styles.demoBtn}
                onClick={() => {
                  addAnalyticsEvent(ANALYTICS_EVENTS.getEarlyAccessClick);
                  setOpen(true);
                }}
              >
                Get a Demo
              </button>
            </div>
          )}
          <div className={styles.socialMediaFooter}>
            <a href={SOCIAL_MEDIA.FACEBOOK} target="_blank">
              <img
                className={styles.socialMediaItem}
                src={iconFacebook}
                alt="fb"
              />
            </a>
            <a href={SOCIAL_MEDIA.INSTAGRAM} target="_blank">
              <img
                className={styles.socialMediaItem}
                src={iconInstagram}
                alt="ig"
              />
            </a>
            <a href={SOCIAL_MEDIA.LINKEDIN} target="_blank">
              <img
                className={styles.socialMediaItem}
                src={iconLinkedin}
                alt="in"
              />
            </a>
            <a href={SOCIAL_MEDIA.YOUTUBE} target="_blank">
              <img
                className={styles.socialMediaItem}
                src={iconYoutube}
                alt="yt"
              />
            </a>
            <a href={SOCIAL_MEDIA.TWITTER} target="_blank">
              <img
                className={styles.socialMediaItem}
                src={iconTwitter}
                alt="t"
              />
            </a>
          </div>
          <div className={styles.footerBackground}></div>
        </header>
      </div>

      {open && (
        <>
          <RemoveScrollBar gapMode="padding" />
          <SubscribePopup close={handleClose} />
        </>
      )}
    </>
  );
};

export default Header;
