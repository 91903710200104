import React from "react";
import * as styles from "./SteermaticsTermsAndConditions.module.scss";

const TERMS_AND_CONDITIONS = [
  <div className={styles.term}>
    <div className={styles.title}></div>
    <p className={styles.description}>
      IMPORTANT NOTICE: THIS AGREEMENT IS SUBJECT TO BINDING ARBITRATION AND A
      WAIVER OF CLASS ACTIONS, AS DESCRIBED IN SECTION 12. Please also read the
      limitations and disclaimers in Sections 9 and 10 regarding your use of the
      Mechanic Advisor device and services.
    </p>
  </div>,
  <div className={styles.term}>
    <div className={styles.title}>
      1. Your Acknowledgment and Acceptance of This Agreement
    </div>
    <p className={styles.description}>
      These Steer by Mechanic Advisor Terms and Conditions (“Terms”) form a
      binding agreement between you and Mechanic Advisor, Inc. (“Mechanic
      Advisor”, “we” or “us”) that governs your use of the Services (as defined
      below). By installing a Mechanic Advisor approved Device, agreeing to our
      terms of service, or using the service, you acknowledge that you have
      read, understood and agreed to be bound by these Terms. If you do not
      agree to these Terms, you may not use the Device or Services. These Terms
      apply to your use of the Device and Services; your use of Mechanic
      Advisor’s website on a separate, stand-alone basis is subject to Mechanic
      Advisor’s Terms of Use, which can be found at:
      steercrm.com/terms-and-conditions.
    </p>
    <p className={styles.description}>
      These Terms may be changed, modified, supplemented, or updated by Mechanic
      Advisor from time to time without advance notice via: (i) by electronic
      mail, or (ii) by posting a notice on the Mechanic Advisor website, which
      is located at steercrm.com. You will be bound by any such changed,
      modified, supplemented, or updated Terms if you continue to use the Device
      or Services after such changes are posted or otherwise identified to you.
      Unless otherwise indicated, any new content, products, services, or
      functionality added to the Devices or Services will also be subject to
      these Terms effective upon the date of their addition. You are encouraged
      to review these Terms periodically for updates and changes.
    </p>
    <p className={styles.description}>
      If you have any questions about these Terms, please email us at
      support@vin.li or write to us at Mechanic Advisor, 840 Summer St. STE 102
      Boston, MA 02127 USA, Attention: Legal.
    </p>
  </div>,
  <div className={styles.term}>
    <div className={styles.title}>
      2. Description of the Device and Services
    </div>
    <p className={styles.description}>
      The “Device” means an OBD-II data device which provides the Services. The
      “Services” include: (a) Mechanic Advisor’s applications, including their
      associated media, online, electronic and printed documentation, and
      updates and upgrades that replace or supplement the Mechanic Advisor
      software (that are not distributed under different terms); (b) third party
      applications provided via the Mechanic Advisor, (c) any software embedded
      in the Device; (d) the Network Service, as defined in the Section entitled
      “Network Service”; and (e) all other services, information, data, reports,
      images, text, sounds, video and other content made available through (a)
      and (d).
    </p>
  </div>,
  <div className={styles.term}>
    <div className={styles.title}>3. License and Restrictions</div>
    <p className={styles.description}>
      3.1 Grant of License. Upon installation of the Device and using the
      Services and subject to your payment of all applicable fees, Mechanic
      Advisor grants to you a limited, revocable, non-exclusive,
      non-transferable, non-sublicensable license to (a) install and use the
      Device in a vehicle that is owned or controlled by you, and (b) install
      and use the Services that you own or control and on which the Services are
      permitted to operate (as determined by Mechanic Advisor), with both (a)
      and (b) solely as permitted by applicable law and these Terms (the
      “License”).
    </p>
    <p className={styles.description}>
      3.2 The License does not allow you to install a Device in any vehicle that
      you do not own or control. The License does not allow you to use the
      Services on any device that you do not own or control, and you may not
      distribute or make the Services available over a network where they could
      be used by multiple devices at the same time. You may not rent, lease,
      lend, sell, redistribute, or sublicense the Services. You may not use the
      Device or Services in any unlawful way (including, without limitation, in
      violation of any export, data or privacy laws), or in any way that
      interferes with the integrity or operation of the Device or Services. You
      may not copy, decompile, reverse engineer, disassemble, attempt to derive
      the source code of, modify, adapt, hack, or create derivative works of, or
      attempt to gain unauthorized access to or disrupt proper performance of,
      the Device or Services, any updates, or any components of the Device or
      Services. You may not use the Device or Services other than as intended,
      including, without limitation, attempting to gain information or data
      through means or channels not intentionally made available to you by
      Mechanic Advisor or provided for through the Service or Device. Any
      attempt to do any of the actions in listed above is a violation of the
      rights of Mechanic Advisor and its licensors. If you breach the License,
      you may be subject to civil and criminal liability. The terms of the
      License will govern any upgrades provided by Mechanic Advisor that replace
      or supplement the original Services, unless such upgrade is accompanied by
      a separate license in which case the terms of that license will govern.
      You shall promptly notify Mechanic Advisor if you learn of a security
      breach related to the Device or Services.
    </p>
    <p className={styles.description}>
      3.3 Reservation of Mechanic Advisor’s Rights. You acknowledge that you
      will not use Device or Services for any other purpose than permitted under
      these Terms. You also acknowledge that Mechanic Advisor and its licensors
      retain all ownership and intellectual property rights in and to the Device
      and Services.
    </p>
    <p className={styles.description}>
      3.4 Termination. The License is effective until terminated by you or
      Mechanic Advisor. Your rights under the License will terminate immediately
      without notice from Mechanic Advisor if you fail to comply with the Terms
      or upon termination of these Terms by Mechanic Advisor or you. Upon
      termination of the License, you shall cease all use of the Services and
      Device, and permanently delete all copies of the Services, including the
      Mechanic Advisor Software. Any termination will not limit any of Mechanic
      Advisor’s other rights or remedies at law or in equity. The provisions of
      Sections (including subsections): 3.4, 4, 5, and 7-13 shall survive
      termination or expiration of these Terms for any reason.
    </p>
  </div>,
  <div className={styles.term}>
    <div className={styles.title}>4. Consent to Use of Data</div>
    <p className={styles.description}>
      4.1 Collection of Data. You agree that, through your use of the Device or
      Services, Mechanic Advisor may collect and use data and information from
      the Device, Services, and your vehicle. That information can include but
      is not limited to technical information about your vehicle and
      location/GPS information about your vehicle and vehicle usage, and other
      related services associated to the business of Mechanic Advisor in
      connection with your use of the Device or Services. Mechanic Advisor will
      also collect personal information from you during the registration,
      including but not limited to, your name, payment information, and
      information about your vehicle. The Device and the Services communicate
      via the internet with Mechanic Advisor and third-party servers; as such,
      standard data transmission rates may apply. Mechanic Advisor may make
      routine software updates without informing you to keep the Services and
      Device working properly.
    </p>
    <p className={styles.description}>
      4.2 Privacy Policy. Mechanic Advisor will use and share the information it
      collects in connection with its business in compliance with Mechanic
      Advisor’s Privacy Policy located at: steercrm.com/privacy-policy. This
      Privacy Policy is hereby incorporated by reference into these Terms and
      you should be read it carefully.
    </p>
    <p className={styles.description}>
      4.3 Security Not Guaranteed. Mechanic Advisor uses reasonable security
      measures to protect the Device and secure the data we receive via the
      Services and Device. However, Mechanic Advisor cannot guarantee that third
      parties will never defeat our security measures. You acknowledge that your
      data might be subject to unauthorized access and, as such, you are using
      the Services and Device at your risk.
    </p>
  </div>,
  <div className={styles.term}>
    <div className={styles.title}>5. Network Service</div>
    <p className={styles.description}>
      5.1 Carrier; Network Service. Mechanic Advisor makes wireless connectivity
      and certain data services available to you through different third-party
      carriers depending on your jurisdiction and the carrier You choose during
      the Device registration process (each, a “Carrier”). The “Network Service”
      means the use of the Carrier’s wireless network or other successor
      technologies or wireless systems, or any combination thereof, and other
      equipment utilized by the Carrier to provide the Network Service, as the
      Carrier may amend at any time without prior notice. The exact definition
      of Network Service may vary depending on the applicable mandatory terms,
      as described in Section 5.2 below.
    </p>
    <p className={styles.description}>
      5.2 Mandatory Terms. Each Carrier has certain mandatory terms that apply
      to your use of the Device and Services, including the Network Service,
      which are described in Attachment A (Network Service Terms). For example,
      if you elect to purchase additional features for your Device from Carrier
      “X,” the mandatory terms applicable to Carrier “X” in Attachment A will
      apply to your use of the Device and Services. Attachment A is attached to,
      and hereby incorporated by reference into, these Terms.
    </p>
  </div>,
  <div className={styles.term}>
    <div className={styles.title}>6. Fees</div>
    <p className={styles.description}>
      To the extent the Services, Device, or any portion thereof is made
      available to you for a fee, you will be required to select a subscription
      or other payment plan and provide Mechanic Advisor with your credit card
      information or other payment method. You agree to pay all such fees
      promptly when due, and Mechanic Advisor will bill you for recurring
      charges in advance of service. You represent and warrant that the payment
      information you provide is correct and complete, and that you will update
      it regularly so as to maintain its accuracy. If we are unable to obtain
      payment from you using the payment method and information that you have
      provided, you acknowledge that we may suspend or terminate your account
      and seek payment from you of any overdue amounts by a means acceptable to
      us. We may charge you a reactivation fee if you wish to reinstate your
      account and access to the Services, in addition to any past due amounts.
    </p>
  </div>,
  <div className={styles.term}>
    <div className={styles.title}>7. Your Conduct and Content</div>
    <p className={styles.description}>
      7.1 Your Content. You are solely responsible for all data (including, but
      not limited to, personal and location data), feedback, suggestions,
      information, text, images, content, videos, and other materials that you
      upload, post, deliver, transmit or otherwise provide or store
      (collectively, “upload(ing)”) in connection with or relating to the Device
      or Services (“Your Content”). Mechanic Advisor has the right, but not the
      obligation, to monitor and remove – in Mechanic Advisor’s sole discretion
      – any content, including Your Content, uploaded on or through the
      Services.
    </p>
    <p className={styles.description}>
      7.2 License to Your Content. By uploading Your Content on or through the
      Services, you hereby grant Mechanic Advisor a non-exclusive, perpetual,
      irrevocable, royalty-free, fully paid-up, worldwide, sublicensable and
      transferable right and license to use, modify, reproduce, distribute,
      display, copy, publish and perform Your Content consistent with the
      Privacy Policy and in connection with the Device and Services and Mechanic
      Advisor’s conduct of its business. You represent, warrant and covenant
      that Your Content is not confidential or proprietary, that you have rights
      to submit Your Content, and Mechanic Advisor may use Your Content for any
      purpose (consistent with the Privacy Policy) without further
      consideration, consent or notice to you or any third party.
    </p>
    <p className={styles.description}>
      7.3 Your Account. You are responsible for maintaining the privacy of your
      Mechanic Advisor account information, including but not limited to your
      password and login credentials. You are responsible for all activities
      that occur under your account –even if your account information was
      obtained or used by a third party without your permission.
    </p>
    <p className={styles.description}>
      7.4 Your Conduct. Your use of the Device and Services is subject to all
      applicable laws and regulations, and you are solely responsible for the
      legality of Your Content and the information contained in your
      communications through the Device and Services. You agree that your use of
      the Device and Services, and your communications through them, will: Not
      be unlawful, threatening, abusive, harassing, libelous, defamatory,
      fraudulent, deceptive, in violation of a third party’s privacy or contract
      rights, tortious, pornographic, contain graphic descriptions of violence,
      impersonate any person or entity, or otherwise violate Mechanic Advisor’s
      rules and policies; Not degrade, threaten harass or intimidate any
      individual or group of individuals on the basis of sex, gender, sexual
      orientation, race, ethnicity, national origin, age, or disability; Not
      infringe any third party’s proprietary rights, including but not limited
      to patent, trademark, trade secret, copyright, moral or author’s rights,
      right of publicity or other intellectual property or proprietary right of
      any third party; Not constitute any form of unauthorized solicitation,
      including but not limited to spam, bulk email or text messaging, any form
      of gambling or lottery, or promotions or advertising; and Not contain any
      computer software, code, programs or files designed to interrupt, limit,
      damage, disrupt, alter or redirect any software, hardware,
      telecommunications equipment or network traffic, or to damage or obtain
      unauthorized access to any data or other information of any third party.
    </p>
    <p className={styles.description}>
      7.5 Your Service. Mechanic Advisor reserves the right to deactivate your
      Device and discontinue your access to and use of the Services without
      notice if you have not used the Device or the Services in the past twelve
      (12) months.
    </p>
    <p className={styles.description}>
      7.6 Security. You may not use the Device, Services or your Mechanic
      Advisor account to breach or attempt to breach the security of any
      network, server, equipment (including but not limited to a vehicle), or
      server. You shall not violate or interfere with anyone else’s use and
      enjoyment of the Services or other similar services. Users who violate
      systems or network security may face civil and criminal liability.
    </p>
    <p className={styles.description}>
      7.7 Mechanic Advisor, through the Device and Services, will provide access
      to, and make available to you, third party mobile applications, services,
      websites, or other third-party content (collectively the “Third Party
      Services”). The Third-Party Services are provided to you for your
      convenience, and Mechanic Advisor takes no responsibility for, makes no
      warranty regarding, and does not endorse any such Third-Party Services.
      Your use of the Third-Party Services is completely at your own risk.
    </p>
  </div>,
  <div className={styles.term}>
    <div className={styles.title}>
      8. DRIVING SAFETY AND LIABILITY DISCLAIMERS
    </div>
    <p className={styles.description}>
      *The Device and Services are operable even while your vehicle is in
      motion. It is very important that you and others ALWAYS DRIVE VIGILANTLY
      ACCORDING TO ROAD CONDITIONS AND IN ACCORDANCE WITH TRAFFIC LAWS,
      INCLUDING BUT NOT LIMITED TO LAWS REGARDING OPERATION OF MOBILE DEVICES
      WHILE DRIVING. The information provided by the Device and Services is not
      intended to replace the information provided to you on the road (for
      example, traffic signs, lane closures, police instructions) or through
      operation of your vehicle. ALWAYS DRIVE SAFELY AND DO NOT RELY ON THE
      DEVICE OR SERVICES TO PROVIDE ACCURATE INFORMATION REGARDING DIRECTIONS,
      ROAD CONDITIONS OR DRIVING HAZARDS. YOU ACKNOWLEDGE AND AGREE THAT, UNDER
      NO CIRCUMSTANCE, WILL MECHANIC ADVISOR BE RESPONSIBLE FOR ANY ACCIDENT,
      DAMAGE, LIABILITY, BODILY INJURY OR DEATH THAT MIGHT OCCUR TO YOU, YOUR
      PROPERTY, THIRD PARTIES OR THEIR PROPERTY, EVEN IF THE DEVICE OR SERVICES
      MAY HAVE OR DID CONTRIBUTE TO THE CAUSE OF THE ACCIDENT, DAMAGE,
      LIABILITY, BODILY INJURY OR DEATH.*
    </p>
  </div>,
  <div className={styles.term}>
    <div className={styles.title}>9. Limited Warranty</div>
    <p className={styles.description}>
      9.1 Limited Device Warranty. This limited warranty extends from Mechanic
      Advisor to you, if you were the original purchaser of the Device. Devices
      provided by Mechanic Advisor are warranted to be free from defects in
      materials or workmanship under normal use in a compatible vehicle (a) for
      new Devices, for one year from the date of original purchase, and (b) for
      repaired, replaced or refurbished Devices, (i) 90 days after the date the
      repaired, replaced or refurbished Device is shipped by Mechanic Advisor to
      you, or (ii) the remainder of the one year period for new Devices,
      whichever period is longer (as applicable, the “Warranty Period”). Within
      the Warranty Period, Mechanic Advisor will – at its sole option and as its
      sole liability and as your exclusive remedy – (A) repair the Device with
      new or refurbished parts, (B) replace the Device with a reasonably
      available equivalent new or refurbished Device, or (C) refund to you the
      amount you paid for the Device, less any rebates and discounts. Mechanic
      Advisor’s repair or replacement of the Device will be at no charge to you
      for parts or labor; however, you are responsible for paying the shipping
      costs and handling costs to return the Device to Mechanic Advisor. All
      replaced Devices and parts become the property of Mechanic Advisor upon
      Mechanic Advisor’s receipt. This limited warranty extends only to Devices
      purchased from Mechanic Advisor or a Mechanic Advisor-authorized reseller.
      This limited warranty does not apply to: (1) damage that is merely
      cosmetic, such as nicks, scratches, or inconsistencies in color and
      texture, (2) damage caused by unusual physical or electrical stress,
      negligence, accident, abuse, misuse, water damage, flood, fire, defects in
      your vehicle, or other natural disasters or external causes, (3) damaged
      caused by service or repair by someone who is not authorized by Mechanic
      Advisor to repair or service Devices, (4) damage caused by unauthorized
      alteration or modification the Device, (5) Devices that have not been
      installed, operated or maintained in accordance with the documentation and
      instructions supplied by Mechanic Advisor and these Terms, (6) Devices
      provided for beta-testing, evaluation, demonstration or development
      purposes and for which Mechanic Advisor did not charge a fee, (7) Devices
      used or obtained in violation of applicable law.
    </p>
    <p className={styles.description}>
      9.2 Submitting a Warranty Claim. To submit a Device warranty claim,
      contact support@vin.li or write to us at Mechanic Advisor, 2203 Commerce
      St., Dallas, Massachusetts 75201 USA, Attention: Customer Service, and we
      will provide further instructions on how to submit your warranty claim.
      You will be responsible for following any instructions provided. Defective
      Devices covered by the limited warranty described above will be repaired
      or replaced and returned to you without charge, or, at Mechanic Advisor’s
      discretion, the Mechanic Advisor will refund to you the amount you paid
      for the Device, less any rebates and discounts. However, Mechanic Advisor
      will not ship replacement Devices to any country or territory in which the
      original Device was not purchased. Mechanic Advisor will notify you if any
      repairs or replacements are not covered by the limited warranty, and you
      may choose to have Mechanic Advisor repair or replace the Device at
      Mechanic Advisor’s then-current service rates, or return your Device to
      you at your cost.
    </p>
    <p className={styles.description}>
      9.3 WARRANTY DISCLAIMER. EXCEPT FOR THE DEVICE LIMITED WARRANTY PROVIDED
      ABOVE, YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE DEVICE AND
      SERVICES IS AT YOUR SOLE RISK AND THAT THE ENTIRE RISK AS TO SATISFACTORY
      QUALITY, PERFORMANCE, ACCURACY AND EFFORT IS WITH YOU. TO THE MAXIMUM
      EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICES AND DEVICE ARE PROVIDED
      “AS IS” AND “AS AVAILABLE”, WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY
      KIND, AND MECHANIC ADVISOR HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS
      WITH RESPECT TO THE DEVICE AND SERVICES, WHETHER EXPRESS, IMPLIED OR
      STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND/OR
      CONDITIONS OF MERCHANTABILITY, TITLE, SATISFACTORY QUALITY, RELIABILITY,
      FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, QUIET ENJOYMENT, AND
      NON-INFRINGEMENT OF THIRD PARTY RIGHTS. MECHANIC ADVISOR DOES NOT WARRANT
      AGAINST INTERFERENCE WITH YOUR ENJOYMENT OF THE DEVICE OR SERVICES, THAT
      THE FUNCTIONS CONTAINED IN, OR SERVICES PERFORMED OR PROVIDED BY, THE
      DEVICE OR SERVICES WILL MEET YOUR REQUIREMENTS, THAT THE OPERATION OF THE
      DEVICE OR SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT DEFECTS IN
      THE DEVICE OR SERVICES WILL BE CORRECTED. NO ORAL OR WRITTEN INFORMATION
      OR ADVICE GIVEN BY MECHANIC ADVISOR OR ITS AUTHORIZED REPRESENTATIVES
      SHALL CREATE A WARRANTY. Through your use of the Device and Services, you
      may be able to undertake transactions with third parties, including but
      not limited to fleet management services, emergency service providers, and
      other vendors. You understand and acknowledge that all transactions
      relating to any services or products offered by a third party are solely
      between you and the third party vendor. MECHANIC ADVISOR MAKES NO WARRANTY
      REGARDING TRANSACTIONS ENTERED INTO BETWEEN YOU AND A THIRD PARTY IN
      CONNECTION WITH THE DEVICE OR SERVICES, AND YOU UNDERSTAND AND AGREE THAT
      ALL SUCH THIRD PARTY TRANSACTIONS ARE ENTIRELY AT YOUR OWN RISK. Any
      warranty regarding any products or services made available to you from a
      third party is provided – if at all – by that third party and not by
      Mechanic Advisor.
    </p>
    <p className={styles.description}>
      YOU UNDERSTAND, ACKNOWLEDGE AND AGREE THAT THE DEVICE AND SERVICES MAY NOT
      BE ABLE TO DETECT EVERY CRASH OR SUCCESSFULLY NOTIFY EMERGENCY RESPONSE
      SERVICES OF A CRASH. MECHANIC ADVISOR IS NOT LIABLE FOR (A) YOUR ACTION OR
      INACTION USING THE DEVICE OR SERVICES, (B) YOUR OPERATION OF, OR FAILURE
      TO PROPERLY OPERATE, YOUR VEHICLE, (C) THE ACTION OR INACTION OF ANY
      EMERGENCY SERVICE PROVIDERS OR OTHER THIRD PARTIES THAT THE SERVICES ARE
      INTENDED TO NOTIFY IN THE EVENT OF AN EMERGENCY, OR (D) THE INABILITY OF
      THE DEVICE OR SERVICES TO NOTIFY EMERGENCY SERVICE PROVIDERS OR OTHER
      THIRD PARTIES. MECHANIC ADVISOR DOES NOT WARRANT THAT YOUR USE OF THE
      DEVICE AND SERVICES WILL RESULT IN ANY SAVINGS OR EFFICIENCIES IN THE
      OPERATION OF YOUR VEHICLE OR THAT THE DETECTION OF MECHANICAL OR TECHNICAL
      ERRORS IN YOUR VEHICLE WILL BE ACCURATE OR ERROR-FREE. THE DEVICE AND
      SERVICES ARE NOT INTENDED TO REPLACE THE ADVICE, GUIDANCE OR DIAGNOSIS OF
      LICENSED MECHANICS OR PROPER VEHICLE MAINTENANCE. YOU ARE SOLELY
      RESPONSIBLE FOR THE OPERATION OF YOUR VEHICLE – INCLUDING WHILE USING THE
      DEVICE AND SERVICES.
    </p>
    <p className={styles.description}>
      Some jurisdictions do not allow the exclusion of implied warranties or
      limitations on applicable statutory rights of a consumer, so the above
      exclusion and limitations may not apply to you.
    </p>
  </div>,
  <div className={styles.term}>
    <div className={styles.title}>10. Limitation of Liability</div>
    <p className={styles.description}>
      10.1 Limitation of Damage Types. EXCEPT AS PROVIDED IN ATTACHMENT A
      (NETWORK SERVICE TERMS) AND TO THE EXTENT NOT PROHIBITED BY LAW, IN NO
      EVENT SHALL MECHANIC ADVISOR BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
      INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES
      WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES RESULTING FROM DEATH,
      PERSONAL INJURY, PROPERTY DAMAGE (INCLUDING, BUT NOT LIMITED TO, DAMAGE TO
      VEHICLES AND OTHER PERSONAL PROPERTY), ACTION OR INACTION OF EMERGENCY
      SERVICE PROVIDERS OR OTHER THIRD PARTY SERVICE PROVIDERS, YOUR USE OF OR
      INABILITY TO USE THIRD PARTY SERVICES, VIOLATION OF YOUR VEHICLE’S
      WARRANTY, UNAUTHORIZED ACCESS TO YOUR MECHANIC ADVISOR ACCOUNT OR TO YOUR
      DEVICE OR THE SERVICES, LOSS OF PROFITS OR INCOME, LOSS OF DATA, BUSINESS
      INTERRUPTION OR ANY OTHER DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO
      YOUR USE OF, OR INABILITY TO USE, THE DEVICE OR SERVICES, HOWEVER CAUSED,
      REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, TORT OR OTHERWISE) AND
      EVEN IF MECHANIC ADVISOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
      DAMAGES. Some jurisdictions do not allow the limitation of liability for
      personal injury, or of incidental or consequential damages, so this
      limitation may not apply to you.
    </p>
    <p className={styles.description}>
      10.2 Limitation of Liability. EXCEPT AS PROVIDED IN ATTACHMENT A**
      (NETWORK SERVICE TERMS), In no event shall MECHANIC ADVISOR’S total
      liability to you FOR DIRECT DAMAGES AND for all OTHER damages ARISING OUT
      OF OR RELATED TO THE DEVICE OR THE SERVICES exceed THE FEES PAID BY YOU TO
      MECHANIC ADVISOR IN THE THREE MONTHS PRECEDING THE EVENT GIVING RISE TO
      YOUR CLAIM, OR IF NO FEES WERE PAID, ONE HUNDRED U.S. DOLLARS ($100.00).
      The foregoing limitations will apply even if the above stated remedy fails
      of its essential purpose.
    </p>
  </div>,
  <div className={styles.term}>
    <div className={styles.title}>11. Indemnification</div>
    <p className={styles.description}>
      11.1 Indemnification. You agree to fully indemnify, defend and hold
      harmless Mechanic Advisor and its affiliates, and its and their
      subsidiaries, predecessors, successors, officers, directors, employees,
      agents, licensors, suppliers and clients from and against any and all
      claims, losses, costs (including court costs), fees (including reasonable
      legal fees), damages and expenses that the indemnified parties may incur
      as a result of, due to, or arising out of (a) your use of the Device or
      Services, (b) a violation by you (or anyone acting under your account or
      password) of these Terms, or (c) any claim alleging that Your Content or
      any communications, information or materials you submit to Mechanic
      Advisor through the Services, Device or by any other means violates a
      third party’s intellectual property rights.
    </p>
  </div>,
  <div className={styles.term}>
    <div className={styles.title}>
      12. Dispute Resolution by Binding Arbitration; Choice of Law.
    </div>
    <p className={styles.description}>
      PLEASE READ THIS SECTION CAREFULLY. WE WANT YOU TO KNOW HOW IT AFFECTS
      YOUR RIGHTS.
    </p>
    <p className={styles.description}>
      12.1 Customer Service and FAQs. At Mechanic Advisor, we expect that our
      customer service team will be able to quickly and satisfactorily resolve
      most concerns you may have regarding Mechanic Advisor, the Device or the
      Services by contacting Mechanic Advisor customer service at
      support@steercrm.com. In the unlikely event that an issue, concern or
      claim (including legal claims) remains unresolved, you and Mechanic
      Advisor each agree to discuss any issue informally for at least 30 days.
      You can do that by sending your full name and contact information, your
      concern and your requested solution to Mechanic Advisor at Mechanic
      Advisor, 840 Summer St STE 102., Boston, MA 02127 USA, Attention: Legal
      Department. If we would like to discuss an issue with you, we will email
      you at the email address you used to register your device.
    </p>
    <p className={styles.description}>
      12.2 Agreement to Arbitrate. If we do not reach an agreed solution after
      the 30-day discussion period, you and Mechanic Advisor each agree that any
      issue, concern or claim (including legal claims) that either of us may
      have arising out of or relating to these Terms (including formation,
      performance, or breach of them), our relationship with each other, or use
      of the Device or Services, must be resolved through final binding
      arbitration before the American Arbitration Association using its Consumer
      Arbitration Rules, available here (the “AAA Rules”) or available by
      calling the American Arbitration Association at 1-800-778-7879. As an
      exception to this agreement to arbitrate, Mechanic Advisor is happy to
      give you the right to pursue in small claims court any claim that is
      within that court’s jurisdiction, so long as you proceed solely on an
      individual basis. A party who intends to seek arbitration must first send
      to the other party, by certified or registered mail, a written notice of
      dispute. You may download or copy a form notice of dispute and a form to
      initiate arbitration from www.adr.org. Unless you and Mechanic Advisor
      agree otherwise, the arbitration hearings will take place in a reasonably
      convenient location for both parties as determined by the arbitrator in
      accordance with the AAA Rules, and the hearings may be conducted
      in-person, by videoconference, phone or by the exchange of documents. The
      arbitrator’s decision will be binding and may be enforced and entered as a
      judgment in any court of competent jurisdiction. If the arbitrator rules
      against Mechanic Advisor, we think it’s fair that Mechanic Advisor
      reimburse you for your reasonable attorneys’ fees and costs, regardless of
      who initiated the arbitration. However, if the arbitrator rules in
      Mechanic Advisor’s favor, Mechanic Advisor will not seek reimbursement of
      our attorneys’ fees and costs, regardless of who initiated the
      arbitration.
    </p>
    <p className={styles.description}>
      12.3 Time Limitation; No Class Actions. To help resolve any issues between
      us promptly and directly, you and Mechanic Advisor agree to begin any
      arbitration under these Terms within one year after the claim first arose;
      otherwise, the claim is waived. You and Mechanic Advisor also agree to
      arbitrate in each of our individual capacities only – not as a
      representative or member of a class – and each of us expressly waives any
      right to file a class action or seek relief on a class action basis.
      Furthermore, unless you and Mechanic Advisor agree in writing, the
      arbitrator may not consolidate more than one person’s claims, and may not
      otherwise preside over any form of a representative of class proceeding.
    </p>
    <p className={styles.description}>
      12.4 Confidentiality. All arbitration proceedings are confidential, unless
      both you and Mechanic Advisor agree otherwise. Arbitration orders and
      awards required to be filed with applicable courts of competent
      jurisdiction are not confidential and may be disclosed by the parties to
      such courts. A party who improperly discloses confidential information
      shall be subject to sanctions. The arbitrator and forum may disclose case
      filings, case dispositions, and other case information as required by a
      court order of proper jurisdiction.
    </p>
  </div>,
  <div className={styles.term}>
    <div className={styles.title}>13. Miscellaneous.</div>
    <p className={styles.description}>
      13.1 International Use. Mechanic Advisor’s goal is to bring you the Device
      and Services to many different countries; however, you may only use the
      Device and Services in jurisdictions where use has been specifically
      authorized by Mechanic Advisor.
    </p>
    <p className={styles.description}>
      13.2 U.S. Government License Rights. Devices and Services provided to the
      U.S. Government pursuant to solicitations issued on or after December 1,
      1995 are provided with the commercial license rights and restrictions
      described in these Terms. All Devices and Services provided to the U.S.
      Government pursuant to solicitations issued prior to December 1, 1995 are
      provided with “Restricted Rights” as provided for in FAR, 48 CFR 52.227
      -14 (JUNE 1987) or DFAR, 48 CFR 252.227-7013 (OCT 1988), as applicable.
    </p>
    <p className={styles.description}>
      13.3 Export Restrictions. You acknowledge that the Device and Services are
      subject to U.S. export control laws. You agree to comply with all
      applicable international and national laws that apply to the Device and
      Services, including the U.S. Export Administration Regulations, as well as
      end-user, end-use, and destination restrictions issued by U.S. and other
      governments.
    </p>
    <p className={styles.description}>
      13.4 Complete Agreement. The Terms, and any additional referenced terms
      therein (including Attachment A (Network Service Terms), constitute the
      complete, final and exclusive understanding between you and Mechanic
      Advisor relating to the subject matter of these Terms, and governs your
      use of the Devices and Services. These Terms supersede all prior or
      contemporaneous understandings, agreements and communications between you
      and Mechanic Advisor with respect to the subject matter of these Terms.
      Mechanic Advisor reserves the right to modify these Terms at any time
      without prior notice in accordance with Section 1 of these Terms.
    </p>
    <p className={styles.description}>
      13.5 Third Party Terms. You may be subject to additional terms and
      conditions that may apply when you obtains or uses third-party content,
      third-party software or goods and services provided by or through clients,
      sponsors, partners, licensors or other third-party partners of Mechanic
      Advisor.
    </p>
    <p className={styles.description}>
      13.6 Severability. If any provision of these Terms is held by a court or
      arbitrator of competent jurisdiction to be invalid, then such provision
      shall be construed, as nearly as possible, to reflect the intentions of
      the parties, and all other provisions shall remain in full force and
      effect.
    </p>
    <p className={styles.description}>
      13.7 Waiver. Mechanic Advisor’s failure to exercise or enforce any right
      or provision of these Terms shall not constitute a waiver of such right or
      provision unless acknowledged and agreed to by Mechanic Advisor in
      writing. Neither the course of conduct between the parties nor trade
      practice shall act to modify any provision of these Terms.
    </p>
    <p className={styles.description}>
      13.8 Governing Law The Terms shall be governed by and construed according
      to the laws of the State of Massachusetts, without giving effect to
      principles of conflicts of law and as applied to agreements made, entered
      into and performed entirely in Massachusetts by Massachusetts residents,
      notwithstanding the actual residence of the parties. You and Mechanic
      Advisor expressly disclaim the applicability of the United Nations
      Convention on Contracts for the International Sale of Goods (CISG). To the
      extent that the arbitration provisions outlined in Section 12 are not
      applicable (for example, when confirming an arbitration award), you and
      Mechanic Advisor agree to submit to the exclusive jurisdiction of the
      courts located in Dallas County in the State of Massachusetts, USA.
    </p>
    <p className={styles.description}>
      13.9 Assignment. Mechanic Advisor may assign its rights and delegate its
      duties under these Terms to any party at any time without notice to you.
      You may not assign or delegate your rights or obligations without Mechanic
      Advisor’s prior written consent, which may be withheld and conditioned at
      Mechanic Advisor’s sole discretion.
    </p>
    <p className={styles.description}>
      13.10 Interpretation; Headings. All headings in these Terms are for
      convenience only and have no legal effect. These Terms may be provided in
      other languages for your convenience; however, the English version of
      these Terms is controlling between you and Mechanic Advisor in all
      respects. The word “including” as used in these Terms is understood
      between you and Mechanic Advisor to mean “including, but not limited to.”
    </p>
    <p className={styles.description}>
      13.11 Electronic Communications; Notices. You hereby agree to the use of
      electronic communications in order to enter into these Terms, to create
      other records and to the electronic delivery of notices, policies and
      records of transactions between you and Mechanic Advisor with respect to
      the Devices, Services, and these Terms. You hereby waive any rights or
      requirements under any laws or regulations in any jurisdiction which
      require an original (non-electronic) signature or delivery or retention of
      non-electronic records, to the extent permitted under applicable mandatory
      law. In addition, Mechanic Advisor may deliver notices to you by prepaid
      certified mail, return receipt requested at the address in Mechanic
      Advisor’s database; any notice that complies with this Section shall be
      deemed effectively given upon delivery. You must give notice to Mechanic
      Advisor by certified or registered mail, return receipt requested at the
      following address: 840 Summer St. STE 102, Boston, Massachusetts 02127
      USA, Attention: Legal Department.
    </p>
  </div>,
  <div className={styles.term}>
    <div className={styles.title}>*Attachment A: Network Service Terms*</div>
  </div>,
  <div className={styles.term}>
    <div className={styles.title}>1. Mojio</div>
  </div>,
];

const SteermaticsTermsAndConditionsPage = ({}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.heroSection}>
        <div className={styles.textWrapper}>
          <div className={styles.name}>Steermatics Terms and Conditions</div>
          <h1 className={styles.title}>Steermatics Terms and Conditions</h1>
        </div>
      </div>
      <div className={styles.termsSection}>{TERMS_AND_CONDITIONS}</div>
    </div>
  );
};

export default SteermaticsTermsAndConditionsPage;
