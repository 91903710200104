import React from "react";
import * as styles from "./Communicate.module.scss";
import UpperSection from "../../Shared/UpperSection";
import { StaticImage } from "gatsby-plugin-image";

export const Communicate = () => {
  return (
    <UpperSection
      sectionName="Text Messaging"
      textTitle="Auto shop text messaging made easy"
      description="Connect with your customers with auto repair shop texting."
      clsLeft="textMessagingDistance"
      clsRight="imagesWrapperDistance"
      formClass="UpperSectionForm"
    >
      <div className={styles.imagesWrap}>
        <StaticImage
          src="../../../assets/images/TextMessaging/feature.png"
          alt="feature"
          className={styles.feature}
          loading="eager"
          quality={100}
        />
        <StaticImage
          src="../../../assets/images/TextMessaging/tab-1.png"
          alt="tab icon 1"
          className={styles.tab1}
          loading="eager"
          quality={100}
          style={{
            position: "absolute",
          }}
        />
        <StaticImage
          src="../../../assets/images/TextMessaging/tab-2.png"
          alt="tab icon 2"
          className={styles.tab2}
          loading="eager"
          quality={100}
          style={{
            position: "absolute",
          }}
        />
        <StaticImage
          src="../../../assets/images/TextMessaging/feather.png"
          alt="feather icon"
          className={styles.feather}
          loading="eager"
          quality={100}
          style={{
            position: "absolute",
          }}
        />
        <StaticImage
          src="../../../assets/images/TextMessaging/message.png"
          alt="message icon"
          className={styles.message}
          loading="eager"
          quality={100}
          style={{
            position: "absolute",
          }}
        />
        <StaticImage
          src="../../../assets/images/TextMessaging/orange.png"
          alt="orange icon"
          className={styles.orange}
          loading="eager"
          quality={100}
          style={{
            position: "absolute",
          }}
        />
        <StaticImage
          src="../../../assets/images/TextMessaging/blue.png"
          alt="blue icon"
          className={styles.blue}
          loading="eager"
          quality={100}
          style={{
            position: "absolute",
          }}
        />
        <StaticImage
          src="../../../assets/images/TextMessaging/green.png"
          alt="green icon"
          className={styles.green}
          loading="eager"
          quality={100}
          style={{
            position: "absolute",
          }}
        />
        <StaticImage
          src="../../../assets/images/TextMessaging/red.png"
          alt="red icon"
          className={styles.red}
          loading="eager"
          quality={100}
          style={{
            position: "absolute",
          }}
        />
      </div>
    </UpperSection>
  );
};
