import React, { useRef, useEffect } from "react";
import * as styles from "./Promo.module.scss";
import { animateOnScroll } from "../../../../services/animationService";

type PromoTool = {
  sectionTitle: string;
  promoTitle: string;
  description: string;
  className?: any;
  children?: any;
  promoTitleCsl?: any;
};

const Promo: React.FC<PromoTool> = ({
  sectionTitle,
  promoTitle,
  description,
  className,
  children,
  promoTitleCsl,
}) => {
  const refSectionTitle = useRef(null);
  const refPromoTitle = useRef(null);
  const refDescription = useRef(null);

  const refs = [refSectionTitle, refPromoTitle, refDescription];

  useEffect(() => animateOnScroll(refs.map((ref) => ref.current)), []);

  return (
    <div className={styles.promo}>
      <div className={styles.sectionHeader}>
        <div
          className={`${styles.sectionTitle} ${className}`}
          ref={refSectionTitle}
        >
          {sectionTitle}
        </div>
        <div
          className={`${styles.promoTitle} ${promoTitleCsl}`}
          ref={refPromoTitle}
        >
          {promoTitle}
        </div>
        <div className={styles.description} ref={refDescription}>
          {description}
        </div>
      </div>
      {children}
    </div>
  );
};

export default Promo;
