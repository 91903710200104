import React, { useRef, useEffect } from "react";
import * as styles from "./SupportRequest.module.scss";
import { animateOnScroll } from "../../services/animationService";
import HubspotContactForm from "./HubspotContactForm";
import "./HubspotContactForm.css";
import {
  ANALYTICS_EVENTS,
  addAnalyticsEvent,
} from "../../services/analyticsService";

const SupportRequestPage = ({}) => {
  const refSectionName = useRef(null);
  const refTextTitle = useRef(null);
  const refDescription = useRef(null);
  const refSection = useRef(null);
  const refContacts = useRef(null);

  const refs = [
    refSectionName,
    refTextTitle,
    refDescription,
    refSection,
    refContacts,
  ];

  useEffect(() => animateOnScroll(refs.map((ref) => ref.current)), []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.section} ref={refSection}>
        <div className={styles.sectionText}>
          <div className={styles.textWraper}>
            <div className={styles.sectionName} ref={refSectionName}>
              Support Request
            </div>
            <h1 className={styles.textTitle} ref={refTextTitle}>
              We're here to help
            </h1>
            <p className={styles.description} ref={refDescription}>
              Have a question? Want to chat? Wondering if we’re hiring? Get in
              touch!
            </p>
            <div className={styles.contactsWrapper} ref={refContacts}>
              <div className={styles.contact}>
                <div className={styles.contactIcon}></div>
                <a href="tel:617-765-8187">617-765-8187</a>
              </div>
              <div className={styles.contact}>
                <div className={styles.contactIcon}></div>
                <p>8 AM – 8 PM ET</p>
              </div>
              <div className={styles.contact}>
                <div className={styles.contactIcon}></div>
                <a href="mailto:help@steercrm.com">help@steercrm.com</a>
              </div>
              <div className={styles.contact}>
                <div className={styles.contactIcon}></div>
                <a
                  target="_blank"
                  href="https://maps.app.goo.gl/JNou8qARQQ2eWfHz8"
                >
                  230 3rd Ave, Waltham, MA 02451
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.independedForm}>
        <HubspotContactForm
          onSubmit={() => {
            addAnalyticsEvent(ANALYTICS_EVENTS.supportRequestFormSubmitAttempt);
          }}
          onSuccess={() => {
            addAnalyticsEvent(ANALYTICS_EVENTS.supportRequestFormSubmitted);
          }}
        />
        <div className={styles.darkBlock}></div>
      </div>
    </div>
  );
};

export default SupportRequestPage;
