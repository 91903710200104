import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { FeaturesSlider } from "../components/Shared/FeaturesSlider";
import {
  Communicate,
  TextMessagingFeatures,
} from "../components/TextMessaging";
import { Helmet } from "react-helmet";

const TextMessaging: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return (
    <>
      <Helmet>
        <title>Auto Repair Shop Texting Software | Steer</title>
        <meta
          name="description"
          content="With automated customer text messaging, automotive shop owners can use Steer to send customer appointment reminders, service specials, and other communications."
        />
      </Helmet>
      <Communicate />
      <TextMessagingFeatures />
      <FeaturesSlider excludedSlideIdx={2} />
    </>
  );
};

export default TextMessaging;
