import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Hero, ToolsNavigation, Promotions } from "../components/Home";
import { VideoTestimonials } from "../components/Shared/VideoTestimonials";
import { PATHS } from "../constants";

const Home: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  return (
    <>
      <Hero />
      <ToolsNavigation />
      <VideoTestimonials link={PATHS.CUSTOMER_TESTIMONIALS} />
      <Promotions />
    </>
  );
};

export default Home;
