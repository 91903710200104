let animateOnScroll;
let animateOnScrollByPercentage;
let animateVideoOnScroll;

if (typeof window !== `undefined`) {
  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const animationTarget = entry.target.hasAttribute(
            "data-animateparent"
          )
            ? entry.target.parentNode
            : entry.target;
          animationTarget.classList.add("animated");
          observer.unobserve(entry.target);
        }
      });
    },
    { rootMargin: "0px 0px -15% 0px" }
  );

  animateOnScroll = (elements: any[]) => {
    elements.forEach((element) => observer.observe(element));
  };

  const percentageObserver = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (
            entry.boundingClientRect.bottom <= entry.intersectionRect.bottom
          ) {
            entry.target.style.animationDelay = "-1s";
            observer.unobserve(entry.target);
          } else {
            entry.target.style.animationDelay =
              0 - entry.intersectionRatio + "s";
          }
        }
      });
    },
    {
      rootMargin: "0px 0px -15% 0px",
      threshold: new Array(101).fill(0).map((v, i) => i * 0.01),
    }
  );

  animateOnScrollByPercentage = (elements: any[]) => {
    elements.forEach((element) => {
      percentageObserver.observe(element);
      element.classList.add("animated");
      element.style.animationTime = "1s";
      element.style.animationPlayState = "paused";
    });
  };

  const videoObserver = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target?.play();
          observer.unobserve(entry.target);
        }
      });
    },
    {
      rootMargin: "0px 0px -15% 0px",
      threshold: 0.5,
    }
  );

  animateVideoOnScroll = (element: any) => {
    videoObserver.observe(element);
  };
}

export { animateOnScroll, animateOnScrollByPercentage, animateVideoOnScroll };
