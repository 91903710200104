import { useEffect, useState } from "react";

export function useHabspotLinkLoad(id: string): boolean {
  const [hubspotLoadingTries, setHubspotLoadingTries] = useState(1);
  const [isHabspotLoading, setIsHabspotLoading] = useState(true);
  const portalId = 6191634;

  useEffect(() => {
    if (typeof hbspt !== "undefined") {
      hbspt.cta.load(portalId, `${id}`, {
        useNewLoader: "true",
        region: "na1",
        afterLoad: setIsHabspotLoading(false),
      });
    } else if (hubspotLoadingTries < 10) {
      setTimeout(
        () => setHubspotLoadingTries((x) => x + 1),
        hubspotLoadingTries * 500
      );
    }
  }, [hubspotLoadingTries]);

  return isHabspotLoading;
}
