import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { FeaturesSlider } from "../components/Shared/FeaturesSlider";
import EmailMarketingProduct from "../components/EmailMarketing";
import { Helmet } from "react-helmet";

const EmailMarketing: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return (
    <>
      <Helmet>
        <title>Auto Repair Shop Email Marketing | Steer</title>
        <meta
          name="description"
          content="Connect with your customers via automated marketing emails designed for automotive shops. With Steer, you can easily create and manage customer communications."
        />
      </Helmet>
      <EmailMarketingProduct />
      <FeaturesSlider excludedSlideIdx={0} />
    </>
  );
};

export default EmailMarketing;
