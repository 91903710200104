import React from "react";
import * as styles from "./Locations.module.scss";

const LOCATIONS = [
  {
    title: "Waltham, Massachusetts",
    description:
      'Waltham, known as the "Watch City" due to its history as a major center for the watchmaking industry, is located just outside Boston and is a hub for innovation and education. Home to several prestigious universities and research institutions, Waltham combines historical charm with modern advancements, making it a vibrant place to live and work.',
    className: styles.boston,
  },
  {
    title: "Tulsa, Oklahoma",
    description:
      "Tulsa is the second-largest city in the state of Oklahoma and 47th-most populous city in the United States. Historically, a robust energy sector fueled Tulsa’s economy; however, today the city has diversified and leading sectors include finance, aviation, telecommunications and technology.",
    className: styles.tulsa,
  },
  {
    title: "Lviv, Ukraine",
    description:
      "Lviv is the largest city in Western Ukraine, and the sixth-largest in Ukraine, with a population of 717,510. Lviv emerged as the centre of the historical regions of Red Ruthenia and Galicia in the 14th century, superseding Halych, Chełm, Belz and Przemyśl.",
    className: styles.lviv,
  },
];

const Locations = ({}) => {
  return (
    <>
      {LOCATIONS.map((location, i) => (
        <div className={`${styles.location} ${location.className}`} key={i}>
          <div className={styles.imgCity} />
          <div className={styles.imgOffice} />
          <div className={styles.card}>
            <div className={styles.title}>{location.title}</div>
            <div className={styles.description}>{location.description}</div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Locations;
