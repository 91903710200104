import React from "react";
import MiddleSection from "../Shared/MiddleSection";
import UpperSection from "../Shared/UpperSection";
import * as styles from "./Telematics.module.scss";
import iconStayConnected from "../../assets/icons/Telematics/i-telematics-stay-connected.svg";
import iconMarketing from "../../assets/icons/Telematics/i-telematics-marketing.svg";
import iconDataAccess from "../../assets/icons/Telematics/i-telematics-data-access.svg";
import { StaticImage } from "gatsby-plugin-image";
import { useScreenSize } from "../../hooks";

const TELEMATICS_TOOLS = [
  {
    toolTitle: "Stay connected",
    toolDescription:
      "The Connection Key, an OBD-II telematics device allows shops to remotely connect with their customer's vehicle from any location to see important diagnostic info. If vehicle trouble is detected, both the shop and customer will receive real-time notifications, allowing you to get in contact and offer service.",
    icon: iconStayConnected,
  },
  {
    toolTitle: "Data-driven marketing",
    toolDescription:
      "Our OBD telematics system takes the guess work out of sending your customer service reminders. With our real-time marketing engine, repair shops can notify customers when they are in need of a service or have a vehicle issue that needs to be checked.",
    icon: iconMarketing,
  },
  {
    toolTitle: "Complete data access",
    toolDescription:
      "Connect with customers on the My Mechanic mobile app, which provides service history, vehicle information, and quote requests. Customers can view service reminders and their vehicle's history. Plus, shops can send quotes and invoice a customer directly through the app for easy, clear communication.",
    icon: iconDataAccess,
  },
];

const TelematicsProduct = ({}) => {
  const { windowWidth } = useScreenSize();
  const areTelematicsImagesShown = windowWidth > 900;

  return (
    <>
      <UpperSection
        sectionName="Telematics"
        textTitle="Do more with vehicle&nbsp;telematics"
        description="Easily diagnose and repair with an OBD device that allows you to receive real-time vehicle telematics from your customers."
        clsLeft="telematicsDistance"
        clsRight="telematicsImgDistance"
        formClass="TelematicsForm"
      >
        <div className={styles.imagesWrap}>
          <StaticImage
            src="../../assets/images/Telematics/photo-1.png"
            alt="telematics image1"
            className={styles.photo1}
            loading="eager"
            quality={100}
          />
          <StaticImage
            src="../../assets/images/Telematics/photo-2.png"
            alt="telematics image2"
            className={styles.photo2}
            loading="eager"
            quality={100}
          />
          <StaticImage
            src="../../assets/images/Telematics/photo-3.png"
            alt="telematics image3"
            className={styles.photo3}
            loading="eager"
            quality={100}
          />
          <StaticImage
            src="../../assets/images/Telematics/photo-4.png"
            alt="telematics image4"
            className={styles.photo4}
            loading="eager"
            quality={100}
          />
          {areTelematicsImagesShown && (
            <>
              <StaticImage
                src="../../assets/images/Telematics/telematics-1.png"
                alt="telematics"
                className={styles.imgTelematics1}
                loading="eager"
                quality={100}
                style={{
                  position: "absolute",
                }}
              />
              <StaticImage
                src="../../assets/images/Telematics/telematics-2.png"
                alt="telematics"
                className={styles.imgTelematics2}
                loading="eager"
                quality={100}
                style={{
                  position: "absolute",
                }}
              />
              <StaticImage
                src="../../assets/images/Telematics/telematics-3.png"
                alt="telematics"
                className={styles.imgTelematics3}
                loading="eager"
                quality={100}
                style={{
                  position: "absolute",
                }}
              />
            </>
          )}
          <StaticImage
            src="../../assets/images/TextMessaging/hexagon.png"
            alt="hexagon"
            className={styles.hexagon}
            style={{
              position: "absolute",
            }}
          />
          <StaticImage
            src="../../assets/images/TextMessaging/circle.png"
            alt="circle"
            className={styles.circle}
            style={{
              position: "absolute",
            }}
          />
          <StaticImage
            src="../../assets/images/TextMessaging/rectangle.png"
            alt="rectangle"
            className={styles.rectangle}
            style={{
              position: "absolute",
            }}
          />
        </div>
      </UpperSection>
      <MiddleSection
        textTitle="Diagnose smarter"
        description="Build customer loyalty and provide increased vehicle safety using vehicle telematics with real-time repair data."
        tools={TELEMATICS_TOOLS}
      >
        <div className={styles.backgroundMiddle}></div>
      </MiddleSection>
    </>
  );
};

export default TelematicsProduct;
