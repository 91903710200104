import React from "react";
import * as styles from "./Footer.module.scss";
import { PATHS } from "../../constants";

export const BannerJoin: React.FC = () => {
  return (
    <>
      <div className={`${styles.banner} ${styles.bannerJoin}`}>
        <p className={styles.title}>Join our team!</p>
        <p className={`${styles.subtitle} ${styles.subtitleJoin}`}>
          We’re always looking for talented individuals to work with us.
        </p>
        <a href={PATHS.SUPPORT_REQUEST} style={{ color: "inherit" }}>
          <div className={styles.button}>
            Contact Us
            <span className={styles.arrow}>&#8594;</span>
          </div>
        </a>
        <div className={`${styles.icon} ${styles.iconJoin}`}></div>
      </div>
      <div className={styles.bannerPlaceholder}></div>
    </>
  );
};
