import React, { useState } from "react";
import classNames from "classnames";
import * as style from "./SelectInput.module.scss";
import iconArrow from "../../../assets/icons/i-arrow-up-grey.svg";
import { useOutsideClick } from "../../../hooks";

type SelectProps = {
  onChange: (e: { value: string; shopSize: number }) => void;
  options: { value: string; shopSize?: string; className?: string }[];
  value: string;
  initialValue: string;
  dropdownHeight?: string;
};

const Select: React.FC<SelectProps> = ({
  options,
  value,
  initialValue,
  onChange,
  dropdownHeight,
}) => {
  const [isOpened, setIsOpened] = useState(false);

  const handleClickOutside = () => {
    setIsOpened(false);
  };
  const ref = useOutsideClick(handleClickOutside);

  function toggleOpen() {
    isOpened ? setIsOpened(false) : setIsOpened(true);
  }

  return (
    <div className={classNames(style.selectGroup)} ref={ref}>
      <div className={style.selectWrapper}>
        <input
          value={value || initialValue || ""}
          onChange={() => {}}
          autoComplete="off"
          readOnly={true}
          className={classNames(style.select, {
            [style.focused]: isOpened,
            [style.initial]: !value,
          })}
          onClick={() => toggleOpen()}
        />
        <img src={iconArrow} alt="error icon" className={style.arrowIcon} />
        <div
          className={isOpened ? style.options : style.hidden}
          style={{ maxHeight: dropdownHeight }}
        >
          {options.map((opt, i) => (
            <div
              className={classNames(style.option, {
                [style[opt.className]]: !!opt.className,
              })}
              key={i}
              data-value={opt.value}
              onClick={() => {
                onChange(opt);
                setIsOpened(false);
              }}
            >
              {opt.value}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Select;
