import React from "react";
import UpperSection from "../Shared/UpperSection";
import * as styles from "./Partners.module.scss";
import { Link } from "@reach/router";
import icon from "../../assets/icons/i-arrow-up-active.svg";
import iconDark from "../../assets/icons/i-arrow-up-dark-blue.svg";
import {
  imgAdvanceProfessional,
  imgAmazingWomenInAutocare,
  imgAutoShopOwnersGroup,
  imgAutomotiveAftermarketSuppliersAssociation,
  imgAutomotiveServiceTireAlliance,
  imgAutomotiveServiceCouncilsOfCalifornia,
  imgAutomotiveTrainingInstitute,
  imgAutoshopSolutions,
  imgBrakesForBreasts,
  imgDrive,
  imgLeadsNearMe,
  imgLoop,
  imgMidwestAutoCareAlliance,
  imgMojio,
  imgMotorMagazine,
  imgNapaTracs,
  imgPitCrewMarketing,
  imgProtractor,
  imgROWriter,
  imgRatchetWrench,
  imgRepairPal,
  imgShopFixAcademy,
  imgShopManagementSystemIntegrations,
  imgShopMarketingPros,
  imgShopWare,
  imgTekmetric,
  imgTorqata,
  imgTransformersInstitute,
  imgTurnKeyMarketing,
  imgVastSQL,
  imgMobil1,
} from "../../assets/images/OurPartners";
import { PATHS } from "../../constants";
import poligonUpper from "../../assets/images/Promos/polygonManagingAppointmets.png";
import poligonBottom from "../../assets/images/Promos/polygonEssentialMarketing.png";
import { StaticImage } from "gatsby-plugin-image";
import { useScreenSize } from "../../hooks";

const PARTNERS = [
  {
    title: "Advance Professional",
    description:
      "Advance Professional, an Advance Auto Parts brand, provides premium OE-quality parts and auto repair shop solutions that help keep your shop running smoothly. Steer CRM has partnered with Advance Professional to connect its service with industry-leading customer relationship tools.",
    logo: imgAdvanceProfessional,
    link: PATHS.ADVANCE_PROFESSIONAL,
    className: "advanceProfessional",
    isExternalLink: false,
  },
  {
    title: "Autoshop Solutions",
    description:
      "Autoshop Solutions is a premier digital marketing agency that specializes in solutions for the automotive industry. Steer CRM has partnered with Autoshop Solutions to enable personalized marketing campaigns for your business that can smoothly integrate into your customer management system, giving you a powerful tool that gain new customers and keep existing customers returning.",
    logo: imgAutoshopSolutions,
    link: "https://autoshopsolutions.com/",
    className: "autoshopSolutions",
    isExternalLink: true,
  },
  {
    title: "Shop Management System Integrations",
    description:
      "Steer CRM integrates with many industry-leading Shop Management Systems, giving your auto repair business an integrated solution that preforms tasks across platforms and provides a connected experience that brings multiple platforms together. This integration makes your shop more efficient and more accurate with critical data.",
    logo: imgShopManagementSystemIntegrations,
    link: "https://steercrm.com/shop-management-integrations",
    className: "shopManagementSystemIntegrations",
    isExternalLink: true,
  },
  {
    title: "LeadsNearMe",
    description:
      "LeadsNearMe offers expert website development and Google marketing services for your auto repair shop. Their solutions are designed to drive new customers to your shop, while Steer CRM can you help you keep new and existing customers returning.",
    logo: imgLeadsNearMe,
    link: "https://leadsnearme.com/",
    className: "leadsNearMe",
    isExternalLink: true,
  },
  {
    title: "Mojio + Steermatics",
    description:
      "Mojio provides connected telematics solutions for automotive repair businesses. Steer CRM has partnered with Mojio to offer Steermatics, a real time telematics and remote diagnostics service that's powered by Mojio and fully integrated with Steer CRM to enable data-driven, automated retention marketing and communication tools for auto repair shops. Plus, shops will gain access to Force by Mojio, which is a fully-integrated fleet management service.",
    link: "https://www.moj.io/",
    className: "mojio",
    isExternalLink: true,
    logo: imgMojio,
  },
  {
    title: "NAPA TRACS",
    description:
      "NAPA TRACS Shop Management System is designed to increase shop profitability, efficiency, and productivity. Steer CRM will be partnering with NAPA TRACS to provide full-system integration that allows you to drive customer sales and retention.",
    logo: imgNapaTracs,
    link: "https://napatracs.com/",
    className: "napaTracs",
    isExternalLink: true,
  },
  {
    title: "Protractor",
    description:
      "At Steer, we're proud to partner with Protractor, whose cloud-based Shop Management System tools are both powerful and easy-to-use. Our integration connects our platforms and allows for seamless real-time data sync between them. With Steer and Protractor on your side, it’s easier than ever to communicate with and retain customers.",
    logo: imgProtractor,
    link: PATHS.PROTRACTOR,
    className: "protractor",
    isExternalLink: false,
  },
  {
    title: "Shop Marketing Pros",
    description:
      "Shop Marketing Pros is a full-service marketing firm that provides solutions specifically designed for the automotive industry. Steer CRM has partnered with Shop Marketing Pros to power your shop communications so you can turn new customers into returning customers.",
    logo: imgShopMarketingPros,
    link: "https://shopmarketingpros.com/",
    className: "shopMarketingPros",
    isExternalLink: true,
  },
  {
    title: "Shop-Ware",
    description:
      "Shop-Ware is a preferred Shop Management System that has partnered with Steer CRM to provide powerful integrations for your auto repair shop. Keep your customers in coming back with industry-leading business tools.",
    logo: imgShopWare,
    link: "https://info.shop-ware.com/steer",
    className: "shopWare",
    isExternalLink: true,
  },
  {
    title: "Tekmetric",
    description:
      "Tekmetric is a leading Shop Management System that has partnered with Steer CRM to provide direct-sync capabilities, so you can manage your shop and marketing more efficiently than ever. Tekmetric is a part of the Aftermarket Technology Connection along with Advance Auto parts.",
    logo: imgTekmetric,
    link: PATHS.TEKMETRIC,
    className: "tekmetric",
    isExternalLink: false,
  },
  {
    title: "TurnKey Marketing",
    description:
      "Turnkey Marketing provides multi-channel marketing campaigns that can capture new customers and keep current ones returning. Steer CRM partnered with TurnKey to enable a smooth CRM integration so you can take action on new leads while keeping current customers retained.",
    logo: imgTurnKeyMarketing,
    link: "https://www.turnkeyautomarketing.com/",
    className: "turnKeyMarketing",
    isExternalLink: true,
  },
  {
    title: "VastSQL",
    description:
      "VastSQL is a preferred Shop Management System that integrates with Steer CRM to keep your shop running smoothly and your customers returning.",
    logo: imgVastSQL,
    link: PATHS.VAST_SQL,
    className: "vastSQL",
    isExternalLink: false,
  },
  {
    title: "Amazing Women in Autocare",
    description:
      "Steer CRM is a proud sponsor of the Amazing Women in Autocare organization and their goal of advancing the careers of women in the auto repair business industry.",
    logo: imgAmazingWomenInAutocare,
    link: "https://amazingwia.com/",
    className: "amazingWomenInAutocare",
    isExternalLink: true,
  },
  {
    title: "Automotive Aftermarket Suppliers Association",
    description:
      "Steer is a proud sponsor of the Automotive Aftermarket Suppliers Association and their annual AAPEX Training Expo, one of the largest automotive training events in the country.",
    logo: imgAutomotiveAftermarketSuppliersAssociation,
    link: "https://www.aftermarketsuppliers.org/",
    isExternalLink: true,
    className: "automotiveAftermarketSuppliersAssociation",
  },
  {
    title: "Automotive Service & Tire Alliance",
    description:
      "The Automotive Service & Tire Alliance is the largest member-sponsored non-profit association in the Southeastern US. Steer CRM has sponsored the ASTA and their annual ASTE Training Expo held in North Carolina.",
    logo: imgAutomotiveServiceTireAlliance,
    link: "https://astausa.org/",
    isExternalLink: true,
    className: "automotiveServiceTireAlliance",
  },
  {
    title: "Automotive Service Councils of California",
    description:
      "Steer CRM is a proud corporate sponsor of the ASCCA, which provides support and advocacy for automotive repair businesses and professionals in California.",
    logo: imgAutomotiveServiceCouncilsOfCalifornia,
    link: "https://www.ascca.com/",
    isExternalLink: true,
    className: "automotiveServiceCouncilsOfCalifornia",
  },
  {
    title: "Auto Shop Owners Group",
    description:
      "Steer CRM has partnered with the Auto Shop Owners Group in its efforts to support automotive professionals through funding, information, and scholarship opportunities.",
    logo: imgAutoShopOwnersGroup,
    link: "https://asog.site/",
    isExternalLink: true,
    className: "autoShopOwnersGroup",
  },
  {
    title: "Automotive Training Institute",
    description:
      "The Automotive Training Institute (ATI) provides advanced training solutions that can help move your business forward. Steer CRM is a proud partner of ATI and attends their training events annually.",
    logo: imgAutomotiveTrainingInstitute,
    link: "https://www.autotraining.net/",
    isExternalLink: true,
    className: "automotiveTrainingInstitute",
  },
  {
    title: "Brakes for Breasts",
    description:
      "Steer CRM is a proud sponsor of the Brakes for Breasts Cancer Vaccine Fund which partners annually with automotive repair shops to donate funds to cancer research. Steer CRM has committed to donating proceeds from their annual Brakes for Breasts campaign to the organization.",
    logo: imgBrakesForBreasts,
    link: "https://www.brakesforbreasts.com/",
    isExternalLink: true,
    className: "brakesForBreasts",
  },
  {
    title: "DRIVE",
    description:
      "DRIVE is one of the largest high-tech automotive, collision, and truck repair coaching and management training companies in North America. Steer CRM is a Gold-level sponsor that provides CRM product training throughout the year and at their annual Super Conference event.",
    logo: imgDrive,
    link: "https://driveshops.com/",
    isExternalLink: true,
    className: "drive",
  },
  {
    title: "Loop",
    description:
      "Steer has partnered with Loop to enhance the auto repair experience by offering convenient vehicle pick-up and return services. This collaboration eliminates the need for customers to arrange their own transportation or endure long waits, increasing overall satisfaction. It's a proactive move that decreases the likelihood of customers postponing necessary maintenance due to inconvenience, ensuring their repair needs are met with ease and efficiency.",
    logo: imgLoop,
    link: "https://www.loopnow.us/",
    isExternalLink: true,
    className: "loop",
  },
  {
    title: "Midwest Auto Care Alliance",
    description:
      "Steer CRM is a proud sponsor of the Midwest Auto Care Alliance and shares its goal of driving excellence and professionalism in the independent automotive service industry through education, training, support, and mentorship.",
    logo: imgMidwestAutoCareAlliance,
    link: "https://www.mwaca.org/",
    isExternalLink: true,
    className: "midwestAutoCareAlliance",
  },
  {
    title: "MOTOR Magazine",
    description:
      "MOTOR Magazine powers the auto industry with the latest news and developments. Steer CRM was awarded the Top 20 designation as one of the industry’s most innovative tools in 2021 and 2022.",
    logo: imgMotorMagazine,
    link: "https://www.motor.com/top20awards/",
    isExternalLink: true,
    className: "motorMagazine",
  },
  {
    title: "Pit Crew Marketing",
    description:
      "The Loyalty Rewards and Social Impact Specialists. Pit Crew’s comprehensive eWardMe Auto Shop Rewards software offers an array of benefits and features to increase loyalty and community engagement.",
    logo: imgPitCrewMarketing,
    link: "https://pitcrewmarketing.com/",
    isExternalLink: true,
    className: "pitCrewMarketing",
  },
  {
    title: "Ratchet+Wrench",
    description:
      "Ratchet+Wrench drives the automotive repair industry with the latest news, trends, and features. Steer CRM is a Silver-level sponsor at the annual Ratchet+Wrench Management Conference. See our product features in new Ratchet+Wrench print and digital editions.",
    logo: imgRatchetWrench,
    link: "https://www.ratchetandwrench.com/",
    isExternalLink: true,
    className: "ratchetWrench",
  },
  {
    title: "RepairPal",
    description:
      "RepairPal empowers motorists with recommendations for trustworthy automotive repair shops. Steer CRM has partnered with RepairPal to offer complimentary CRM tools for shops utilizing their service.",
    logo: imgRepairPal,
    link: "https://pages.repairpal.com/steer",
    isExternalLink: true,
    className: "repairPal",
  },
  {
    title: "R. O. Writer",
    description:
      "R. O. Writer is a preferred Shop Management System partner that provides direct sync with the Steer CRM platform, so you can keep your shop running efficiently while utilizing Steer CRM's advanced marketing tools to bring in new and existing customers.",
    logo: imgROWriter,
    link: "https://info.rowriter.com/",
    isExternalLink: true,
    className: "ROWriter",
  },
  {
    title: "Shop Fix Academy",
    description:
      "Shop Fix Academy is a premier automotive training group that offers high-quality content with free and paid training, live events, and online groups.",
    logo: imgShopFixAcademy,
    link: "https://shopfixacademy.com/",
    isExternalLink: true,
    className: "shopFixAcademy",
  },
  {
    title: "Torqata",
    description:
      "Torqata provides data marketing solutions for the automotive aftermarket including customer research, pricing information, and industry trends. Steer CRM has partnered with Torqata to provide co-marketing solutions to integrate industry insights and customer data into your CRM.",
    logo: imgTorqata,
    link: "https://torqata.com/",
    isExternalLink: true,
    className: "torqata",
  },
  {
    title: "Transformers Institute",
    description:
      "The Transformers Institute is a premier auto repair shop coaching group that provides live and video training, consulting and coaching programs, M&A assistance, financing solutions, and marketing services. Steer CRM is a Platinum sponsor and is proud to be the exclusive CRM sponsor of Transformers Institute. Steer CRM attends Transformer Institute’s Mastermind as well as its annual summit.",
    logo: imgTransformersInstitute,
    link: "https://www.transformersinstitute.com/",
    isExternalLink: true,
    className: "transformersInstitute",
  },
  {
    title: "Mobil 1™",
    description:
      "Mobil 1™ is the most recommended brand by service providers and a leader in the synthetic oil market. Steer joined forces with the Mobil 1 team to build a partnership that not only safeguards engines but also helps shops strengthen their communication with their customers. By recommending Mobil 1 oil changes, you're going beyond standard maintenance and helping customers extend their engine life with a superior product.",
    logo: imgMobil1,
    link: "https://learn.steercrm.com/mobil1-steer",
    isExternalLink: true,
    className: "mobil1",
    linkTitle: "Learn More",
  },
].sort((a, b) => {
  const ATitle = a.title.toUpperCase();
  const BTitle = b.title.toUpperCase();
  if (ATitle < BTitle) return -1;
  if (ATitle > BTitle) return 1;
  return 0;
});

const Partners = ({}) => {
  const { isTablet, isMobile } = useScreenSize();

  return (
    <>
      <UpperSection
        sectionName="Partners"
        textTitle="Meet our partners"
        description="Steer has partnered with industry leaders in automotive repair technology and solutions to help your shop build efficiencies, find new customers, and drive customer loyalty."
        clsLeft="PartnersHero"
        clsRight="PartnersHeroImg"
        formClass="Hidden"
        className={styles.heroWrapper}
      >
        <div className={styles.allParnersWrapper}>
          <UpperLineImages isTablet={isTablet} isMobile={isMobile} />
          <BottomLineImages isTablet={isTablet} isMobile={isMobile} />
        </div>
      </UpperSection>
      <div className={styles.wrapper}>
        <div className={styles.cardsWrapper}>
          <img className={styles.poligonUpper} src={poligonUpper} />
          <img className={styles.poligonBottom} src={poligonBottom} />
          <div className={styles.cardsContainer}>
            {PARTNERS.map(
              (
                {
                  title,
                  logo,
                  description,
                  link,
                  className,
                  isExternalLink,
                  linkTitle,
                },
                i
              ) => (
                <PartnerCard
                  title={title}
                  description={description}
                  link={link}
                  logo={logo}
                  key={i}
                  className={className}
                  isExternalLink={isExternalLink}
                  linkTitle={linkTitle}
                />
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Partners;

type PartnerCardProps = {
  title: string;
  description: string;
  logo: string;
  link?: string;
  className?: string;
  isExternalLink: boolean;
  linkTitle?: string;
};

const PartnerCard: React.FC<PartnerCardProps> = ({
  title,
  description,
  link = "",
  logo,
  className,
  isExternalLink,
  linkTitle,
}) => {
  return (
    <div className={styles.partnerCard}>
      <div className={styles.cardHeader}>
        <img
          src={logo}
          alt="logo"
          className={`${styles.logo} ${styles[className]}`}
        />
      </div>
      <div className={styles.cardBody}>
        <div className={styles.cardTitle}>{title}</div>
        <div className={styles.cardDescription}>{description}</div>
        {!isExternalLink && (
          <Link to={link} className={styles.link}>
            <span>{linkTitle || "Learn More"}</span>
            <img className={styles.linkIcon} src={icon}></img>
            <img className={styles.linkIconDark} src={iconDark}></img>
          </Link>
        )}
        {isExternalLink && (
          <a
            href={link}
            className={styles.link}
            target={"_blank"}
            rel="nofollow"
          >
            <span>{linkTitle || "Visit Website"}</span>
            <img className={styles.linkIcon} src={icon}></img>
            <img className={styles.linkIconDark} src={iconDark}></img>
          </a>
        )}
      </div>
    </div>
  );
};

type LineImagesProps = {
  isTablet: boolean;
  isMobile: boolean;
};

const UpperLineImages: React.FC<LineImagesProps> = ({ isTablet, isMobile }) => {
  return (
    <>
      {isMobile ? (
        <>
          <StaticImage
            src="../../assets/images/OurPartners/upper_logos_line.png"
            alt="parnters logos"
            height={121}
            width={1683.6}
            className={styles.upperLineImg}
            loading="eager"
            quality={100}
            style={{
              position: "absolute",
            }}
            layout="fixed"
          />
          <StaticImage
            src="../../assets/images/OurPartners/upper_logos_line.png"
            alt="parnters logos"
            height={121}
            width={1683.6}
            className={styles.upperLineImg}
            loading="eager"
            quality={100}
            style={{
              position: "absolute",
            }}
            layout="fixed"
          />
        </>
      ) : isTablet ? (
        <>
          <StaticImage
            src="../../assets/images/OurPartners/upper_logos_line.png"
            alt="parnters logos"
            height={151.5}
            width={2104.5}
            className={styles.upperLineImg}
            loading="eager"
            quality={100}
            style={{
              position: "absolute",
            }}
            layout="fixed"
          />
          <StaticImage
            src="../../assets/images/OurPartners/upper_logos_line.png"
            alt="parnters logos"
            height={151.5}
            width={2104.5}
            className={styles.upperLineImg}
            loading="eager"
            quality={100}
            style={{
              position: "absolute",
            }}
            layout="fixed"
          />
        </>
      ) : (
        <>
          <StaticImage
            src="../../assets/images/OurPartners/upper_logos_line.png"
            alt="parnters logos"
            height={202}
            width={2806}
            className={styles.upperLineImg}
            loading="eager"
            quality={100}
            style={{
              position: "absolute",
            }}
            layout="fixed"
          />
          <StaticImage
            src="../../assets/images/OurPartners/upper_logos_line.png"
            alt="parnters logos"
            height={202}
            width={2806}
            className={styles.upperLineImg}
            loading="eager"
            quality={100}
            style={{
              position: "absolute",
            }}
            layout="fixed"
          />
        </>
      )}
    </>
  );
};

const BottomLineImages: React.FC<LineImagesProps> = ({
  isTablet,
  isMobile,
}) => {
  return (
    <>
      {isMobile ? (
        <>
          <StaticImage
            src="../../assets/images/OurPartners/down_logos_line.png"
            alt="parnters logos"
            layout="fixed"
            width={1585.8}
            aspectRatio={3965 / 303}
            className={styles.bottomLineImg}
            loading="eager"
            quality={100}
            style={{
              position: "absolute",
            }}
          />
          <StaticImage
            src="../../assets/images/OurPartners/down_logos_line.png"
            alt="parnters logos"
            layout="fixed"
            width={1585.8}
            aspectRatio={3965 / 303}
            className={styles.bottomLineImg}
            loading="eager"
            quality={100}
            style={{
              position: "absolute",
            }}
          />
        </>
      ) : isTablet ? (
        <>
          <StaticImage
            src="../../assets/images/OurPartners/down_logos_line.png"
            alt="parnters logos"
            layout="fixed"
            width={1982}
            aspectRatio={3965 / 303}
            className={styles.bottomLineImg}
            loading="eager"
            quality={100}
            style={{
              position: "absolute",
            }}
          />
          <StaticImage
            src="../../assets/images/OurPartners/down_logos_line.png"
            alt="parnters logos"
            layout="fixed"
            width={1982}
            aspectRatio={3965 / 303}
            className={styles.bottomLineImg}
            loading="eager"
            quality={100}
            style={{
              position: "absolute",
            }}
          />
        </>
      ) : (
        <>
          <StaticImage
            src="../../assets/images/OurPartners/down_logos_line.png"
            alt="parnters logos"
            className={styles.bottomLineImg}
            loading="eager"
            quality={100}
            width={2643}
            aspectRatio={3965 / 303}
            style={{
              position: "absolute",
            }}
            layout="fixed"
          />
          <StaticImage
            src="../../assets/images/OurPartners/down_logos_line.png"
            alt="parnters logos"
            className={styles.bottomLineImg}
            loading="eager"
            quality={100}
            width={2643}
            aspectRatio={3965 / 303}
            style={{
              position: "absolute",
            }}
            layout="fixed"
          />
        </>
      )}
    </>
  );
};
