import React from "react";
import * as styles from "./About.module.scss";
import UpperSection from "../Shared/UpperSection";
import Timeline from "./Timeline";
import Locations from "./Locations";
import { StaticImage } from "gatsby-plugin-image";
import { useScreenSize } from "../../hooks";

const About = ({}) => {
  const { isMobile } = useScreenSize();

  return (
    <>
      <UpperSection
        sectionName="Our Story"
        textTitle="We're here to help you thrive"
        description="Steer is changing the way auto shops communicate with their customers through our automated retention marketing and customer communication platform."
        clsLeft="aboutUsDistance"
        clsRight="aboutUsImgDistance"
        formClass="Hidden"
        className={styles.hero}
      >
        {isMobile ? (
          <StaticImage
            src="../../assets/images/AboutUs/hero-mobile.png"
            alt="telematics hero image"
            className={styles.heroMobile}
            loading="eager"
            quality={100}
            objectFit="contain"
          />
        ) : (
          <div className={styles.imagesWrap}>
            <StaticImage
              src="../../assets/images/AboutUs/img-hero-photo1.png"
              alt="laptop"
              className={styles.photo1}
              loading="eager"
              quality={100}
            />
            <StaticImage
              src="../../assets/images/AboutUs/img-hero-garage.png"
              alt="garage"
              className={styles.photo2}
              loading="eager"
              quality={100}
            />
            <StaticImage
              src="../../assets/images/AboutUs/img-hero-office.png"
              alt="office"
              className={styles.photo3}
              loading="eager"
              quality={100}
            />
            <StaticImage
              src="../../assets/images/AboutUs/img-hero-car.png"
              alt="car repair"
              className={styles.photo4}
              loading="eager"
              quality={100}
            />
          </div>
        )}
      </UpperSection>
      <div className={styles.wrapper}>
        <div className={styles.sectionWrapper}>
          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              <div className={styles.title}>The driving force behind Steer</div>
            </div>
            <div className={styles.companyValues}>
              <div>
                <div className={styles.companyValuesTitle}>Our vision</div>
                <div className={styles.companyValuesDescription}>
                  Steer is an automated retention marketing and communications
                  platform. We help repair shops acquire new customers and
                  drives existing customers back to the shop to perform regular
                  maintenance and services. Each tool is designed to build and
                  improve relationships with your customers.
                </div>
              </div>
              <div>
                <div className={styles.companyValuesTitle}>Our mission</div>
                <div className={styles.companyValuesDescription}>
                  Our mission is to improve the auto repair experience for shop
                  owners and their customers.
                </div>
              </div>
            </div>
            <div className={styles.banner}></div>
            <div className={styles.poligon1}></div>
          </div>
        </div>
        <div className={styles.sectionWrapper}>
          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              <div className={styles.title}>Our history</div>
            </div>
          </div>
        </div>
        <Timeline />
        <div className={styles.sectionWrapper}>
          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              <div className={styles.title}>Where we are</div>
              <div className={styles.description}>
                Steer has offices and employees in the United States and
                Ukraine.
              </div>
            </div>
            <Locations />
            <div className={styles.poligon2}></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default About;
