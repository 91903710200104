import React from "react";
import * as styles from "./AppLoader.module.scss";
import Layout from "../Layout";
import { Helmet } from "react-helmet";

export default () => {
  return (
    <Layout>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
        <title>Steer | Auto Repair Shop Marketing and CRM</title>
        <meta
          name="description"
          content="Get your customers to return for necessary repairs and services with Steer CRM — an automated marketing and communication platform for automotive shops."
        />
      </Helmet>
      <div className={styles.wrapper}>
        <div className={styles.cover}></div>
        <header>
          <h1>
            Auto Repair Shop Marketing That Increases Repeat Sales in a Big Way
          </h1>
          <p>
            Acquiring new customers is expensive and adds additional time to the
            repair process. Steer's intelligent marketing engine targets and
            segments your existing customers to make sure your ideal customers
            are visiting on time, every time, for necessary repairs and service.
          </p>
        </header>
        <main>
          <h2>Communication Tools That Are Changing the Automotive Industry</h2>
          <section>
            <h3>Scheduling Services</h3>
            <p>
              Our automated scheduling system allows customers to access your
              real-time calendar and schedule appointments directly into your
              shop management system.
            </p>
          </section>
          <section>
            <h3>Essential Marketing Tools</h3>
            <p>
              Steer provides a complete suite of marketing automation tools that
              help build relationships with customers which in turn increases
              repeat rates and retention.
            </p>
          </section>
          <section>
            <h3>Reputation Management</h3>
            <p>
              Stop worrying about 1-star reviews and use the Steer reputation
              builder to collect more, high-quality reviews for your shop.
            </p>
          </section>
          <section>
            <h3>Fleet Diagnostics</h3>
            <p>
              Give your commercial fleets accounts the power of telematics.
              Remotely detect issues, provide real-time service reminders, and
              remotely view vehicle diagnostic information.
            </p>
          </section>
        </main>
      </div>
    </Layout>
  );
};
