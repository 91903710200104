import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { Helmet } from "react-helmet";
import DirectMailProduct from "../components/DirectMail";

const DirectMail: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return (
    <>
      <Helmet>
        <title>Auto Repair Direct Mail Marketing | Steer</title>
      </Helmet>
      <DirectMailProduct />
    </>
  );
};

export default DirectMail;
