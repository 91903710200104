import React from "react";
import * as styles from "./Footer.module.scss";
import * as linkStyles from "../Shared/TextLink/TextLink.module.scss";
import cn from "classnames";
import {
  iconFacebook,
  iconLinkedin,
  iconTwitter,
  iconInstagram,
  iconYoutube,
  iconLogoFooter,
} from "../../assets/icons/Footer";
import TextLink from "../Shared/TextLink";
import { HSplit } from "../Shared/Divider";
import { EarlyAccessForm } from "../Shared/EarlyAccessForm";
import { BannerQuestion } from "./BannerQuestion";
import { BannerJoin } from "./BannerJoin";
import { BannerIntegration } from "./BannerIntegration";
import { BannerPartners } from "./BannerPartners";
import { BannerEnterprise } from "./BannerEnterprise";
import { BannerCareers } from "./BannerCareers";
import { PATHS, SOCIAL_MEDIA } from "../../constants";

const BANNERS = [
  <></>,
  <BannerQuestion />,
  <BannerJoin />,
  <BannerIntegration />,
  <BannerPartners />,
  <BannerEnterprise />,
  <BannerCareers />,
];
const BANNER_BY_PATH = {
  [PATHS.SUPPORT_REQUEST]: 0,
  [PATHS.ABOUT_US]: 2,
  [PATHS.INTEGRATIONS]: 3,
  [PATHS.PARTNERS]: 4,
  [PATHS.ADVANCE_PROFESSIONAL]: 4,
  [PATHS.PROTRACTOR]: 4,
  [PATHS.SHOP_WARE]: 4,
  [PATHS.TEKMETRIC]: 4,
  [PATHS.VAST_SQL]: 4,
  [PATHS.ENTERPRISE_SOLUTIONS]: 5,
  [PATHS.CAREERS_AT_STEER]: 6,
};

const Footer: React.FC = () => {
  let path;
  if (typeof window !== `undefined`) path = window.location.pathname;

  return (
    <footer className={styles.footer}>
      {BANNERS[BANNER_BY_PATH[path]] || BANNERS[1]}
      <div className={styles.footerNavWrap}>
        <div className={`container ${styles.footerNav}`}>
          <div className={styles.earlyAccess}>
            <img src={iconLogoFooter} alt="logo" className={styles.logo} />
            <h2 className={styles.title}>
              Do you want to be the first to use Steer?
            </h2>
            <h3 className={styles.subtitle}>
              Sign up for our latest trend reports, as well as our most
              surprising and actionable discoveries.
            </h3>
            <EarlyAccessForm className="FooterForm" />
          </div>
          <div className={styles.navLinks}>
            <h6 className={styles.linksTitle}>Products</h6>
            {[
              ["Text Messaging", PATHS.TEXT_MESSAGING],
              ["Email Marketing", PATHS.EMAIL_MARKETING],
              ["Direct Mail Postcards", PATHS.DIRECT_MAIL],
              ["Service Reminders", PATHS.SERVICE_REMINDERS],
              ["Reputation Management", PATHS.REPUTATION_MANAGEMENT],
              ["Online Scheduling", PATHS.ONLINE_SCHEDULING],
              ["Website Chat", PATHS.WEBSITE_CHAT],
              ["Telematics", PATHS.TELEMATICS],
              ["Advanced Reporting", PATHS.ADVANCED_REPORTING],
            ].map(([text, link], i) => (
              <TextLink
                className={styles.navLink}
                text={text}
                to={link}
                key={i}
              />
            ))}
          </div>
          <div className={styles.navLinks}>
            <h6 className={styles.linksTitle}>Explore</h6>
            {[
              ["Shop Management Integrations", PATHS.INTEGRATIONS],
              ["Customer Testimonials", PATHS.CUSTOMER_TESTIMONIALS],
              ["Enterprise Solutions", PATHS.ENTERPRISE_SOLUTIONS],
              ["Reserve with Google", PATHS.RESERVE_WITH_GOOGLE],
            ].map(([text, link], i) => (
              <TextLink
                className={styles.navLink}
                text={text}
                to={link}
                key={i}
              />
            ))}
          </div>
          <div className={styles.navLinks_empty} />
          <div className={styles.navLinks}>
            <h6 className={styles.linksTitle}>Company</h6>
            {[
              ["Our Story", PATHS.ABOUT_US],
              ["Careers", PATHS.CAREERS_AT_STEER],
              ["Partners", PATHS.PARTNERS],
              ["Pricing", PATHS.PRICING],
            ].map(([text, link], i) => (
              <TextLink
                className={styles.navLink}
                text={text}
                to={link}
                key={i}
              />
            ))}
          </div>
          <div className={styles.navLinksPair}>
            <div className={styles.navLinks}>
              <h6 className={styles.linksTitle}>Contact us</h6>
              <a className={styles.contact} href="tel:617-765-8187">
                617-765-8187
              </a>
              <a className={styles.contact} href="mailto:sales@steercrm.com">
                sales@steercrm.com
              </a>
              <TextLink
                className={styles.navLink}
                text="Support Request"
                to={PATHS.SUPPORT_REQUEST}
              />
            </div>
            <div className={styles.navLinks}>
              <h6 className={styles.linksTitle}>Social media</h6>
              <div className={styles.socialMediaIcons}>
                <a href={SOCIAL_MEDIA.FACEBOOK} target="_blank">
                  <img
                    className={styles.icon}
                    src={iconFacebook}
                    alt="Facebook Icon"
                  />
                </a>
                <a href={SOCIAL_MEDIA.INSTAGRAM} target="_blank">
                  <img
                    className={styles.icon}
                    src={iconInstagram}
                    alt="Instagram Icon"
                  />
                </a>
                <a href={SOCIAL_MEDIA.LINKEDIN} target="_blank">
                  <img
                    className={styles.icon}
                    src={iconLinkedin}
                    alt="LinkedIn Icon"
                  />
                </a>
                <a href={SOCIAL_MEDIA.YOUTUBE} target="_blank">
                  <img
                    className={styles.icon}
                    src={iconYoutube}
                    alt="Youtube Icon"
                  />
                </a>
                <a href={SOCIAL_MEDIA.TWITTER} target="_blank">
                  <img
                    className={styles.icon}
                    src={iconTwitter}
                    alt="Twitter Icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <HSplit className={styles.divider} />

      <div className={styles.copyright}>
        <div className={styles.copyrightNav}>
          <span className={styles.copyrightLink}>
            {`© Steer ${new Date().getFullYear()}. All rights reserved`}
          </span>
          {[
            ["Terms and Conditions", PATHS.TERMS_AND_CONDITIONS],
            ["Privacy Policy", PATHS.PRIVACY_POLICY],
          ].map(([text, link], i) => (
            <TextLink
              text={text}
              to={link}
              className={styles.copyrightLink}
              key={i}
            />
          ))}
        </div>
        <HSplit className={styles.dividerMobile} />
      </div>
    </footer>
  );
};

export default Footer;
